import { SVGProps } from 'react';

function InviteIllustration(props: Readonly<SVGProps<SVGSVGElement>>) {
  return (
    <svg
      width="400"
      height="200"
      viewBox="0 0 400 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_76_10635)">
        <path
          d="M65.8061 60.2929C65.6192 60.2929 65.4946 60.1682 65.4946 59.9812L67.1768 2.87313C67.1768 2.68609 67.3637 2.5614 67.4884 2.5614C67.6753 2.5614 67.7999 2.68609 67.7999 2.87313L66.1177 59.9812C66.1177 60.1682 65.9931 60.2929 65.8061 60.2929Z"
          fill="#08080C"
        />
        <path
          d="M74.9653 110.421C74.7784 110.421 74.6538 110.296 74.6538 110.109L76.336 3.12532C76.336 2.93829 76.4606 2.8136 76.6475 2.8136C76.8344 2.8136 76.959 2.93829 76.959 3.12532L75.2768 110.109C75.2768 110.296 75.1522 110.421 74.9653 110.421Z"
          fill="#08080C"
        />
        <path
          d="M116.208 152.253C116.022 152.253 115.897 152.128 115.897 151.941C115.897 150.133 115.897 148.263 115.959 146.455C115.959 146.268 116.084 146.143 116.271 146.143C116.458 146.143 116.582 146.268 116.582 146.455C116.582 148.325 116.582 150.133 116.52 151.941C116.52 152.128 116.395 152.253 116.208 152.253Z"
          fill="#08080C"
        />
        <path
          d="M116.458 131.865C116.272 131.865 116.147 131.74 116.147 131.553C116.334 111.478 116.458 90.9044 116.583 69.0837C116.708 47.3253 116.832 24.7564 117.019 2.43685C117.019 2.24981 117.144 2.12512 117.331 2.12512C117.518 2.12512 117.642 2.24981 117.642 2.43685C117.393 24.7564 117.331 47.2629 117.206 69.0837C117.082 90.8421 116.957 111.416 116.77 131.553C116.77 131.74 116.645 131.865 116.458 131.865Z"
          fill="#08080C"
        />
        <path
          d="M119.574 133.361C119.387 133.361 119.262 133.236 119.262 133.049C119.325 125.131 119.387 117.026 119.449 108.797C119.449 108.61 119.574 108.485 119.761 108.485C119.948 108.485 120.072 108.61 120.072 108.797C120.072 117.026 119.948 125.131 119.885 133.049C119.885 133.236 119.761 133.361 119.574 133.361Z"
          fill="#08080C"
        />
        <path
          d="M119.824 100.133C119.637 100.133 119.512 100.009 119.512 99.8217C119.512 89.5347 119.637 78.9984 119.699 68.3998V52.6888C119.761 52.5018 119.886 52.3771 120.073 52.3771C120.26 52.3771 120.384 52.5018 120.384 52.6888V68.3998C120.26 78.9984 120.198 89.4724 120.135 99.8217C120.135 100.009 120.011 100.133 119.824 100.133Z"
          fill="#08080C"
        />
        <path
          d="M120.073 45.2042C119.886 45.2042 119.761 45.0795 119.761 44.8925C119.824 29.805 119.948 17.5854 120.01 6.42562C120.01 6.23858 120.135 6.11389 120.322 6.11389C120.509 6.11389 120.633 6.23858 120.633 6.42562C120.509 17.5854 120.447 29.8673 120.384 44.8925C120.384 45.0795 120.26 45.2042 120.073 45.2042Z"
          fill="#08080C"
        />
        <path
          d="M151.97 88.6598C151.783 88.6598 151.659 88.5351 151.659 88.3481L152.593 57.0509C152.593 56.8638 152.718 56.7391 152.905 56.7391C153.092 56.7391 153.216 56.8638 153.216 57.0509L152.282 88.3481C152.282 88.5351 152.157 88.6598 151.97 88.6598Z"
          fill="#08080C"
        />
        <path
          d="M153.156 48.8859C152.969 48.8859 152.845 48.7612 152.845 48.5741C153.281 34.4218 153.655 22.8256 154.028 12.1646C154.028 11.9776 154.153 11.8529 154.34 11.8529C154.527 11.8529 154.652 11.9776 154.652 12.1646C154.278 22.8256 153.904 34.4218 153.468 48.5741C153.468 48.7612 153.343 48.8859 153.156 48.8859Z"
          fill="#08080C"
        />
        <path
          d="M155.024 86.1656C154.837 86.1656 154.712 86.0409 154.712 85.8538L155.522 57.8609C155.522 57.6739 155.647 57.5492 155.834 57.5492C156.021 57.5492 156.145 57.6739 156.145 57.8609L155.335 85.8538C155.335 86.0409 155.211 86.1656 155.024 86.1656Z"
          fill="#08080C"
        />
        <path
          d="M156.084 48.885C155.897 48.885 155.772 48.7603 155.772 48.5733C156.146 35.1691 156.52 24.1964 156.832 14.0341C156.832 13.8471 156.956 13.7224 157.143 13.7224C157.33 13.7224 157.455 13.8471 157.455 14.0341C157.143 24.1964 156.769 35.1691 156.395 48.5733C156.395 48.7603 156.271 48.885 156.084 48.885Z"
          fill="#08080C"
        />
        <path
          d="M194.649 83.7948C194.462 83.7948 194.338 83.6701 194.338 83.4831V68.3332C194.4 68.1462 194.525 68.0215 194.712 68.0215C194.899 68.0215 195.023 68.1462 195.023 68.3332V83.4831C194.961 83.6701 194.836 83.7948 194.649 83.7948Z"
          fill="#08080C"
        />
        <path
          d="M194.899 39.0947C194.712 39.0947 194.587 38.97 194.587 38.7829C194.65 25.5658 194.712 18.7702 194.774 17.5233C194.774 17.3362 194.899 17.2115 195.086 17.2115C195.273 17.2115 195.397 17.3362 195.397 17.5233C195.397 18.8325 195.273 25.5658 195.21 38.7829C195.21 38.97 195.086 39.0947 194.899 39.0947Z"
          fill="#08080C"
        />
        <path
          d="M187.423 88.5341C187.236 88.5341 187.112 88.4094 187.112 88.2224V76.8756C187.174 76.6885 187.361 76.5638 187.486 76.5638C187.673 76.5638 187.797 76.6885 187.797 76.8756V88.2224C187.735 88.4094 187.548 88.5341 187.423 88.5341Z"
          fill="#08080C"
        />
        <path
          d="M187.921 35.4789C187.734 35.4789 187.61 35.3542 187.61 35.1672C187.672 26.7506 187.734 21.8877 187.797 20.7655C187.797 20.5784 187.984 20.4537 188.108 20.4537C188.295 20.4537 188.42 20.5784 188.42 20.7655C188.42 21.8877 188.295 26.7506 188.233 35.1048C188.233 35.2919 188.108 35.4165 187.921 35.4165V35.4789Z"
          fill="#08080C"
        />
        <path
          d="M197.515 85.2925C197.328 85.2925 197.204 85.1679 197.204 84.9808V62.599C197.266 62.4119 197.391 62.2872 197.577 62.2872C197.764 62.2872 197.889 62.4119 197.889 62.599V84.9808C197.827 85.1679 197.702 85.2925 197.515 85.2925Z"
          fill="#08080C"
        />
        <path
          d="M197.64 41.965C197.453 41.965 197.329 41.8403 197.329 41.6533C197.329 27.501 197.453 20.3937 197.453 19.2714C197.453 19.0844 197.64 18.9597 197.765 18.9597C197.952 18.9597 198.076 19.0844 198.076 19.2714C198.076 20.3313 198.014 27.4386 197.952 41.591C197.952 41.778 197.827 41.9027 197.64 41.9027V41.965Z"
          fill="#08080C"
        />
        <path
          d="M223.123 49.4458C222.936 49.4458 222.811 49.3211 222.811 49.134C222.811 38.5354 222.811 29.1836 222.811 20.5177C222.811 20.3306 222.936 20.2059 223.123 20.2059C223.309 20.2059 223.434 20.3306 223.434 20.5177C223.434 29.1836 223.434 38.5354 223.434 49.134C223.434 49.3211 223.309 49.4458 223.123 49.4458Z"
          fill="#08080C"
        />
        <path
          d="M225.863 52.4999C225.676 52.4999 225.552 52.3752 225.552 52.1882C225.552 40.8414 225.552 31.365 225.552 22.3873C225.552 22.2003 225.676 22.0756 225.863 22.0756C226.05 22.0756 226.175 22.2003 226.175 22.3873C226.175 31.365 226.175 40.8414 226.175 52.1882C226.175 52.3752 226.05 52.4999 225.863 52.4999Z"
          fill="#08080C"
        />
        <path
          d="M252.031 41.9633C251.844 41.9633 251.719 41.8386 251.719 41.6515C251.719 36.2899 251.719 30.9905 251.719 25.6289C251.719 25.4418 251.844 25.3171 252.031 25.3171C252.218 25.3171 252.342 25.4418 252.342 25.6289C252.342 30.9905 252.342 36.2899 252.342 41.6515C252.342 41.8386 252.218 41.9633 252.031 41.9633Z"
          fill="#08080C"
        />
        <path
          d="M254.461 40.4667C254.275 40.4667 254.15 40.342 254.15 40.1549C254.15 35.8531 254.15 31.5513 254.15 27.3118C254.15 27.1248 254.275 27.0001 254.461 27.0001C254.648 27.0001 254.773 27.1248 254.773 27.3118C254.773 31.6137 254.773 35.9155 254.773 40.1549C254.773 40.342 254.648 40.4667 254.461 40.4667Z"
          fill="#08080C"
        />
        <path
          d="M83.874 129.873C83.6871 129.873 83.5625 129.748 83.5625 129.561L85.9923 3.12532C85.9923 2.93829 86.1169 2.8136 86.3039 2.8136C86.4908 2.8136 86.6154 2.93829 86.6154 3.12532L84.1855 129.561C84.1855 129.748 84.0609 129.873 83.874 129.873Z"
          fill="#08080C"
        />
        <path
          d="M255.022 22.9465L119.574 3.6819C119.387 3.6819 119.262 3.49487 119.324 3.30783C119.324 3.1208 119.511 2.99611 119.698 3.05845L255.084 22.3231C255.271 22.3231 255.395 22.5101 255.333 22.6971C255.333 22.8218 255.208 22.9465 255.022 22.9465Z"
          fill="#08080C"
        />
        <path
          d="M151.972 11.6644L122.876 7.54958C122.689 7.54958 122.565 7.36255 122.627 7.17551C122.627 6.98848 122.814 6.86378 123.001 6.92613L152.097 11.0409C152.284 11.0409 152.408 11.2279 152.346 11.415C152.346 11.5397 152.221 11.6644 152.034 11.6644H151.972Z"
          fill="#08080C"
        />
        <path
          d="M184.494 16.2767L159.137 12.6607C158.95 12.6607 158.825 12.4736 158.887 12.2866C158.887 12.0996 159.074 11.9749 159.261 12.0372L184.619 15.6532C184.806 15.6532 184.93 15.8403 184.868 16.0273C184.868 16.152 184.743 16.2767 184.556 16.2767H184.494Z"
          fill="#08080C"
        />
        <path
          d="M220.817 21.4529L199.883 18.4603C199.696 18.4603 199.572 18.2733 199.634 18.0863C199.634 17.8992 199.821 17.7746 200.008 17.8369L220.942 20.8295C221.129 20.8295 221.253 21.0165 221.191 21.2035C221.191 21.3282 221.066 21.4529 220.879 21.4529H220.817Z"
          fill="#08080C"
        />
        <path
          d="M249.727 25.5665L227.983 22.4492C227.796 22.4492 227.671 22.2622 227.733 22.0752C227.733 21.8881 227.92 21.7634 228.107 21.8258L249.851 24.943C250.038 24.943 250.163 25.1301 250.1 25.3171C250.1 25.4418 249.976 25.5665 249.789 25.5665H249.727Z"
          fill="#08080C"
        />
        <path
          d="M149.542 50.5677L122.814 47.2634C122.627 47.2634 122.502 47.0764 122.565 46.8894C122.565 46.7023 122.751 46.5777 122.938 46.64L149.667 49.9443C149.854 49.9443 149.978 50.1313 149.916 50.3184C149.916 50.5054 149.791 50.5677 149.604 50.5677H149.542Z"
          fill="#08080C"
        />
        <path
          d="M160.321 51.8784L157.455 51.5043C157.268 51.5043 157.143 51.3173 157.206 51.1302C157.206 50.9432 157.393 50.8185 157.579 50.8808L160.445 51.2549C160.632 51.2549 160.757 51.4419 160.695 51.629C160.695 51.816 160.57 51.8784 160.383 51.8784H160.321Z"
          fill="#08080C"
        />
        <path
          d="M201.877 57.0511L199.572 56.7394C199.385 56.7394 199.261 56.5524 199.323 56.3653C199.323 56.1783 199.51 56.0536 199.697 56.1159L202.002 56.4277C202.189 56.4277 202.313 56.6147 202.251 56.8017C202.251 56.9888 202.127 57.0511 201.94 57.0511H201.877Z"
          fill="#08080C"
        />
        <path
          d="M250.661 63.0355L239.633 61.664C239.446 61.664 239.322 61.4769 239.384 61.2899C239.384 61.1029 239.571 60.9782 239.758 61.0405L250.785 62.4121C250.972 62.4121 251.097 62.5991 251.035 62.7862C251.035 62.9732 250.91 63.0355 250.723 63.0355H250.661Z"
          fill="#08080C"
        />
        <path
          d="M149.854 54.5561L123.437 51.4389C123.25 51.4389 123.126 51.2518 123.188 51.0648C123.188 50.8778 123.375 50.7531 123.562 50.8154L149.979 53.9327C150.166 53.9327 150.29 54.1197 150.228 54.3067C150.228 54.4938 150.103 54.5561 149.916 54.5561H149.854Z"
          fill="#08080C"
        />
        <path
          d="M159.511 55.7408L157.829 55.5537C157.642 55.5537 157.517 55.3667 157.58 55.1797C157.58 54.9926 157.767 54.8679 157.954 54.9303L159.636 55.1173C159.823 55.1173 159.947 55.3044 159.885 55.4914C159.885 55.6784 159.76 55.7408 159.573 55.7408H159.511Z"
          fill="#08080C"
        />
        <path
          d="M200.818 60.6061L198.638 60.3567C198.451 60.3567 198.326 60.1697 198.388 59.9826C198.388 59.7956 198.575 59.6709 198.762 59.7333L200.943 59.9826C201.13 59.9826 201.254 60.1697 201.192 60.3567C201.192 60.5437 201.067 60.6061 200.88 60.6061H200.818Z"
          fill="#08080C"
        />
        <path
          d="M249.228 66.2157L241.938 65.4052C241.751 65.4052 241.627 65.2181 241.689 65.0311C241.689 64.8441 241.876 64.7194 242.063 64.7817L249.352 65.5922C249.539 65.5922 249.664 65.7792 249.602 65.9663C249.602 66.1533 249.477 66.2157 249.29 66.2157H249.228Z"
          fill="#08080C"
        />
        <path
          d="M123.437 102.877L121.194 102.69C121.007 102.69 120.883 102.503 120.883 102.378C120.883 102.254 121.07 102.067 121.194 102.067L123.437 102.254C123.624 102.254 123.749 102.441 123.749 102.565C123.749 102.752 123.624 102.877 123.437 102.877Z"
          fill="#08080C"
        />
        <path
          d="M124.061 107.3L121.506 107.113C121.319 107.113 121.195 106.926 121.195 106.801C121.195 106.614 121.382 106.489 121.506 106.489L124.061 106.676C124.248 106.676 124.372 106.863 124.372 106.988C124.372 107.175 124.248 107.3 124.061 107.3Z"
          fill="#08080C"
        />
        <path
          d="M238.387 162.165L232.343 161.916C232.156 161.916 232.032 161.791 232.032 161.604C232.032 161.417 232.156 161.292 232.343 161.292L238.387 161.542C238.574 161.542 238.698 161.666 238.698 161.853C238.698 162.041 238.574 162.165 238.387 162.165Z"
          fill="#08080C"
        />
        <path
          d="M258.761 9.60454L218.512 3.86879C218.326 3.86879 218.201 3.68176 218.263 3.49472C218.263 3.30769 218.45 3.183 218.637 3.24534L258.885 8.98109C259.072 8.98109 259.197 9.16812 259.134 9.35516C259.134 9.47985 259.01 9.60454 258.823 9.60454H258.761Z"
          fill="#08080C"
        />
        <path
          d="M258.76 13.0362L202.313 4.99367C202.126 4.99367 202.001 4.80664 202.064 4.6196C202.064 4.43257 202.25 4.30788 202.437 4.37022L258.884 12.4127C259.071 12.4127 259.196 12.5998 259.134 12.7868C259.134 12.9115 259.009 13.0362 258.822 13.0362H258.76Z"
          fill="#08080C"
        />
        <path
          d="M114.466 8.7327L88.5476 5.05434C88.3607 5.05434 88.2361 4.86731 88.2984 4.68027C88.2984 4.49324 88.4853 4.36855 88.6723 4.43089L114.591 8.10925C114.777 8.10925 114.902 8.29628 114.84 8.48332C114.84 8.60801 114.715 8.7327 114.528 8.7327H114.466Z"
          fill="#08080C"
        />
        <path
          d="M114.466 13.594L88.5476 10.5391C88.3607 10.5391 88.2361 10.352 88.2984 10.165C88.2984 9.97798 88.4853 9.85329 88.6723 9.91563L114.591 12.9705C114.777 12.9705 114.902 13.1576 114.84 13.3446C114.84 13.5316 114.715 13.594 114.528 13.594H114.466Z"
          fill="#08080C"
        />
        <path
          d="M254.586 26.2521C254.462 26.2521 254.337 26.1898 254.275 26.0651C254.275 25.8781 254.275 25.7534 254.462 25.691L260.941 23.3219L261.315 3.05977C261.315 2.87274 261.502 2.74805 261.627 2.74805C261.814 2.74805 261.938 2.87274 261.938 3.05977L261.564 23.5089C261.564 23.6336 261.502 23.7583 261.378 23.8207L254.711 26.2521C254.711 26.2521 254.649 26.2521 254.586 26.2521Z"
          fill="#08080C"
        />
        <path
          d="M223.821 109.645C223.821 109.645 228.636 110.665 229.769 111.969C230.902 113.273 245.632 137.026 246.538 138.897C247.445 140.768 243.253 148.421 238.777 154.884C234.301 161.347 225.747 150.916 225.747 150.916L230.789 142.412C230.789 142.412 230.222 142.525 229.089 141.845C227.956 141.165 225.067 133.511 225.067 133.511C224.954 135.439 224.5 136.573 223.764 141.391C222.178 151.653 221.045 163.388 218.155 162.197C220.195 167.356 226.257 183.4 225.747 184.93C224.897 187.481 216.682 186.914 216.682 186.914C216.682 186.914 217.249 170.701 216.286 162.934C215.323 155.167 216.229 143.716 216.286 140.711C216.342 137.707 218.665 115.37 218.552 113.329C218.438 111.289 218.665 108.794 219.572 108.681C220.478 108.567 221.498 109.078 223.481 108.567C225.463 108.057 223.877 109.588 223.877 109.588L223.821 109.645Z"
          fill="#FA7353"
        />
        <path
          d="M230.789 140.654C230.676 140.654 230.619 140.654 230.562 140.541C230.449 140.427 230.506 140.257 230.619 140.144C230.732 140.03 234.075 137.593 237.984 137.309C238.154 137.309 238.267 137.423 238.267 137.593C238.267 137.763 238.154 137.876 237.984 137.876C234.245 138.16 230.959 140.597 230.902 140.597C230.902 140.597 230.789 140.597 230.732 140.597L230.789 140.654Z"
          fill="#08080C"
        />
        <path
          d="M239.853 141.335C239.74 141.335 239.683 141.335 239.626 141.221C239.57 141.108 239.626 140.938 239.74 140.824C239.853 140.824 242.006 139.52 243.592 140.087C243.762 140.087 243.819 140.314 243.762 140.428C243.705 140.541 243.535 140.654 243.422 140.598C242.119 140.087 240.079 141.278 240.079 141.278C240.079 141.278 239.966 141.278 239.909 141.278L239.853 141.335Z"
          fill="#08080C"
        />
        <path
          d="M226.085 132.547C226.085 132.547 226.028 132.547 225.972 132.547C225.858 132.491 225.745 132.32 225.858 132.15C226.028 131.81 229.711 124.157 232.77 122.003C232.883 121.889 233.053 121.946 233.167 122.059C233.28 122.173 233.223 122.343 233.11 122.456C230.164 124.497 226.425 132.32 226.368 132.434C226.368 132.547 226.198 132.604 226.085 132.604V132.547Z"
          fill="#08080C"
        />
        <path
          d="M219.174 127.502C219.004 127.502 218.891 127.389 218.891 127.219C218.891 125.065 218.948 114.18 220.817 113.556C222.404 113.046 222.97 110.835 222.97 110.779C222.97 110.608 223.14 110.552 223.31 110.552C223.48 110.552 223.537 110.722 223.537 110.892C223.537 111.005 222.913 113.443 221.044 114.067C220.081 114.407 219.458 120.983 219.514 127.162C219.514 127.332 219.401 127.446 219.231 127.446L219.174 127.502Z"
          fill="#08080C"
        />
        <path
          d="M224.388 184.988C224.388 184.988 222.405 186.575 212.661 189.183C202.916 191.791 166.885 184.364 162.976 180.509L182.068 158.116L193.002 111.97L205.296 100.688L207.278 101.028L223.368 108.625L220.875 117.299C220.875 117.299 223.651 131.075 222.178 137.594C220.705 144.114 218.043 157.379 218.043 157.379C218.043 157.379 219.176 177.391 224.388 184.988Z"
          fill="#08080C"
        />
        <path
          d="M236.058 160.156C236.058 160.156 239.514 157.208 239.457 156.188C239.401 155.168 228.07 147.911 226.257 147.684C224.444 147.458 223.934 151.426 223.934 151.426C223.934 151.426 220.818 152.503 220.932 154.714C221.045 156.925 230.903 164.522 233.169 163.671C235.435 162.821 236.058 160.156 236.058 160.156Z"
          fill="#FA7353"
        />
        <path
          d="M220.931 155.053C220.761 155.053 220.648 154.94 220.648 154.77C220.535 152.389 223.651 151.255 223.821 151.198C223.991 151.198 224.104 151.198 224.161 151.368C224.161 151.539 224.161 151.652 223.991 151.709C223.991 151.709 221.101 152.729 221.215 154.713C221.215 154.883 221.101 154.997 220.931 154.997V155.053Z"
          fill="#08080C"
        />
        <path
          d="M234.358 157.604C234.301 157.604 234.188 157.604 234.131 157.547C232.658 156.073 229.825 153.806 228.126 152.615C228.012 152.502 227.956 152.332 228.069 152.218C228.182 152.105 228.352 152.048 228.466 152.162C230.222 153.352 233.111 155.676 234.584 157.15C234.698 157.264 234.698 157.434 234.584 157.547C234.584 157.547 234.471 157.604 234.358 157.604Z"
          fill="#08080C"
        />
        <path
          d="M221.839 156.642C221.782 156.642 221.669 156.642 221.613 156.528C220.989 155.791 220.649 155.224 220.593 154.771C220.593 154.601 220.706 154.487 220.876 154.487C221.046 154.487 221.159 154.601 221.159 154.771C221.159 154.941 221.273 155.338 222.009 156.188C222.122 156.301 222.122 156.471 222.009 156.585C222.009 156.585 221.896 156.642 221.839 156.642Z"
          fill="#08080C"
        />
        <path
          d="M230.788 158.852L222.856 166.619C222.856 166.619 223.989 170.984 223.253 173.989C223.253 173.989 224.386 175.519 224.726 177.05C225.066 178.581 224.329 178.354 224.329 178.354C224.329 178.354 225.009 179.658 224.726 180.905C224.443 182.152 223.763 181.585 223.763 181.585C223.763 181.585 224.103 182.379 223.423 182.549C222.743 182.719 222.516 181.925 222.12 181.472C222.12 181.472 221.667 181.642 221.27 181.585C220.873 181.529 218.267 178.297 217.078 173.025C215.888 167.753 216.511 165.825 216.624 164.862C216.738 163.898 223.649 154.771 223.649 154.771L230.731 158.852H230.788Z"
          fill="#9D5B3A"
        />
        <path
          d="M223.706 178.695C223.649 178.695 223.536 178.695 223.479 178.581C223.479 178.581 221.779 176.37 221.496 175.293C221.213 174.329 220.533 172.685 220.476 172.685C220.42 172.515 220.476 172.402 220.646 172.288C220.816 172.232 220.93 172.288 221.043 172.459C221.043 172.515 221.779 174.103 222.063 175.18C222.346 176.087 223.762 178.071 223.989 178.298C224.102 178.411 224.045 178.581 223.989 178.695C223.989 178.695 223.876 178.751 223.819 178.751L223.706 178.695Z"
          fill="#08080C"
        />
        <path
          d="M223.253 181.302C223.14 181.302 223.083 181.302 223.027 181.188C222.573 180.508 222.007 179.374 221.667 178.637C221.554 178.41 221.497 178.24 221.44 178.127C221.1 177.447 219.061 172.458 218.948 172.231C218.891 172.061 218.948 171.948 219.117 171.834C219.287 171.777 219.401 171.834 219.514 172.004C219.514 172.004 221.61 177.22 221.95 177.843C222.007 177.957 222.12 178.127 222.233 178.354C222.573 179.034 223.14 180.168 223.593 180.848C223.706 180.961 223.593 181.131 223.536 181.245C223.536 181.245 223.423 181.245 223.366 181.245L223.253 181.302Z"
          fill="#08080C"
        />
        <path
          d="M221.61 180.906C221.496 180.906 221.44 180.906 221.383 180.793C220.533 179.432 219.514 177.732 219.287 177.108C218.947 176.201 217.871 172.799 217.814 172.629C217.814 172.459 217.814 172.346 217.984 172.289C218.154 172.289 218.267 172.289 218.324 172.459C218.324 172.459 219.457 176.031 219.74 176.881C219.91 177.335 220.647 178.639 221.78 180.453C221.836 180.566 221.78 180.736 221.666 180.85C221.666 180.85 221.553 180.85 221.496 180.85L221.61 180.906Z"
          fill="#08080C"
        />
        <path
          d="M223.139 155.565C224.952 155.508 228.577 158.57 229.88 159.76C230.277 159.363 231.127 158.626 231.127 158.626L223.705 154.828C223.705 154.828 223.479 155.111 223.139 155.622V155.565Z"
          fill="#08080C"
        />
        <path
          d="M222.858 154.374C229.26 156.472 232.432 162.197 232.432 162.197C232.432 162.197 234.019 159.023 230.676 156.131C227.334 153.24 222.858 154.374 222.858 154.374Z"
          fill="#FA7353"
        />
        <path
          d="M232.487 162.425C232.374 162.425 232.317 162.425 232.261 162.255C232.261 162.255 229.031 156.642 222.856 154.545C222.686 154.545 222.63 154.318 222.686 154.204C222.686 154.034 222.913 153.978 223.026 154.034C229.428 156.189 232.714 161.914 232.771 161.971C232.827 162.084 232.771 162.255 232.657 162.368H232.544L232.487 162.425Z"
          fill="#08080C"
        />
        <path
          d="M214.359 152.275L214.982 156.981C214.982 156.981 215.832 157.094 215.832 157.888C215.832 158.681 218.438 180.054 218.438 180.054C218.438 180.054 220.024 180.281 220.194 181.358C220.364 182.435 220.194 186.063 220.194 186.063C220.194 186.063 221.781 191.789 220.874 192.186C219.968 192.583 196.173 195.757 195.153 195.531C194.134 195.304 192.491 185.27 192.491 185.27C192.491 185.27 191.698 193.83 190.791 195.587C189.885 197.345 162.974 191.222 162.578 191.222C162.181 191.222 163.598 186.687 163.598 186.687C163.598 186.687 163.258 182.095 164.051 181.641L164.844 181.188C164.844 181.188 167.62 165.484 173.115 157.718C178.611 149.951 182.01 147.627 182.01 147.627L214.302 152.219L214.359 152.275Z"
          fill="#08080C"
        />
        <path
          d="M207.277 157.378C207.107 157.378 206.994 157.264 206.994 157.094C206.994 156.924 207.107 156.811 207.277 156.811C207.277 156.811 211.583 156.754 213.453 155.904C213.623 155.847 213.736 155.904 213.849 156.074C213.906 156.244 213.849 156.357 213.679 156.471C211.696 157.321 207.447 157.434 207.277 157.434V157.378Z"
          fill="white"
        />
        <path
          d="M194.87 180.055C194.814 180.055 194.7 180.055 194.7 179.998C194.587 179.884 194.587 179.714 194.7 179.601C194.7 179.544 200.366 173.762 204.898 171.041C205.011 170.984 205.181 171.041 205.294 171.154C205.351 171.267 205.294 171.438 205.181 171.551C200.705 174.215 195.153 179.941 195.097 180.055C195.097 180.111 194.983 180.168 194.87 180.168V180.055Z"
          fill="white"
        />
        <path
          d="M193.283 184.193C193.226 184.193 193.113 184.193 193.056 184.08C192.943 183.966 192.999 183.796 193.056 183.683C193.113 183.683 199.741 178.354 207.616 177.56C207.786 177.56 207.899 177.674 207.899 177.787C207.899 177.9 207.786 178.07 207.673 178.07C199.968 178.864 193.509 184.023 193.396 184.08C193.396 184.08 193.283 184.136 193.226 184.136L193.283 184.193Z"
          fill="white"
        />
        <path
          d="M196.23 190.314C196.117 190.314 196.003 190.257 195.947 190.144C195.947 189.974 195.947 189.86 196.117 189.804C196.173 189.804 203.028 187.139 208.524 186.686C208.694 186.686 208.807 186.799 208.807 186.969C208.807 187.139 208.694 187.253 208.524 187.253C203.085 187.706 196.343 190.314 196.287 190.314C196.287 190.314 196.23 190.314 196.173 190.314H196.23Z"
          fill="white"
        />
        <path
          d="M180.367 188.615C180.367 188.615 180.367 188.615 180.311 188.615C173.569 186.858 164.618 186.631 164.504 186.631C164.334 186.631 164.221 186.517 164.221 186.347C164.221 186.177 164.391 186.064 164.504 186.064C164.618 186.064 173.626 186.347 180.424 188.048C180.594 188.048 180.651 188.218 180.651 188.388C180.651 188.502 180.481 188.615 180.367 188.615Z"
          fill="white"
        />
        <path
          d="M194.869 186.461C194.756 186.461 194.642 186.461 194.642 186.291C194.586 186.178 194.642 186.008 194.756 185.894C194.869 185.894 204.727 180.905 218.493 179.715C218.663 179.715 218.777 179.828 218.777 179.998C218.777 180.168 218.663 180.282 218.493 180.282C204.84 181.416 195.039 186.348 194.982 186.404H194.869V186.461Z"
          fill="white"
        />
        <path
          d="M219.854 190.599C219.854 190.599 220.024 206.7 219.628 212.028C219.231 217.357 201.612 212.028 201.612 212.028C201.612 212.028 194.587 195.248 197.136 193.717C202.915 190.259 219.798 190.599 219.798 190.599H219.854Z"
          fill="#9D5B3A"
        />
        <path
          d="M163.881 189.181C163.881 189.181 162.068 206.245 161.955 211.857C161.841 217.526 181.16 213.671 181.16 213.671C181.16 213.671 190.564 196.324 187.788 194.736C178.044 189.351 163.937 189.124 163.937 189.124L163.881 189.181Z"
          fill="#9D5B3A"
        />
        <path
          d="M220.932 134.192C220.875 136.743 216.23 141.959 216.23 141.959C216.23 141.959 215.947 142.753 215.607 143.943C214.813 146.664 213.567 151.426 213.907 152.844C214.417 154.884 191.926 154.431 187.733 152.844C183.541 151.256 182.125 147.685 182.125 147.685C182.125 147.685 183.767 143.943 185.524 139.578C187.393 134.929 189.433 129.6 189.773 127.673C189.886 126.766 189.659 125.235 189.319 123.364C188.073 117.355 185.297 108.171 187.96 107.207C191.472 105.903 201.557 104.94 201.557 104.94L205.352 102.785L215.833 108.568C215.833 108.568 215.777 110.495 216.23 110.836C217.363 111.686 219.516 112.026 219.516 112.026C219.516 112.026 217.929 117.809 219.799 125.178C221.669 132.548 220.932 133.115 220.932 134.249V134.192Z"
          fill="#9D5B3A"
        />
        <path
          d="M221.723 134.08C221.666 136.631 216.229 141.959 216.229 141.959C216.229 141.959 215.945 142.753 215.605 143.944C204.955 144.681 194.814 143.547 185.522 139.578C187.392 134.93 189.432 129.601 189.771 127.673C189.885 126.766 189.658 125.236 189.318 123.365C194.417 124.102 209.94 126.143 220.421 125.179C220.761 129.147 221.497 130.678 221.497 131.812L221.723 134.08Z"
          fill="#08080C"
        />
        <path
          d="M234.359 96.0954C234.359 96.0954 232.206 99.6102 227.617 100.631C223.028 101.651 229.77 88.272 231.64 89.4625C233.509 90.653 235.379 91.6168 234.359 96.0954Z"
          fill="#08080C"
        />
        <path
          d="M218.325 73.9858C206.597 73.1354 204.954 81.5257 205.521 87.0247C205.521 87.0247 202.915 86.7979 200.309 87.4215C198.269 85.8342 197.42 83.0563 197.873 81.0154C198.723 77.3305 204.445 77.7841 204.445 77.7841C204.445 77.7841 202.518 76.4235 201.159 76.3101C199.799 76.2534 197.873 77.7841 197.873 77.7841C200.932 73.1921 205.408 77.2171 205.408 77.2171C205.408 74.1558 207.56 70.1308 212.943 71.0378C215.888 71.5481 217.475 72.8519 218.325 73.9291V73.9858Z"
          fill="#08080C"
        />
        <path
          d="M231.016 93.658C230.846 94.8485 229.373 97.3996 228.523 101.651C227.56 106.697 223.255 108.454 222.518 109.248C221.782 110.042 220.932 110.325 220.139 110.325H220.082C217.702 110.325 216.173 108.851 216.173 108.851V110.098L216.229 110.722C213.793 111.402 209.431 108.965 205.352 103.749C212.49 96.4358 205.975 90.0864 208.015 87.1385C209.035 85.6645 213.114 85.3244 217.419 85.3811C221.838 85.4945 226.484 86.0614 228.24 86.5716C231.753 87.4786 231.639 88.0456 231.639 88.0456C231.639 88.0456 231.639 88.3857 231.639 88.8959C231.639 90.3132 231.356 91.7872 231.073 93.6013L231.016 93.658Z"
          fill="#9D5B3A"
        />
        <path
          d="M225.351 93.9968C225.351 93.9968 224.955 92.1827 223.482 91.956C222.009 91.7292 219.686 93.2032 219.686 93.2032C219.686 93.2032 222.462 92.8063 223.199 92.9197C223.935 93.0331 225.295 93.9402 225.295 93.9402L225.351 93.9968Z"
          fill="#08080C"
        />
        <path
          d="M227.277 94.6791C227.277 94.6791 229.147 94.9626 229.43 95.246C229.713 95.5295 230.053 96.3231 230.053 96.3231C230.053 96.3231 230.45 95.3027 229.827 94.509C229.204 93.7154 227.221 94.6791 227.221 94.6791H227.277Z"
          fill="#08080C"
        />
        <path
          d="M224.501 103.635C224.161 103.635 223.708 103.522 223.142 103.295C223.028 103.238 222.915 103.068 223.028 102.898C223.085 102.728 223.255 102.671 223.425 102.785C224.161 103.125 224.728 103.182 225.125 102.955C225.351 102.785 225.464 102.501 225.464 102.331C225.351 100.801 225.634 99.4967 225.634 99.44C225.634 99.2699 225.804 99.2133 225.974 99.2133C226.144 99.2133 226.201 99.3833 226.201 99.5534C226.201 99.5534 225.918 100.857 226.031 102.275C226.031 102.615 225.861 103.068 225.464 103.352C225.295 103.465 225.011 103.578 224.615 103.578L224.501 103.635Z"
          fill="#08080C"
        />
        <path
          d="M223.818 105.053C223.535 105.45 222.402 106.81 221.439 106.47C220.306 106.13 219.513 103.182 219.513 103.182C219.796 103.352 222.289 104.373 223.818 104.996V105.053Z"
          fill="white"
        />
        <path
          d="M221.723 106.811C221.609 106.811 221.496 106.811 221.326 106.754C220.08 106.357 219.287 103.579 219.23 103.296C219.23 103.183 219.23 103.069 219.343 103.012C219.457 102.956 219.57 102.956 219.683 103.012C219.797 103.126 220.646 103.466 223.932 104.827C223.989 104.827 224.102 104.94 224.102 104.997C224.102 105.053 224.102 105.167 224.102 105.223C223.819 105.62 222.799 106.811 221.779 106.811H221.723ZM219.967 103.749C220.306 104.77 220.93 106.017 221.496 106.187C222.063 106.357 222.856 105.734 223.366 105.11C221.496 104.373 220.533 103.92 219.967 103.693V103.749Z"
          fill="#08080C"
        />
        <path
          d="M225.009 105.79C225.009 105.79 224.952 105.79 224.895 105.79L223.706 105.336L219.4 103.522C219.287 103.522 219.23 103.352 219.23 103.239C219.23 103.125 219.23 103.182 219.23 103.125C219.287 102.955 219.457 102.898 219.627 102.955L225.122 105.279C225.292 105.336 225.349 105.506 225.292 105.62C225.292 105.733 225.122 105.79 225.009 105.79Z"
          fill="#08080C"
        />
        <path
          d="M221.781 108.399C220.025 108.399 219.345 107.378 219.345 107.322C219.288 107.208 219.345 107.038 219.458 106.925C219.572 106.868 219.742 106.925 219.855 107.038C219.855 107.038 220.478 108.002 222.234 107.832C222.404 107.832 222.518 107.945 222.518 108.059C222.518 108.229 222.404 108.342 222.291 108.342C222.121 108.342 221.951 108.342 221.781 108.342V108.399Z"
          fill="#08080C"
        />
        <path
          d="M228.128 99.043C229.205 99.043 229.205 97.3423 228.128 97.3423C227.052 97.3423 227.052 99.043 228.128 99.043Z"
          fill="#08080C"
        />
        <path
          d="M222.008 97.3422C223.084 97.3422 223.084 95.6415 222.008 95.6415C220.931 95.6415 220.931 97.3422 222.008 97.3422Z"
          fill="#08080C"
        />
        <path
          d="M220.025 110.382C217.645 110.382 216.116 108.908 216.116 108.908V110.155C213.566 107.831 212.037 103.296 212.433 100.688C212.433 100.688 213.113 107.718 220.082 110.382H220.025Z"
          fill="#08080C"
        />
        <path
          d="M236.625 95.6428C236.058 98.9309 231.583 100.462 231.583 100.462V92.7516C231.923 90.5407 230.506 89.01 230.506 89.01C225.407 88.8399 225.294 86.5156 225.294 86.2321C225.294 86.4022 225.181 87.536 223.764 88.0463C220.875 89.0667 217.249 88.3297 217.249 88.3297C217.249 88.3297 219.346 90.5973 218.949 91.1076C218.609 91.6178 215.72 90.1438 215.606 90.0871C215.776 90.2005 218.212 92.2981 216.683 93.2618C215.04 94.2823 215.436 95.246 215.323 96.6066C215.21 97.9105 213.227 98.4774 213.057 98.5341C213.17 98.4207 214.247 97.5136 213.907 96.4932C213.624 95.3594 213.794 93.2051 213.794 93.2051C209.941 99.3845 204.162 100.518 204.162 100.518C198.271 104.26 195.041 99.101 195.608 93.0918C196.174 87.0258 206.032 87.8195 206.032 87.8195C205.465 82.3205 207.165 73.9869 219.006 75.064C230.846 76.1411 230.959 84.3613 232.999 87.1959C235.095 90.0304 237.078 92.5248 236.511 95.8129L236.625 95.6428Z"
          fill="#08080C"
        />
        <path
          d="M212.151 98.761C212.151 98.761 210.168 98.3641 210.055 95.4729C209.941 92.5817 211.697 90.314 213.85 93.0352C213.284 98.0807 212.151 98.761 212.151 98.761Z"
          fill="#9D5B3A"
        />
        <path
          d="M212.15 99.0438C212.15 99.0438 212.15 99.0438 212.093 99.0438C211.98 99.0438 209.884 98.5336 209.771 95.5289C209.657 93.6581 210.337 92.0708 211.414 91.6739C211.923 91.5039 212.887 91.3905 214.076 92.8645C214.19 92.9778 214.133 93.1479 214.076 93.2613C213.963 93.3747 213.793 93.318 213.68 93.2613C212.943 92.3542 212.263 92.0141 211.64 92.2409C210.79 92.581 210.28 93.9416 210.337 95.5289C210.45 98.1367 212.15 98.5336 212.207 98.5336C212.377 98.5336 212.433 98.7036 212.433 98.8737C212.433 98.9871 212.263 99.1005 212.15 99.1005V99.0438Z"
          fill="#08080C"
        />
        <path
          d="M212.49 96.7772C212.32 96.7772 212.206 96.6638 212.206 96.4937C212.206 95.3032 211.98 94.1127 211.81 93.9426C211.753 93.9426 211.583 94.1127 211.47 94.3395C211.413 94.4529 211.243 94.5662 211.073 94.4529C210.96 94.3962 210.847 94.2261 210.96 94.056C210.96 93.9426 211.3 93.4324 211.697 93.3757C211.866 93.3757 212.036 93.3757 212.206 93.5458C212.66 93.9426 212.716 95.7001 212.773 96.4937C212.773 96.6638 212.66 96.7772 212.49 96.7772Z"
          fill="#08080C"
        />
        <path
          d="M202.123 187.255C200.876 183.117 207.221 146.551 211.244 137.594C213.283 133.058 211.244 114.861 211.244 114.861C211.244 114.861 211.98 109.305 211.867 108.058C211.754 106.811 206.825 104.033 206.825 103.182C206.825 102.332 206.145 101.425 207.391 100.971C208.638 100.518 205.862 98.9872 203.992 99.2139C202.123 99.4407 201.783 102.899 201.783 102.899L199.347 104.316C199.347 104.316 191.359 105.393 187.959 107.094C184.56 108.795 184.73 143.206 184.73 143.206C184.73 143.206 171.02 154.091 166.261 168.604C161.502 183.117 163.145 180.395 163.145 180.395C161.899 181.076 195.268 188.956 202.236 187.142L202.123 187.255Z"
          fill="#FA7353"
        />
        <path
          d="M206.655 113.671C206.655 113.671 206.655 113.671 206.598 113.671C205.805 113.614 204.389 111.857 202.746 109.759C202.519 109.476 202.349 109.249 202.179 109.022C201.103 107.718 199.177 104.657 199.12 104.487C199.063 104.373 199.12 104.203 199.233 104.09C199.347 104.033 199.516 104.09 199.63 104.203C199.63 104.203 201.613 107.378 202.689 108.682C202.859 108.909 203.029 109.135 203.256 109.419C204.049 110.439 206.145 113.104 206.711 113.104C206.881 112.934 206.938 111.573 206.938 110.723C206.938 108.739 207.051 107.378 207.618 106.924C208.751 106.131 210.677 107.718 210.847 107.888C210.96 108.002 210.96 108.172 210.847 108.285C210.734 108.398 210.564 108.398 210.451 108.285C209.997 107.888 208.581 106.868 207.901 107.378C207.448 107.661 207.448 109.419 207.448 110.723C207.448 112.31 207.448 113.217 207.051 113.557C206.995 113.614 206.881 113.671 206.711 113.671H206.655Z"
          fill="#08080C"
        />
        <path
          d="M204.275 107.888C204.218 107.888 204.105 107.888 204.048 107.831C203.992 107.775 202.009 105.734 201.442 103.013C201.442 102.843 201.499 102.729 201.669 102.673C201.839 102.673 201.952 102.729 202.009 102.899C202.519 105.45 204.445 107.378 204.445 107.435C204.558 107.548 204.558 107.718 204.445 107.831C204.445 107.831 204.332 107.888 204.218 107.888H204.275Z"
          fill="#08080C"
        />
        <path
          d="M186.43 171.268C186.146 169.454 186.033 147.855 186.033 147.855C186.033 147.855 195.211 128.069 196.797 123.307C198.383 118.545 193.908 105.733 187.846 107.15C181.784 108.568 169.094 144.283 168.131 150.009C167.167 155.735 173.229 174.499 173.229 174.499C173.229 174.499 186.713 173.082 186.43 171.268Z"
          fill="#FA7353"
        />
        <path
          d="M191.413 136.289C191.413 136.289 191.357 136.289 191.3 136.289C191.13 136.233 191.073 136.062 191.13 135.892C194.076 129.316 195.889 125.064 196.512 123.25C196.512 123.08 196.739 123.024 196.852 123.08C197.022 123.08 197.079 123.307 197.022 123.42C196.399 125.234 194.586 129.543 191.64 136.119C191.64 136.233 191.47 136.289 191.357 136.289H191.413Z"
          fill="#08080C"
        />
        <path
          d="M186.259 168.319C186.089 168.319 185.976 168.206 185.976 168.036C185.863 164.067 185.806 157.491 185.75 152.616C185.75 152.446 185.863 152.332 186.033 152.332C186.203 152.332 186.316 152.446 186.316 152.616C186.316 157.434 186.429 164.011 186.543 167.979C186.543 168.149 186.429 168.262 186.259 168.262V168.319Z"
          fill="#08080C"
        />
        <path
          d="M176.174 155.621C176.061 155.621 175.948 155.564 175.891 155.451C175.891 155.281 175.891 155.168 176.061 155.111C177.931 154.487 182.916 152.616 183.766 151.766C183.879 151.653 184.049 151.653 184.163 151.766C184.276 151.879 184.276 152.05 184.163 152.163C183.086 153.24 176.571 155.564 176.288 155.621C176.288 155.621 176.231 155.621 176.174 155.621Z"
          fill="#08080C"
        />
        <path
          d="M181.67 133.738C181.557 133.738 181.443 133.681 181.387 133.568C181.387 133.398 181.387 133.284 181.557 133.228C184.333 132.151 190.565 123.193 190.621 123.08C190.735 122.967 190.904 122.91 191.018 123.023C191.131 123.137 191.188 123.307 191.074 123.42C190.791 123.817 184.673 132.661 181.727 133.795C181.727 133.795 181.67 133.795 181.613 133.795L181.67 133.738Z"
          fill="#08080C"
        />
        <path
          d="M171.869 170.248C171.756 170.248 171.642 170.192 171.586 170.078C170.622 167.867 168.81 159.364 168.753 159.024C168.753 158.854 168.81 158.74 168.98 158.683C169.149 158.683 169.263 158.74 169.319 158.91C169.319 159.024 171.189 167.754 172.095 169.852C172.152 170.022 172.095 170.135 171.926 170.248C171.926 170.248 171.869 170.248 171.812 170.248H171.869Z"
          fill="#08080C"
        />
        <path
          d="M189.206 177.278C190.905 176.088 187.903 173.48 187.903 173.48C187.903 173.48 188.186 171.723 188.356 170.362C188.526 169.001 176.912 169.512 172.833 171.609C168.754 173.707 172.833 177.562 172.833 177.562C172.833 177.562 171.247 182.21 172.096 182.437C175.212 183.231 181.841 182.38 189.149 177.278H189.206Z"
          fill="#FA7353"
        />
        <path
          d="M174.081 176.768C174.024 176.768 173.911 176.768 173.854 176.654C173.741 176.541 173.798 176.371 173.854 176.258C174.024 176.144 177.537 173.31 181.106 172.969C181.276 172.969 181.389 173.083 181.389 173.196C181.389 173.366 181.276 173.48 181.163 173.48C177.82 173.82 174.251 176.654 174.251 176.654C174.251 176.654 174.138 176.711 174.081 176.711V176.768Z"
          fill="#08080C"
        />
        <path
          d="M174.533 182.946C173.57 182.946 172.777 182.833 172.04 182.663C171.927 182.663 171.757 182.549 171.7 182.379C171.247 181.529 172.21 178.581 172.494 177.617C171.984 177.164 170.397 175.52 170.624 173.819C170.737 172.799 171.417 171.948 172.72 171.325C176.403 169.397 186.657 168.66 188.357 169.794C188.64 169.964 188.696 170.248 188.64 170.361C188.526 171.495 188.3 172.912 188.243 173.366C188.696 173.762 190.113 175.18 190.056 176.37C190.056 176.824 189.83 177.221 189.433 177.504C183.824 181.416 178.386 183.003 174.646 183.003L174.533 182.946ZM184.674 169.851C181.105 169.851 175.61 170.418 172.947 171.778C171.87 172.345 171.247 173.025 171.191 173.819C171.021 175.52 173.004 177.221 173.004 177.221C173.117 177.277 173.117 177.447 173.06 177.504C172.324 179.658 171.87 181.756 172.154 182.039C174.703 182.72 181.162 182.379 188.98 176.937C189.263 176.767 189.376 176.54 189.376 176.257C189.376 175.35 188.187 173.989 187.677 173.592C187.62 173.536 187.563 173.422 187.563 173.309C187.563 173.309 187.847 171.551 188.017 170.191C187.847 169.907 186.544 169.737 184.617 169.737L184.674 169.851Z"
          fill="#08080C"
        />
        <path
          d="M194.078 195.757C192.491 196.608 191.528 195.134 191.302 194.057C191.075 192.979 190.565 191.165 190.565 191.165L190.282 195.871C190.282 195.871 190.735 199.952 190.565 201.256C190.395 202.617 189.375 202.674 189.375 202.674C189.659 204.771 189.035 204.941 188.469 204.941C187.902 204.941 187.336 203.977 187.336 203.977C187.336 203.977 187.166 204.374 186.486 204.318C185.806 204.261 184.333 200.973 184.333 200.973C183.88 202.447 182.86 201.823 182.747 201.483C182.634 201.143 181.387 196.154 181.501 188.671C181.501 184.306 179.971 180.394 179.178 178.807C178.951 178.296 178.781 178.013 178.781 178.013C178.781 178.013 185.296 175.349 185.863 176.766C185.976 177.049 186.146 177.389 186.429 177.73C187.449 179.317 189.375 181.414 190.282 182.718C191.245 184.079 193.284 187.367 193.511 189.805C193.738 192.242 194.078 195.757 194.078 195.757Z"
          fill="#9D5B3A"
        />
        <path
          d="M188.583 201.824C188.47 201.824 188.356 201.767 188.3 201.654C187.79 200.293 187.45 198.082 187.393 197.516C187.28 196.722 186.657 191.166 186.657 191.109C186.657 190.939 186.77 190.826 186.883 190.769C187.053 190.769 187.167 190.883 187.223 190.996C187.223 190.996 187.846 196.608 187.96 197.345C188.016 197.856 188.356 200.01 188.81 201.314C188.81 201.484 188.81 201.597 188.64 201.654C188.64 201.654 188.583 201.654 188.526 201.654L188.583 201.824Z"
          fill="#08080C"
        />
        <path
          d="M186.997 203.015C186.884 203.015 186.771 202.958 186.714 202.788C186.317 201.428 185.807 199.67 185.581 198.763C185.184 197.289 184.504 191.96 184.448 191.734C184.448 191.564 184.561 191.45 184.674 191.393C184.844 191.393 184.958 191.507 185.014 191.62C185.014 191.62 185.751 197.176 186.147 198.593C186.374 199.5 186.884 201.258 187.28 202.618C187.28 202.788 187.28 202.902 187.11 202.958C187.11 202.958 187.11 202.958 187.054 202.958L186.997 203.015Z"
          fill="#08080C"
        />
        <path
          d="M184.051 200.181C183.938 200.181 183.824 200.124 183.768 199.954C183.201 197.686 182.578 194.058 182.578 192.244C182.578 192.074 182.748 191.961 182.861 191.961C183.031 191.961 183.145 192.074 183.145 192.244C183.145 194.001 183.711 197.516 184.278 199.784C184.278 199.954 184.278 200.067 184.051 200.124C184.051 200.124 184.051 200.124 183.994 200.124L184.051 200.181Z"
          fill="#08080C"
        />
        <path
          d="M182.52 201.54C182.18 200.18 181.161 195.418 181.274 188.615C181.331 183.002 178.611 178.127 178.555 178.07C178.555 178.013 178.555 177.9 178.555 177.843C178.555 177.787 178.611 177.73 178.725 177.673C179.291 177.447 184.163 175.462 185.693 176.086C185.92 176.199 186.09 176.369 186.203 176.539C186.599 177.503 187.902 179.091 189.036 180.451C189.659 181.188 190.225 181.868 190.622 182.435C191.132 183.172 193.624 186.857 193.908 189.692C194.134 192.129 194.474 195.644 194.474 195.644H193.908C193.908 195.701 193.511 192.186 193.341 189.692C193.171 187.594 191.528 184.646 190.169 182.719C189.772 182.209 189.205 181.528 188.639 180.791C187.449 179.374 186.146 177.787 185.75 176.71C185.75 176.71 185.75 176.596 185.58 176.539C184.56 176.086 180.991 177.333 179.348 178.013C179.914 179.147 182.011 183.626 181.954 188.558C181.897 195.304 182.86 200.01 183.2 201.257L182.917 201.37H182.634L182.52 201.54Z"
          fill="#08080C"
        />
        <path
          d="M190.397 197.516C190.227 197.516 190.113 197.403 190.113 197.233C190.057 196.439 190 195.816 190 195.816L190.283 191.11C190.283 190.997 190.397 190.883 190.51 190.827C190.623 190.827 190.793 190.883 190.793 191.054C190.793 191.11 191.303 192.924 191.53 194.001C191.53 194.172 191.473 194.285 191.303 194.342C191.133 194.342 191.02 194.285 190.963 194.115C190.906 193.775 190.793 193.321 190.68 192.868L190.51 195.872C190.51 195.872 190.567 196.439 190.623 197.233C190.623 197.403 190.51 197.516 190.34 197.516H190.397Z"
          fill="#08080C"
        />
        <path
          d="M186.373 177.73C184.673 177.106 180.764 178.126 179.178 178.807C178.951 178.296 178.781 178.013 178.781 178.013C178.781 178.013 185.296 175.349 185.863 176.766C185.976 177.049 186.146 177.389 186.429 177.73H186.373Z"
          fill="#08080C"
        />
        <path
          d="M187.393 176.426C180.651 176.426 173.909 181.415 173.909 181.415C173.909 181.415 174.646 177.674 178.725 176.03C182.804 174.386 187.393 176.426 187.393 176.426Z"
          fill="#FA7353"
        />
        <path
          d="M173.909 181.698C173.796 181.698 173.739 181.698 173.682 181.585C173.569 181.471 173.626 181.301 173.739 181.188C174.022 180.961 180.707 176.199 187.392 176.142C187.562 176.142 187.676 176.256 187.676 176.426C187.676 176.596 187.562 176.709 187.392 176.709C180.821 176.709 174.136 181.585 174.022 181.641C174.022 181.641 173.909 181.641 173.852 181.641L173.909 181.698Z"
          fill="#08080C"
        />
        <path
          d="M272.884 94.168C270.334 93.6577 266.992 93.431 262.743 94.338L266.708 101.991C266.708 101.991 275.943 99.5536 272.884 94.168Z"
          fill="#08080C"
        />
        <path
          d="M252.205 91.7868C252.205 91.7868 253.395 97.5693 250.449 100.971C247.503 104.372 246.88 104.089 246.88 104.089L251.072 137.196H259.57L263.989 125.688L273.337 96.2087C273.337 96.2087 266.029 95.3584 264.896 94.2812C263.763 93.2041 262.233 82.2061 262.233 82.2061L252.205 91.8435V91.7868Z"
          fill="#C48C73"
        />
        <path
          d="M257.19 122.287C253.621 121.437 257.19 112.14 251.581 101.198C251.354 100.575 249.542 101.312 249.542 101.312L250.278 128.24L254.697 130.904L257.246 122.287H257.19Z"
          fill="#08080C"
        />
        <path
          d="M288.746 207.323C288.349 212.482 241.894 207.323 241.894 207.323C241.894 207.323 232.829 184.25 232.829 172.968C232.829 161.687 236.172 155.677 240.874 150.915C240.874 150.915 240.364 148.931 241.044 147.854C241.667 146.777 244.443 146.21 244.783 146.097V144.509C244.783 144.509 241.1 145.983 238.041 144.906C236.455 144.339 234.982 143.149 234.245 140.711C232.206 133.568 239.797 125.574 239.797 125.574C239.797 125.574 238.664 109.928 240.817 107.093C243.027 104.259 249.315 101.254 249.315 101.254C249.315 101.254 256.454 107.66 254.017 121.436C252.261 127.048 254.357 127.615 254.357 127.615C254.357 127.615 254.471 124.044 256.454 119.792C258.436 115.54 257.983 112.025 259.286 109.928C260.589 107.887 263.649 104.032 264.045 100.857C264.442 97.6824 270.39 98.0793 272.26 96.0951C274.073 94.1109 272.883 94.7912 274.299 96.7754C274.299 96.7754 279.002 98.3627 281.041 100.29C281.268 100.517 281.494 100.744 281.778 101.084C283.534 103.295 281.098 128.749 279.851 141.108C279.455 145.019 279.172 147.627 279.172 147.797C279.172 148.534 285.517 157.888 288.406 171.664C291.295 185.44 288.916 202.107 288.519 207.266L288.746 207.323Z"
          fill="#4780E6"
        />
        <path
          d="M272.317 129.033C272.317 129.033 272.714 130.45 267.898 130.337C263.082 130.224 261.27 128.126 261.27 128.126C261.27 128.126 266.992 128.013 268.861 127.446C270.731 126.936 272.147 123.081 272.147 123.081C272.147 123.081 269.428 111.856 270.277 111.799C271.127 111.742 272.147 115.711 272.147 115.711L273.62 111.402L273.393 119.396L274.64 121.323C274.413 123.818 276.283 135.156 276.623 137.197L272.374 129.033H272.317Z"
          fill="#08080C"
        />
        <path
          d="M266.765 139.293C266.652 139.293 266.595 139.293 266.538 139.18C265.179 137.366 262.233 131.923 262.119 131.697C262.063 131.583 262.119 131.413 262.233 131.3C262.346 131.243 262.516 131.3 262.629 131.413C262.629 131.413 265.632 137.082 266.992 138.84C267.105 138.953 267.048 139.123 266.992 139.237C266.992 139.237 266.878 139.293 266.822 139.293H266.765Z"
          fill="#08080C"
        />
        <path
          d="M247.616 126.424C247.616 126.424 247.446 127.615 244.33 129.542C243.253 128.182 243.027 126.991 243.027 126.991L240.874 126.368H247.559L247.616 126.424Z"
          fill="#08080C"
        />
        <path
          d="M242.233 120.302C242.064 120.302 242.007 120.131 242.007 119.961C242.347 118.204 243.593 115.426 245.633 113.102C245.746 112.988 245.916 112.988 246.029 113.102C246.143 113.215 246.143 113.385 246.029 113.499C244.103 115.71 242.913 118.431 242.573 120.131C242.573 120.245 242.403 120.358 242.29 120.358L242.233 120.302Z"
          fill="#08080C"
        />
        <path
          d="M243.82 114.689C243.706 114.689 243.65 114.689 243.593 114.576C242.857 113.499 242.517 112.308 242.517 112.252C242.517 112.082 242.517 111.968 242.743 111.912C242.913 111.912 243.026 111.912 243.083 112.138C243.083 112.138 243.423 113.329 244.103 114.293C244.216 114.406 244.16 114.576 244.046 114.689C244.046 114.689 243.933 114.689 243.876 114.689H243.82Z"
          fill="#08080C"
        />
        <path
          d="M263.424 112.65C263.424 113.67 262.687 114.634 261.724 114.804C260.761 114.974 260.024 114.35 260.024 113.33C260.024 112.309 260.761 111.346 261.724 111.176C262.687 111.005 263.424 111.629 263.424 112.65Z"
          fill="#08080C"
        />
        <path
          d="M261.951 114.067C262.765 114.067 263.424 113.407 263.424 112.593C263.424 111.779 262.765 111.119 261.951 111.119C261.137 111.119 260.478 111.779 260.478 112.593C260.478 113.407 261.137 114.067 261.951 114.067Z"
          fill="white"
        />
        <path
          d="M261.952 114.35C260.988 114.35 260.195 113.557 260.195 112.593C260.195 111.629 260.988 110.835 261.952 110.835C262.915 110.835 263.708 111.629 263.708 112.593C263.708 113.557 262.915 114.35 261.952 114.35ZM261.952 111.402C261.272 111.402 260.762 111.913 260.762 112.593C260.762 113.273 261.328 113.783 261.952 113.783C262.575 113.783 263.141 113.273 263.141 112.593C263.141 111.913 262.575 111.402 261.952 111.402Z"
          fill="#08080C"
        />
        <path
          d="M247.616 135.154C245.916 135.154 244.84 133.17 244.783 133.114C244.726 133 244.783 132.83 244.896 132.717C245.01 132.66 245.18 132.717 245.293 132.83C245.293 132.83 246.256 134.587 247.616 134.587C247.786 134.587 247.899 134.701 247.899 134.871C247.899 135.041 247.786 135.154 247.616 135.154Z"
          fill="#08080C"
        />
        <path
          d="M280.079 141.165C279.682 145.077 279.399 147.685 279.399 147.855C279.399 147.855 261.78 151.54 244.954 146.097V144.51C244.954 144.51 241.271 145.984 238.212 144.907C238.212 144.85 238.212 144.737 238.269 144.68C238.778 144.793 244.897 137.14 245.747 136.063C245.35 136.63 243.651 139.011 243.537 139.521C243.424 140.088 245.18 141.505 247.276 141.278C247.276 141.278 249.033 142.752 250.222 142.582C251.355 142.412 253.055 140.882 253.055 140.882C253.055 140.882 263.083 143.263 280.135 141.165H280.079Z"
          fill="#08080C"
        />
        <path
          d="M247.106 140.767C247.049 140.767 246.936 140.767 246.879 140.71C246.029 139.86 246.029 138.953 246.029 138.953C246.029 138.783 246.143 138.669 246.313 138.669C246.483 138.669 246.596 138.783 246.596 138.953C246.596 138.953 246.596 139.633 247.276 140.313C247.389 140.427 247.389 140.597 247.276 140.71C247.276 140.71 247.162 140.767 247.049 140.767H247.106Z"
          fill="#08080C"
        />
        <path
          d="M253.111 140.086C252.091 143.318 251.695 145.926 251.582 148.137L253.394 148.647C253.394 146.663 254.471 142.241 255.151 140.313L253.111 140.086Z"
          fill="#4780E6"
        />
        <path
          d="M270.674 140.144C271.694 143.943 272.09 147.004 272.203 149.668L270.391 150.235C270.391 147.911 269.314 142.695 268.634 140.428L270.674 140.144Z"
          fill="#4780E6"
        />
        <path
          d="M269.202 150.916C268.975 150.916 268.862 150.916 268.862 150.916C268.692 150.916 268.579 150.746 268.579 150.632C268.579 150.462 268.749 150.349 268.862 150.349C268.862 150.349 270.731 150.462 273.168 149.669C273.337 149.669 273.451 149.669 273.507 149.839C273.507 150.009 273.507 150.122 273.337 150.179C271.411 150.859 269.882 150.916 269.202 150.916Z"
          fill="#08080C"
        />
        <path
          d="M266.934 154.318C266.424 154.318 266.141 154.318 266.141 154.318C265.971 154.318 265.858 154.147 265.858 154.034C265.858 153.864 265.971 153.751 266.141 153.751C266.141 153.751 271.183 154.034 277.585 152.22C277.755 152.22 277.868 152.22 277.925 152.39C277.982 152.56 277.925 152.673 277.755 152.73C272.77 154.147 268.634 154.318 266.934 154.318Z"
          fill="#08080C"
        />
        <path
          d="M254.641 150.518C252.261 150.234 250.222 149.724 250.222 149.724C250.052 149.724 249.995 149.554 249.995 149.384C249.995 149.214 250.165 149.157 250.335 149.157C250.335 149.157 252.375 149.667 254.697 149.894C254.867 149.894 254.981 150.064 254.924 150.234C254.924 150.404 254.811 150.461 254.641 150.461V150.518Z"
          fill="#08080C"
        />
        <path
          d="M247.276 152.843C247.276 152.843 247.219 152.843 247.162 152.843C243.367 151.425 242.46 149.328 242.404 149.214C242.347 149.044 242.404 148.931 242.574 148.818C242.743 148.761 242.857 148.818 242.97 148.988C242.97 148.988 243.877 150.972 247.389 152.332C247.559 152.332 247.616 152.559 247.559 152.673C247.559 152.786 247.389 152.843 247.276 152.843Z"
          fill="#08080C"
        />
        <path
          d="M271.807 158.171C255.264 157.66 247.559 154.656 247.503 154.599C247.333 154.542 247.276 154.372 247.333 154.202C247.389 154.032 247.559 153.976 247.729 154.032C247.786 154.032 255.434 157.094 271.864 157.547C272.034 157.547 272.147 157.66 272.147 157.831C272.147 158.001 272.034 158.114 271.864 158.114L271.807 158.171Z"
          fill="#08080C"
        />
        <path
          d="M252.205 154.658C252.035 154.658 251.921 154.544 251.921 154.374C251.921 152.674 252.261 151.256 252.318 151.2C252.318 151.03 252.488 150.973 252.658 150.973C252.828 150.973 252.884 151.143 252.884 151.313C252.884 151.313 252.545 152.73 252.545 154.318C252.545 154.488 252.431 154.601 252.261 154.601L252.205 154.658Z"
          fill="#08080C"
        />
        <path
          d="M252.998 171.948C252.885 171.948 252.771 171.834 252.715 171.721C251.015 164.748 251.468 157.208 251.468 157.151C251.468 156.981 251.638 156.868 251.752 156.868C251.922 156.868 252.035 157.038 252.035 157.151C252.035 157.208 251.582 164.691 253.281 171.551C253.281 171.721 253.281 171.834 253.055 171.891C253.055 171.891 253.055 171.891 252.998 171.891V171.948Z"
          fill="#08080C"
        />
        <path
          d="M256.227 175.688C256.227 175.688 256.17 175.688 256.113 175.688C251.524 174.158 247.445 170.756 247.445 170.756C247.332 170.643 247.332 170.473 247.445 170.359C247.559 170.246 247.729 170.246 247.842 170.359C247.842 170.359 251.864 173.704 256.34 175.178C256.51 175.178 256.567 175.405 256.51 175.518C256.51 175.632 256.34 175.688 256.227 175.688Z"
          fill="#08080C"
        />
        <path
          d="M264.725 209.534C264.611 209.534 264.498 209.477 264.441 209.364C258.549 191.903 256.34 178.354 256.283 178.184C256.283 178.014 256.34 177.9 256.51 177.844C256.68 177.844 256.793 177.9 256.85 178.07C256.85 178.184 259.116 191.676 264.951 209.137C264.951 209.307 264.951 209.42 264.781 209.477C264.781 209.477 264.725 209.477 264.668 209.477L264.725 209.534Z"
          fill="#08080C"
        />
        <path
          d="M258.096 115.767C258.096 115.767 258.096 115.767 258.039 115.767C257.869 115.767 257.812 115.597 257.812 115.427C257.926 114.803 258.039 114.236 258.152 113.669C258.436 112.138 258.662 110.835 259.285 109.871C259.569 109.417 259.909 108.964 260.305 108.397C261.098 107.263 262.061 105.789 262.798 104.372C262.855 104.258 263.025 104.202 263.195 104.258C263.308 104.315 263.365 104.485 263.308 104.655C262.515 106.129 261.552 107.603 260.758 108.737C260.362 109.304 260.022 109.814 259.795 110.211C259.229 111.118 259.002 112.365 258.776 113.839C258.662 114.406 258.549 114.973 258.436 115.597C258.436 115.71 258.266 115.823 258.152 115.823L258.096 115.767Z"
          fill="#08080C"
        />
        <path
          d="M247.275 132.321H247.162C245.972 131.641 244.953 130.79 244.159 129.826C243.31 128.749 242.97 127.729 242.856 127.332L240.874 126.765C240.817 126.765 240.704 126.708 240.704 126.595C240.704 126.538 240.704 126.425 240.704 126.368C241.213 125.121 241.61 123.59 241.667 122.287C241.667 122.116 241.837 122.003 241.95 122.003C242.12 122.003 242.233 122.173 242.233 122.287C242.177 123.534 241.837 125.008 241.327 126.255L243.14 126.765C243.253 126.765 243.31 126.878 243.366 126.992C243.366 126.992 243.593 128.126 244.613 129.373C245.349 130.337 246.312 131.13 247.445 131.754C247.559 131.811 247.615 131.981 247.559 132.151C247.559 132.264 247.389 132.321 247.332 132.321H247.275Z"
          fill="#08080C"
        />
        <path
          d="M253 96.1522C253 96.1522 258.382 93.1476 259.232 89.3493C260.081 85.551 258.325 85.6077 258.325 85.6077L251.697 92.1272L253.056 96.0955L253 96.1522Z"
          fill="#08080C"
        />
        <path
          d="M243.425 66.1056C241.896 67.7497 242.292 70.3575 242.462 72.3983C242.689 75.0628 243.085 77.7273 243.709 80.3918C243.992 81.6957 243.085 81.8657 242.406 82.2059C242.009 80.6185 241.669 79.0312 241.443 77.3871C241.046 75.0061 240.763 72.6251 240.649 70.2441C240.649 68.3733 240.876 66.3891 242.236 64.9718C242.576 64.575 244.785 64.0648 245.068 64.2915C245.408 64.575 243.822 65.7655 243.425 66.1623V66.1056Z"
          fill="#FFDC69"
        />
        <path
          d="M253.509 134.248C252.886 131.527 252.376 128.749 252.206 125.915C252.092 123.194 252.546 120.529 252.942 117.808C253.282 115.484 253.339 113.159 252.829 110.835C252.319 108.567 251.413 106.47 250.336 104.429C248.24 100.46 245.464 96.8889 243.311 92.9206C243.311 92.9206 243.311 92.8072 243.255 92.8072C240.988 88.612 239.799 84.0201 239.006 79.3147C238.722 77.5573 238.496 75.7999 238.326 74.0425C238.326 73.4189 238.212 72.7953 238.212 72.1717C238.212 70.3009 238.439 68.3167 239.799 66.8994C240.139 66.5026 241.612 65.6522 241.895 65.9357C242.235 66.2191 241.385 67.6931 240.988 68.0899C239.459 69.6773 239.855 72.3417 240.025 74.3826C240.252 77.0471 240.649 79.7116 241.215 82.376C241.725 84.6437 242.291 86.8546 243.198 88.9522C243.708 90.1994 244.331 91.3899 244.954 92.5804C245.407 93.3741 245.861 94.1678 246.37 94.9047C248.693 98.703 251.356 102.275 253.112 106.413C253.962 108.397 254.529 110.438 254.812 112.592C255.095 114.917 254.755 117.184 254.359 119.452C253.962 121.833 253.735 124.157 253.905 126.595C254.075 129.033 254.585 131.414 255.095 133.795C255.322 134.872 253.679 135.325 253.452 134.248H253.509Z"
          fill="#FFDC69"
        />
        <path
          d="M245.011 92.5804L243.312 92.9772C243.312 92.9772 243.312 92.8638 243.255 92.8638C240.989 88.6687 239.799 84.0767 239.006 79.3714C238.723 77.614 238.496 75.8565 238.326 74.0991L240.082 74.4393C240.309 77.1037 240.706 79.7682 241.272 82.4327C241.782 84.7003 242.349 86.9113 243.255 89.0089C243.765 90.2561 244.388 91.4466 245.011 92.6371V92.5804Z"
          fill="#08080C"
        />
        <path
          d="M253 102.161C253 102.501 252.773 102.785 252.433 102.898C252.15 103.012 251.754 103.012 251.527 102.728C251.3 102.445 251.074 102.218 250.847 101.935C250.79 102.105 250.677 102.275 250.564 102.445C250.224 102.955 249.431 103.068 249.091 102.445C248.978 102.218 248.864 101.991 248.808 101.765C248.808 101.765 248.751 101.651 248.694 101.594C248.524 101.028 248.468 100.404 248.694 99.837C248.694 99.5536 248.694 99.3268 248.638 99.0433C245.408 96.3789 242.859 92.9774 241.329 89.0657C241.159 88.6689 240.989 88.2721 240.876 87.8185C240.536 86.7981 242.179 86.3446 242.519 87.365C243.142 89.2358 244.049 90.9932 245.125 92.6373C245.635 93.4876 246.258 94.2246 246.881 94.9616C246.711 94.4514 246.598 93.9978 246.485 93.4876C246.202 92.4105 246.032 91.3334 245.862 90.1995C245.862 90.1429 245.862 90.0295 245.862 89.9728C245.748 89.1791 246.881 88.8956 247.335 89.4059C247.788 89.009 248.524 89.009 248.694 89.746C248.694 89.8594 248.694 89.9728 248.751 90.0862H248.864C249.204 90.0862 249.487 90.2562 249.601 90.7098C249.941 92.2404 250.337 93.7144 250.79 95.245C251.414 97.5127 252.15 99.7803 252.943 102.048C252.943 102.105 252.943 102.161 252.943 102.218L253 102.161Z"
          fill="#FFDC69"
        />
        <path
          d="M259.119 132.433C259.232 133.511 257.532 133.511 257.419 132.433C257.079 127.955 256.343 123.476 255.549 118.998C255.436 118.487 255.38 117.977 255.266 117.467C254.756 114.632 254.19 111.798 253.623 108.963C253.51 108.396 253.397 107.83 253.283 107.319C253 105.845 252.717 104.371 252.434 102.841C251.98 100.46 251.414 98.0787 250.791 95.7543C250.734 95.5276 250.677 95.2441 250.564 95.0173C250.054 93.3166 249.488 91.6159 248.864 89.9152C248.241 88.2144 247.561 86.5137 246.882 84.7563C245.465 81.0714 244.275 77.3864 243.539 73.5315C243.312 72.4543 244.955 72.0008 245.182 73.0779C246.032 77.4998 247.505 81.6383 249.091 85.8334C249.714 87.4208 250.337 88.9514 250.904 90.5388C251.47 92.1261 251.98 93.6568 252.434 95.3008C252.547 95.811 252.717 96.3212 252.83 96.8315C253.793 100.686 254.473 104.541 255.21 108.453C255.21 108.566 255.21 108.68 255.266 108.737C256.796 116.56 258.439 124.383 259.062 132.32L259.119 132.433Z"
          fill="#FFDC69"
        />
        <path
          d="M252.377 134.02C250.507 131.129 250.054 127.614 250.224 124.212C250.337 120.811 250.904 117.409 251.017 114.008C251.074 111.967 251.017 109.983 250.96 107.942C250.904 106.581 250.79 105.221 250.62 103.803C250.507 102.896 250.394 101.989 250.281 101.026C250.281 100.969 250.281 100.912 250.281 100.856C249.997 98.6446 249.601 96.4337 249.204 94.2227C248.921 92.6354 248.581 91.1047 248.241 89.574C247.788 87.5899 247.391 85.6624 246.881 83.6782C246.598 82.6011 248.298 82.1475 248.524 83.2247C249.374 86.9096 250.224 90.6512 250.96 94.3928C250.96 94.3928 250.96 94.3928 250.96 94.4495C251.754 98.8714 252.377 103.293 252.66 107.772C252.773 109.983 252.83 112.194 252.717 114.348C252.717 115.028 252.66 115.709 252.603 116.389C252.093 122.001 250.79 128.124 253.906 133.056C254.473 133.963 253 134.813 252.433 133.906L252.377 134.02Z"
          fill="#FFDC69"
        />
        <path
          d="M248.524 100.516C247.391 100.63 246.258 100.686 245.182 100.8C244.105 100.913 244.219 100.913 243.822 100.8C243.425 100.686 243.312 100.233 243.199 99.8926C242.576 97.795 242.122 95.6408 241.669 93.4865C240.819 89.0646 240.31 84.6427 240.14 80.1641C239.97 75.6855 238.383 79.087 238.44 80.1641C238.61 85.1529 239.233 90.1417 240.253 95.0172C241.273 99.8926 240.763 97.3982 241.103 98.5887C241.443 99.7792 241.556 100.856 242.236 101.65C242.916 102.444 243.879 102.614 244.898 102.557C245.918 102.5 247.391 102.33 248.581 102.274C249.771 102.217 249.657 100.459 248.581 100.573L248.524 100.516Z"
          fill="#FFDC69"
        />
        <path
          d="M242.519 87.3075C243.142 89.1783 244.049 90.9357 245.125 92.5798C242.802 90.9357 241.499 89.1783 241.329 89.0082C241.159 88.6114 240.989 88.2146 240.876 87.761C240.536 86.7406 242.179 86.2871 242.519 87.3075Z"
          fill="#08080C"
        />
        <path
          d="M253.623 95.471C253.227 95.471 252.83 95.471 252.434 95.4143C252.264 95.4143 252.037 95.4143 251.867 95.3576C251.527 95.3009 251.131 95.2442 250.791 95.1308C250.734 95.1308 250.621 95.1308 250.564 95.1308C249.034 94.734 247.675 94.1671 246.485 93.4868C246.202 92.4097 246.032 91.3325 245.862 90.1987C245.862 90.142 245.862 90.0286 245.862 89.9719C245.748 89.1783 246.882 88.8948 247.335 89.405C247.788 89.0082 248.524 89.0082 248.694 89.7452C248.694 89.8586 248.694 89.9719 248.751 90.0853H248.864C248.241 88.3846 247.561 86.6839 246.882 84.9264C245.465 81.2415 244.275 77.5566 243.539 73.7016C243.312 72.6245 244.955 72.171 245.182 73.2481C246.032 77.67 247.505 81.8084 249.091 86.0036C249.714 87.5909 250.337 89.1216 250.904 90.7089C250.451 88.7247 250.054 86.7972 249.544 84.8131C249.261 83.7359 250.961 83.2824 251.187 84.3595C252.037 88.0444 252.887 91.7861 253.623 95.5277V95.471Z"
          fill="#08080C"
        />
        <path
          d="M249.091 97.3981C248.921 97.3981 248.808 97.2847 248.808 97.1147C248.808 97.1147 248.808 95.6407 247.902 93.3164C247.845 93.1463 247.902 93.0329 248.072 92.9762C248.242 92.9195 248.355 92.9762 248.411 93.1463C249.375 95.6407 249.318 97.1147 249.318 97.1713C249.318 97.3414 249.205 97.4548 249.035 97.4548L249.091 97.3981Z"
          fill="#08080C"
        />
        <path
          d="M251.019 100.517C250.849 100.517 250.736 100.346 250.736 100.233C250.849 98.5323 250.453 96.5482 250.396 96.5482C250.396 96.3781 250.453 96.2647 250.623 96.208C250.793 96.208 250.906 96.2647 250.963 96.4348C250.963 96.4915 251.416 98.4756 251.302 100.29C251.302 100.46 251.189 100.573 251.019 100.573V100.517Z"
          fill="#08080C"
        />
        <path
          d="M256.115 133.795C255.889 133.569 253.849 133.625 253.679 133.909C253.453 134.135 253.509 134.986 253.509 135.269C253.509 135.553 253.566 136.346 253.792 136.573C254.019 136.8 256.059 136.743 256.229 136.46C256.455 136.233 256.399 135.383 256.399 135.099C256.399 134.816 256.342 134.022 256.115 133.795Z"
          fill="white"
        />
        <path
          d="M256.002 136.856C255.889 136.856 255.775 136.799 255.719 136.629C255.719 136.459 255.719 136.346 255.945 136.289C256.002 136.289 256.059 136.289 256.059 136.289C256.059 136.289 256.115 136.176 256.172 135.779C256.172 135.609 256.342 135.496 256.455 135.496C256.625 135.496 256.739 135.666 256.739 135.779C256.739 136.176 256.625 136.459 256.512 136.629C256.455 136.743 256.342 136.799 256.115 136.856C256.115 136.856 256.115 136.856 256.059 136.856H256.002Z"
          fill="#08080C"
        />
        <path
          d="M272.6 130.166C272.374 129.939 270.334 129.996 270.164 130.279C269.937 130.506 269.994 131.356 269.994 131.64C269.994 131.923 270.051 132.717 270.277 132.944C270.504 133.171 272.543 133.114 272.713 132.83C272.94 132.604 272.883 131.753 272.883 131.47C272.883 131.186 272.827 130.393 272.6 130.166Z"
          fill="white"
        />
        <path
          d="M271.807 133.341C271.637 133.341 271.524 133.227 271.524 133.057C271.524 132.887 271.637 132.774 271.807 132.774C272.317 132.774 272.543 132.66 272.6 132.604C272.6 132.604 272.713 132.264 272.713 131.47C272.713 130.676 272.543 130.393 272.487 130.336C272.43 130.336 271.977 130.279 271.41 130.336C271.24 130.336 271.127 130.223 271.127 130.053C271.127 129.883 271.24 129.769 271.41 129.769C271.977 129.769 272.713 129.769 272.94 129.996C272.997 130.053 273.28 130.336 273.336 131.527C273.336 132.717 273.166 133.001 273.11 133.057C273.053 133.114 272.883 133.284 271.977 133.397L271.807 133.341Z"
          fill="#08080C"
        />
        <path
          d="M259.232 132.378C259.005 132.151 256.966 132.208 256.796 132.491C256.569 132.718 256.569 133.568 256.626 133.852C256.682 134.135 256.626 134.929 256.909 135.212C257.136 135.439 259.175 135.382 259.345 135.099C259.572 134.872 259.572 134.022 259.515 133.738C259.458 133.455 259.515 132.661 259.232 132.378Z"
          fill="white"
        />
        <path
          d="M257.814 135.608C256.851 135.608 256.681 135.438 256.625 135.381C256.511 135.268 256.511 135.098 256.625 134.984C256.738 134.871 256.908 134.871 257.021 134.984C257.078 134.984 257.361 135.041 257.871 135.041C258.041 135.041 258.154 135.155 258.154 135.325C258.154 135.495 257.984 135.608 257.814 135.608Z"
          fill="#08080C"
        />
        <path
          d="M251.582 94.6216C251.582 104.996 255.491 114.804 257.644 124.838C258.267 127.729 258.72 130.677 258.947 133.625C259.174 136.573 260.703 134.702 260.647 133.625C259.91 123.194 255.944 113.5 254.075 103.295C252.205 93.0909 253.282 97.5695 253.225 94.6782C253.168 91.787 251.525 93.6011 251.525 94.6782L251.582 94.6216Z"
          fill="#FFDC69"
        />
        <path
          d="M261.102 79.939C260.479 86.005 257.42 90.9371 255.097 92.3543C252.038 94.2252 247.279 91.9575 244.73 89.9166C242.237 87.9324 241.897 83.1704 241.897 83.1704C241.614 81.6964 241.217 80.3359 240.821 79.1453C240.311 77.8415 239.178 75.1203 238.951 72.5692C238.951 72.3424 238.951 72.1724 238.951 71.9456C238.781 64.2356 250.565 60.494 256.287 63.3286C259.516 64.9159 260.932 68.4874 261.272 72.796C261.442 75.0069 261.386 77.3879 261.159 79.8256L261.102 79.939Z"
          fill="#C48C73"
        />
        <path
          d="M261.214 72.909C260.648 73.0791 259.968 73.1357 259.062 73.0791C255.152 72.7956 253.056 70.6413 251.697 70.1311C250.337 69.6776 243.199 69.054 241.046 70.0744C240.309 70.4146 239.573 71.2083 238.893 72.0586C238.723 64.3486 250.507 60.607 256.229 63.4416C259.458 65.0289 260.874 68.6005 261.214 72.909Z"
          fill="#08080C"
        />
        <path
          d="M244.162 82.0932C245.239 82.0932 245.239 80.3925 244.162 80.3925C243.086 80.3925 243.086 82.0932 244.162 82.0932Z"
          fill="#08080C"
        />
        <path
          d="M250.337 79.5423C251.413 79.5423 251.413 77.8416 250.337 77.8416C249.26 77.8416 249.26 79.5423 250.337 79.5423Z"
          fill="#08080C"
        />
        <path
          d="M246.258 86.1746C245.805 86.1746 245.181 86.0046 244.615 85.381C243.992 84.644 244.955 83.7369 245.691 83.1133C245.918 82.8866 246.201 82.6598 246.258 82.5464C246.484 82.0929 246.088 80.9591 245.748 80.3355C245.691 80.2221 245.748 80.052 245.861 79.9386C245.975 79.8819 246.145 79.9386 246.258 80.052C246.371 80.2221 247.221 81.9228 246.768 82.7732C246.654 82.9999 246.428 83.2267 246.088 83.5102C245.635 83.9637 244.785 84.7007 245.068 84.9841C245.805 85.8345 246.711 85.4943 246.768 85.4943C246.938 85.4943 247.051 85.4943 247.108 85.6644C247.108 85.8345 247.108 85.9479 246.938 86.0046C246.938 86.0046 246.654 86.1179 246.258 86.1179V86.1746Z"
          fill="#08080C"
        />
        <path
          d="M248.637 87.1375C248.977 87.5911 250.11 89.0083 251.186 88.4414C252.489 87.7611 253.622 84.0195 253.622 84.0195C253.282 84.303 250.393 86.0604 248.637 87.1375Z"
          fill="white"
        />
        <path
          d="M250.678 88.8951C249.828 88.8951 248.978 88.1581 248.411 87.3077C248.411 87.251 248.355 87.1376 248.411 87.081C248.411 87.0243 248.468 86.9109 248.525 86.9109C252.377 84.5866 253.34 83.963 253.51 83.7929C253.624 83.7362 253.737 83.6795 253.85 83.7929C253.963 83.8496 254.02 84.0196 253.963 84.133C253.85 84.5299 252.717 88.0447 251.414 88.725C251.187 88.8384 250.961 88.8951 250.734 88.8951H250.678ZM249.035 87.251C249.431 87.7046 250.281 88.6116 251.074 88.2148C251.867 87.8179 252.66 85.9471 253.114 84.7566C252.377 85.2668 251.017 86.0605 249.035 87.251Z"
          fill="#08080C"
        />
        <path
          d="M247.28 88.2732C247.166 88.2732 247.11 88.2732 247.053 88.1598C246.996 88.0464 247.053 87.8763 247.166 87.7629L248.526 86.9126L253.511 83.7946C253.625 83.7379 253.795 83.7946 253.908 83.908C253.908 83.908 253.908 84.0213 253.908 84.078C253.908 84.1914 253.908 84.2481 253.795 84.3048L248.809 87.4228L247.45 88.2732C247.45 88.2732 247.336 88.2732 247.28 88.2732Z"
          fill="#08080C"
        />
        <path
          d="M250.112 90.8791C249.942 90.8791 249.829 90.7657 249.829 90.5956C249.829 90.4255 249.942 90.3122 250.112 90.3122C252.152 90.1988 252.945 88.7815 252.945 88.7815C253.002 88.6681 253.172 88.6114 253.342 88.6681C253.455 88.7248 253.512 88.8949 253.455 89.0649C253.455 89.1216 252.435 90.709 250.169 90.8791H250.112Z"
          fill="#08080C"
        />
        <path
          d="M247.675 76.1972C247.675 76.1972 248.468 74.4965 249.998 74.2131C251.528 73.9296 253.001 75.0067 253.001 75.0067C253.001 75.0067 251.301 74.95 250.565 75.0634C249.828 75.1768 247.732 76.1972 247.732 76.1972H247.675Z"
          fill="#08080C"
        />
        <path
          d="M244.501 77.615C244.501 77.615 242.632 78.2386 242.065 78.4087C241.499 78.5788 240.989 79.7126 240.989 79.7126C240.989 79.7126 240.819 78.0686 242.065 77.445C243.312 76.8214 244.501 77.615 244.501 77.615Z"
          fill="#08080C"
        />
        <path
          d="M260.818 80.1098C260.818 80.1098 262.801 79.5429 263.028 76.3682C263.255 73.1935 261.045 70.7558 258.326 73.7604C258.949 79.3161 260.818 80.0531 260.818 80.0531V80.1098Z"
          fill="#C48C73"
        />
        <path
          d="M260.818 80.3936C260.705 80.3936 260.592 80.3369 260.535 80.1668C260.535 79.9968 260.535 79.8834 260.705 79.8267C260.762 79.8267 262.518 79.2598 262.688 76.3685C262.801 74.6678 262.235 73.1938 261.215 72.797C260.422 72.4569 259.459 72.9104 258.496 73.9875C258.382 74.1009 258.212 74.1009 258.099 73.9875C257.986 73.8741 257.986 73.7041 258.099 73.5907C259.629 71.8333 260.875 72.0033 261.498 72.2868C262.688 72.797 263.425 74.441 263.311 76.4252C263.085 79.77 260.988 80.3936 260.875 80.3936C260.875 80.3936 260.875 80.3936 260.818 80.3936Z"
          fill="#08080C"
        />
        <path
          d="M260.025 77.786C259.855 77.786 259.742 77.6726 259.742 77.5025C259.742 76.8789 259.912 74.7247 260.479 74.2711C260.649 74.1011 260.875 74.1011 261.045 74.1011C261.498 74.2145 261.782 74.7247 261.838 74.8381C261.895 74.9514 261.838 75.1215 261.725 75.2349C261.612 75.2916 261.442 75.2349 261.329 75.1215C261.215 74.9514 261.045 74.7247 260.932 74.668C260.932 74.668 260.932 74.668 260.819 74.668C260.592 74.8381 260.365 76.2553 260.309 77.5025C260.309 77.6726 260.195 77.786 260.025 77.786Z"
          fill="#08080C"
        />
        <path
          d="M253.509 81.9234C254.245 81.8667 254.415 80.903 253.679 80.9597C252.942 81.0164 252.829 81.9801 253.509 81.9234Z"
          fill="white"
        />
        <path
          d="M255.152 81.3565C255.889 81.2432 256.172 80.2794 255.379 80.3928C254.586 80.5062 254.416 81.4699 255.152 81.3565Z"
          fill="white"
        />
        <path
          d="M255.435 82.8299C256.172 82.7732 256.399 81.8095 255.662 81.9228C254.926 82.0362 254.756 82.9433 255.435 82.8299Z"
          fill="white"
        />
        <path
          d="M243.312 86.7982C243.765 86.7982 243.765 86.1746 243.312 86.1746C242.858 86.1746 242.858 86.7982 243.312 86.7982Z"
          fill="white"
        />
        <path
          d="M244.331 87.706C244.841 87.706 244.898 86.969 244.331 87.0257C243.765 87.0824 243.765 87.7627 244.331 87.706Z"
          fill="white"
        />
        <path
          d="M246.315 62.7611C246.315 62.7611 248.128 62.8178 248.071 62.9312C248.015 63.0446 247.165 63.2713 246.372 63.5548C245.579 63.8382 245.069 63.8382 245.069 63.8382C245.069 63.8382 245.409 62.9879 246.259 62.8178L246.315 62.7611Z"
          fill="#FFDC69"
        />
        <path
          d="M244.672 63.4406C244.672 63.4406 246.202 63.6106 246.145 63.8374C246.089 64.0642 244.956 64.5744 243.936 64.9145C242.916 65.2547 242.689 64.9145 242.689 64.9145C242.689 64.9145 243.369 63.8941 244.672 63.4972V63.4406Z"
          fill="#FFDC69"
        />
        <path
          d="M243.087 64.2911C243.087 64.2911 244.787 64.5746 244.787 64.7447C244.673 65.2549 242.974 65.595 242.124 66.2753C241.274 66.9556 240.368 67.1824 240.368 67.1824C240.368 67.1824 240.708 64.9147 243.144 64.2911H243.087Z"
          fill="#FFDC69"
        />
        <path
          d="M241.5 65.9361C241.5 65.9361 243.086 65.8228 243.086 65.9361C243.086 66.0495 241.386 67.5235 240.537 68.2038C239.687 68.8841 238.78 68.8274 238.78 68.8274C238.78 68.8274 239.12 66.5597 241.556 65.9361H241.5Z"
          fill="#FFDC69"
        />
        <path
          d="M279.796 98.1941C275.944 96.4366 273.678 92.8651 271.582 89.3503C269.486 85.8354 270.505 87.4795 269.939 86.5157C269.372 85.552 268.919 84.9851 268.636 84.1347C267.956 82.434 267.616 80.5632 266.88 78.8624C266.143 77.1617 265.69 76.5948 265.123 75.4043C264.557 74.2138 263.877 72.6264 263.367 71.2091C262.857 69.7919 261.328 70.6422 261.724 71.6627C262.687 74.1571 263.764 76.5381 265.01 78.9191C265.916 80.5632 266.256 82.434 266.88 84.1914C267.503 85.9488 268.862 87.933 269.939 89.7471C271.015 91.5612 272.092 93.432 273.451 95.1327C274.811 96.8335 276.794 98.5909 278.947 99.6113C281.1 100.632 280.816 98.5909 279.796 98.1374V98.1941Z"
          fill="#FFDC69"
        />
        <path
          d="M246.203 68.4306C249.828 68.6007 252.831 69.3376 255.72 72.9092C258.61 76.4807 258.156 75.0634 258.893 76.5374C259.629 78.0114 259.629 79.2586 259.629 80.6191C259.629 83.6805 258.893 86.7418 258.836 89.8031C258.78 92.8644 258.78 92.6376 259.346 93.7715C259.913 94.9053 260.139 95.1887 260.536 95.869C261.159 96.9461 261.839 98.0233 262.575 99.1004C263.312 100.178 263.538 100.801 264.332 100.915C265.408 101.085 265.861 99.4406 264.785 99.2705C264.785 99.2705 265.011 99.4406 264.841 99.2705C264.672 99.1004 264.615 99.0437 264.502 98.8736C264.275 98.5902 263.992 98.1367 263.708 97.7398C263.085 96.7761 262.462 95.8123 261.896 94.8486C261.329 93.8848 260.989 93.5447 260.762 92.8644C260.309 91.7306 260.479 90.0298 260.536 88.7826C260.706 85.7213 261.442 82.6033 261.272 79.542C261.102 76.4807 260.876 76.5941 260.139 75.2902C259.403 73.9863 258.1 72.6824 256.91 71.6053C253.907 68.9408 250.282 66.7865 246.203 66.6165C242.124 66.4464 245.126 68.2605 246.203 68.3172V68.4306Z"
          fill="#FFDC69"
        />
        <path
          d="M281.61 97.683C280.987 97.0594 280.477 97.0594 279.457 97.4563C278.438 97.8531 278.268 98.1933 277.814 98.7035C277.361 99.2137 277.644 98.8168 277.644 98.8735C277.531 99.0436 277.418 98.8735 277.644 98.9302C276.965 98.8735 276.171 98.1932 275.718 97.7397C274.982 97.0594 274.415 96.0957 273.962 95.2453C273.509 94.395 272.999 93.3745 272.602 92.4108C272.036 90.8801 271.696 89.3495 271.356 87.7621C270.676 84.5874 270.053 81.2993 268.863 78.2947C267.674 75.2901 267.504 75.7436 266.71 74.4964C265.917 73.2492 264.501 71.095 263.311 69.4509C262.121 67.8069 260.705 65.9361 258.836 64.6889C256.966 63.4417 254.02 62.0811 251.641 62.7047C249.261 63.3283 251.357 65.1991 252.377 64.9156C256.343 63.8385 259.515 67.0699 261.612 69.9611C262.745 71.4918 263.764 73.0791 264.784 74.6665C265.804 76.2538 266.484 77.0475 267.05 78.2947C268.297 80.9592 268.807 84.0772 269.43 86.9117C270.053 89.7463 270.789 93.2044 272.432 96.039C273.452 97.7397 274.585 99.3271 276.455 100.177C278.324 101.028 278.098 100.631 278.834 99.9507C279.571 99.2704 279.797 99.4971 280.194 99.157C280.59 98.8168 280.93 100.234 282.12 100.971C282.12 100.971 282.573 98.6468 281.61 97.6263V97.683Z"
          fill="#FFDC69"
        />
        <path
          d="M249.657 64.5186C251.413 64.5186 253.112 64.6887 254.755 65.3123C256.398 65.9359 257.758 67.1264 258.891 68.487C260.024 69.8476 260.931 71.3782 261.724 72.9089C262.517 74.4395 263.367 76.0836 263.707 77.7276C264.046 79.3717 264.556 82.3196 265.066 84.6439C265.576 86.9683 266.029 88.3855 266.596 90.1996C267.672 93.6011 268.862 96.8892 269.995 100.291C271.525 105.109 273.508 111.119 270.108 115.654C266.709 120.189 270.902 117.355 271.581 116.504C274.697 112.309 273.734 106.753 272.375 102.161C271.015 97.5695 269.882 94.6782 268.635 90.9366C267.276 86.7415 266.256 82.433 265.519 78.0678C264.783 73.7026 264.443 74.4962 263.593 72.7955C262.743 71.0948 261.894 69.5074 260.761 68.0335C259.628 66.5595 257.871 64.8021 255.888 63.895C253.906 62.988 251.809 62.7045 249.657 62.7612C247.504 62.8179 248.354 63.158 248.41 63.6116C248.467 64.0651 249.033 64.2352 249.657 64.4619V64.5186Z"
          fill="#FFDC69"
        />
        <path
          d="M272.093 119.055C271.753 115.087 271.526 111.062 270.96 107.15C270.733 105.506 270.223 103.919 269.94 102.275C269.657 100.631 269.43 97.7397 268.977 95.5287C268.524 93.3178 267.617 91.0501 267.107 88.7825C266.597 86.5149 266.088 84.1905 265.578 81.9229C264.558 77.4443 262.858 72.9657 260.026 69.2808C257.193 65.5959 253.057 63.2716 248.185 63.385C243.313 63.4983 246.599 64.1219 246.485 64.4054C246.259 64.9156 246.599 65.1991 247.279 65.1424C251.471 64.6888 255.607 66.7297 258.326 69.8477C261.215 73.1925 262.802 77.6144 263.878 81.8662C264.954 86.118 264.841 86.2881 265.351 88.499C265.861 90.71 266.597 92.8076 267.164 94.9618C267.73 97.1161 267.844 99.2136 268.127 101.311C268.41 103.409 268.864 104.713 269.203 106.413C269.94 110.552 270.053 114.804 270.45 118.999C270.846 123.194 272.263 120.076 272.149 118.999L272.093 119.055Z"
          fill="#FFDC69"
        />
        <path
          d="M245.748 66.1622C249.94 66.1622 253.622 68.3164 256.455 71.2643C259.288 74.2123 261.384 78.8609 262.517 83.2261C263.65 87.5914 264.5 93.0337 265.69 97.9091C266.936 103.068 268.466 108.227 269.202 113.499C270.052 119.508 269.712 125.631 269.372 131.697C269.032 137.763 271.015 132.774 271.072 131.697C271.355 126.085 271.695 120.472 271.072 114.86C270.449 109.247 268.976 104.088 267.616 98.7595C266.256 93.4305 265.633 88.4417 264.273 83.3962C263.084 78.8042 261.271 72.9651 258.155 69.3369C255.039 65.7086 251.3 64.4614 246.428 64.4614C241.555 64.4614 244.615 65.4252 244.615 65.822C244.615 66.2188 245.181 66.1622 245.748 66.1622Z"
          fill="#FFDC69"
        />
        <path
          d="M243.937 67.5794C246.43 67.5794 248.922 67.6927 251.302 68.373C253.681 69.0533 255.324 70.2438 256.797 71.9446C260.026 75.5728 261.159 80.3915 262.009 85.0402C262.859 89.6889 264.162 95.4146 265.408 100.574C266.655 105.732 267.958 110.891 268.751 116.164C269.544 121.436 269.884 128.012 268.751 133.851C267.618 139.69 270.167 135.382 270.394 134.305C272.49 123.363 269.997 112.309 267.391 101.707C264.785 91.1061 264.785 90.9927 263.766 85.5504C262.746 80.1081 261.726 75.5728 258.553 71.5477C255.381 67.5227 254.928 67.9762 252.492 67.0692C250.055 66.1621 247.393 65.9353 244.503 65.8786C241.614 65.8219 242.804 66.9558 242.86 67.4093C242.917 67.8628 243.37 67.6361 243.88 67.6361L243.937 67.5794Z"
          fill="#FFDC69"
        />
        <path
          d="M243.199 67.3532C242.633 67.3532 241.557 68.3169 241.726 68.8272C241.896 69.3374 241.84 69.0539 242.236 69.0539C245.579 68.9405 249.318 68.2036 252.321 70.0177C255.323 71.8318 255.607 73.2491 256.683 75.1199C257.759 76.9907 258.496 79.0315 259.062 81.0724C261.952 91.6736 264.105 102.558 265.917 113.386C266.937 119.566 267.844 125.745 268.75 131.981C269.657 138.217 269.373 132.661 269.77 132.548C270.166 132.434 270.45 131.981 270.336 131.527C268.807 120.246 266.994 108.964 264.728 97.7963C262.462 86.6281 262.292 86.6281 260.819 81.1291C259.346 75.6301 257.646 72.2286 254.077 69.2807C250.508 66.3327 246.202 67.2398 242.18 67.4099"
          fill="#FFDC69"
        />
        <path
          d="M238.893 68.6016C237.534 70.0189 237.25 72.0031 237.307 73.8739C237.364 75.7447 237.704 78.6926 238.1 81.0169C238.837 85.7223 240.083 90.3142 242.349 94.5094C244.502 98.5344 247.335 102.163 249.487 106.188C251.64 110.213 251.47 110.326 251.98 112.594C252.49 114.861 252.433 117.186 252.093 119.567C251.754 121.948 251.244 124.952 251.357 127.674C251.47 130.508 252.037 133.229 252.66 136.007C253.283 138.785 254.53 136.631 254.303 135.554C253.793 133.173 253.283 130.792 253.113 128.354C252.943 125.916 253.17 123.592 253.566 121.211C253.963 118.83 254.246 116.676 254.02 114.351C253.793 112.027 253.17 110.156 252.377 108.172C250.621 104.033 248.014 100.405 245.635 96.6636C243.256 92.922 243.312 92.8086 242.462 90.7111C241.613 88.6135 240.989 86.3459 240.48 84.1349C239.97 81.924 239.516 78.8627 239.233 76.1415C238.95 73.4203 238.667 71.4928 240.196 69.8488C241.726 68.2048 241.556 68.2614 241.159 68.0347C240.536 67.6378 239.347 68.2614 239.007 68.6583L238.893 68.6016Z"
          fill="#FFDC69"
        />
        <path
          d="M261.95 95.2462C261.837 95.2462 261.78 95.2462 261.723 95.1329C261.61 94.9628 261.497 94.736 261.383 94.566C261.1 94.1124 260.76 93.6022 260.59 93.092C260.137 91.9582 260.25 90.3141 260.364 89.0669V88.8969C260.364 88.7268 260.534 88.6134 260.647 88.6134C260.817 88.6134 260.93 88.7835 260.93 88.8969V89.0669C260.817 90.2574 260.76 91.8448 261.1 92.8652C261.27 93.3188 261.553 93.7723 261.837 94.2258C261.95 94.3959 262.063 94.6226 262.176 94.7927C262.233 94.9061 262.176 95.0762 262.063 95.1896C262.063 95.1896 261.95 95.1896 261.893 95.1896L261.95 95.2462Z"
          fill="#08080C"
        />
        <path
          d="M262.574 99.4409C262.461 99.4409 262.404 99.4409 262.347 99.3276C261.668 98.3638 261.044 97.3434 260.308 96.0962L260.025 95.6426C259.685 95.1324 259.401 94.5655 259.118 94.0553C258.495 92.8648 258.552 91.164 258.552 89.9168C258.552 88.4429 258.778 87.0256 258.948 85.6083C258.948 85.4383 259.118 85.3249 259.288 85.3816C259.458 85.3816 259.571 85.5516 259.515 85.7217C259.345 87.0823 259.175 88.4996 259.118 89.9735C259.118 91.164 259.062 92.7514 259.571 93.8285C259.855 94.3387 260.138 94.9057 260.478 95.4159L260.761 95.8694C261.498 97.0599 262.121 98.0804 262.801 99.0441C262.914 99.1575 262.857 99.3276 262.744 99.4409C262.744 99.4409 262.631 99.4409 262.574 99.4409Z"
          fill="#08080C"
        />
        <path
          d="M279.797 98.4772C279.797 98.4772 279.74 98.4772 279.684 98.4772C279.117 98.1937 278.551 97.9103 278.041 97.5701C277.927 97.4568 277.871 97.2867 277.984 97.1733C278.097 97.0599 278.267 97.0032 278.381 97.1166C278.891 97.4568 279.4 97.7402 279.967 97.967C280.137 98.0237 280.194 98.1937 280.08 98.3638C280.08 98.4772 279.91 98.5339 279.797 98.5339V98.4772Z"
          fill="#08080C"
        />
        <path
          d="M280.251 99.5556C280.138 99.5556 280.081 99.5556 280.025 99.4422C279.968 99.3288 280.025 99.1587 280.138 99.0453C280.818 98.5918 280.931 98.2517 280.931 98.2517C280.931 98.0816 281.101 98.0249 281.271 98.0249C281.441 98.0249 281.498 98.195 281.498 98.3651C281.498 98.4784 281.328 98.932 280.421 99.4989C280.421 99.4989 280.308 99.4989 280.251 99.4989V99.5556Z"
          fill="#08080C"
        />
        <path
          d="M277.702 99.2152C276.852 99.1018 275.889 98.2514 275.606 97.968C274.812 97.231 274.246 96.2672 273.793 95.4169C273.736 95.3035 273.793 95.1334 273.906 95.02C274.019 94.9634 274.189 95.02 274.303 95.1334C274.756 95.9271 275.322 96.8908 276.059 97.5144C276.569 97.968 277.305 98.5349 277.872 98.5916C278.042 98.5916 278.155 98.7616 278.098 98.9317C278.098 99.1018 277.985 99.1585 277.815 99.1585L277.702 99.2152Z"
          fill="#08080C"
        />
        <path
          d="M277.645 100.915C277.192 100.915 276.739 100.745 276.399 100.575C274.416 99.6113 273.17 97.9106 272.263 96.3232C271.81 95.5862 271.413 94.7926 271.073 93.8855C271.017 93.7154 271.074 93.602 271.243 93.4887C271.413 93.432 271.527 93.4887 271.64 93.6587C271.98 94.5091 272.377 95.3028 272.773 95.9831C273.68 97.457 274.813 99.1578 276.682 100.008C277.532 100.405 278.155 100.348 278.722 99.8381C278.835 99.7247 279.005 99.7247 279.118 99.8381C279.232 99.9514 279.232 100.122 279.118 100.235C278.608 100.688 278.155 100.858 277.702 100.858L277.645 100.915Z"
          fill="#08080C"
        />
        <path
          d="M269.997 89.6335C269.884 89.6335 269.77 89.5202 269.714 89.4068C269.544 88.7265 269.43 88.0462 269.26 87.3659V87.0824C269.091 86.6289 269.034 86.2321 268.921 85.7786C268.411 83.2842 267.844 80.7331 266.824 78.4654C266.428 77.5584 265.805 76.6513 265.238 75.8009C265.011 75.4608 264.785 75.1773 264.615 74.8372C264.502 74.7238 264.615 74.5537 264.672 74.4404C264.785 74.327 264.955 74.4404 265.068 74.497C265.295 74.8372 265.465 75.1206 265.691 75.4608C266.314 76.3112 266.881 77.2182 267.334 78.182C268.411 80.5063 268.921 83.1141 269.487 85.6085C269.6 86.062 269.657 86.4588 269.77 86.9124V87.1958C269.997 87.8761 270.11 88.5564 270.28 89.2367C270.28 89.4068 270.224 89.5202 270.054 89.5768C270.054 89.5768 270.054 89.5768 269.997 89.5768V89.6335Z"
          fill="#08080C"
        />
        <path
          d="M277.703 99.2145C276.853 99.1011 275.89 98.2508 275.606 97.9673C274.813 97.2303 274.247 96.2666 273.794 95.4162C273.227 94.5092 272.774 93.5454 272.434 92.525C271.867 91.051 271.527 89.5204 271.188 87.8196C271.188 87.6496 271.244 87.5362 271.414 87.4795C271.584 87.4795 271.697 87.5362 271.754 87.7063C272.094 89.3503 272.434 90.881 273 92.2982C273.34 93.262 273.794 94.169 274.303 95.0761C274.757 95.8698 275.323 96.8335 276.06 97.4571C276.57 97.9106 277.306 98.4775 277.873 98.5342C278.043 98.5342 278.156 98.7043 278.099 98.8744C278.099 99.0444 277.986 99.1011 277.816 99.1011L277.703 99.2145Z"
          fill="#08080C"
        />
        <path
          d="M270.223 96.0389C270.11 96.0389 269.997 95.9822 269.94 95.8689L269.827 95.5287C269.317 94.0547 268.807 92.5241 268.297 91.0501C266.994 86.9684 265.918 82.6598 265.125 78.1246C264.785 76.2538 264.048 74.553 263.255 72.9657C262.292 70.9815 261.386 69.5075 260.479 68.2603C259.006 66.3328 257.42 64.9723 255.72 64.2353C255.494 64.1219 255.324 64.0652 255.097 63.9518C254.927 63.8951 254.87 63.7251 254.927 63.555C254.984 63.3849 255.154 63.3282 255.324 63.3849C255.55 63.4983 255.777 63.555 255.947 63.6684C257.76 64.462 259.403 65.8793 260.932 67.9202C261.895 69.1674 262.802 70.698 263.765 72.6822C264.558 74.3263 265.351 76.0837 265.691 78.0112C266.428 82.4898 267.504 86.7983 268.807 90.8233C269.317 92.2973 269.827 93.828 270.337 95.3019L270.45 95.6421C270.45 95.8122 270.45 95.9255 270.28 95.9822C270.28 95.9822 270.223 95.9822 270.167 95.9822L270.223 96.0389Z"
          fill="#08080C"
        />
        <path
          d="M273.51 111.742C273.34 111.742 273.226 111.572 273.283 111.402C273.68 108.114 272.773 104.599 272.093 102.275C271.47 100.177 270.79 98.1366 269.997 95.869C269.997 95.6989 269.997 95.5855 270.167 95.5288C270.337 95.5288 270.45 95.5288 270.507 95.6989C271.3 97.9665 271.98 100.007 272.603 102.105C273.34 104.486 274.246 108.058 273.793 111.459C273.793 111.629 273.68 111.686 273.51 111.686V111.742Z"
          fill="#08080C"
        />
        <path
          d="M271.98 109.644C271.81 109.644 271.697 109.531 271.697 109.36C271.697 106.413 270.62 103.295 269.771 100.517V100.347C269.657 100.177 269.771 100.063 269.884 100.006C270.054 100.006 270.167 100.006 270.224 100.177V100.347C271.187 103.124 272.15 106.299 272.207 109.304C272.207 109.474 272.093 109.587 271.923 109.587L271.98 109.644Z"
          fill="#08080C"
        />
        <path
          d="M268.978 95.7564C268.864 95.7564 268.751 95.643 268.694 95.5297C268.411 94.2824 268.071 92.9786 267.675 91.788C267.391 90.8243 267.051 89.8039 266.825 88.7834C266.485 87.4795 266.202 86.1189 265.918 84.8151C265.692 83.8513 265.522 82.8876 265.295 81.9238C264.049 76.6516 262.293 72.5698 259.8 69.3951C256.967 65.8236 253.738 64.0662 249.376 63.6693C249.206 63.6693 249.092 63.4993 249.092 63.3859C249.092 63.2158 249.262 63.1024 249.376 63.1024C253.908 63.4426 257.25 65.3134 260.196 68.9983C262.746 72.2297 264.559 76.3681 265.805 81.7537C266.032 82.7175 266.258 83.6812 266.428 84.645C266.711 85.9489 266.995 87.3095 267.335 88.6134C267.561 89.5771 267.901 90.5975 268.184 91.5613C268.581 92.8085 268.978 94.0557 269.204 95.3596C269.204 95.5297 269.148 95.643 268.978 95.6997C268.978 95.6997 268.978 95.6997 268.921 95.6997L268.978 95.7564Z"
          fill="#08080C"
        />
        <path
          d="M269.942 102.559C269.828 102.559 269.715 102.446 269.658 102.332C269.488 101.312 269.375 100.291 269.262 99.2709C269.149 98.0237 268.979 96.7765 268.752 95.586C268.752 95.4159 268.809 95.3025 268.979 95.2458C269.149 95.2458 269.262 95.3025 269.318 95.4726C269.602 96.7198 269.715 97.967 269.885 99.2142C269.998 100.235 270.112 101.255 270.282 102.219C270.282 102.389 270.225 102.502 270.055 102.559H269.942Z"
          fill="#08080C"
        />
        <path
          d="M267.616 99.0441C267.503 99.0441 267.39 98.9307 267.333 98.8173C266.71 96.3796 266.257 93.8852 265.747 91.5042C265.237 88.8397 264.67 86.1186 263.991 83.4541C262.914 79.2023 261.045 73.1363 257.929 69.5081C255.379 66.6169 251.923 65.0295 247.335 64.8027C247.165 64.8027 247.051 64.6327 247.051 64.5193C247.051 64.3492 247.165 64.2358 247.335 64.2358C252.093 64.5193 255.719 66.1066 258.325 69.168C261.554 72.9096 263.424 79.0322 264.5 83.3407C265.18 86.0052 265.747 88.783 266.257 91.4475C266.71 93.8285 267.22 96.3229 267.843 98.7607C267.843 98.9307 267.843 99.0441 267.616 99.1008C267.616 99.1008 267.616 99.1008 267.56 99.1008L267.616 99.0441Z"
          fill="#08080C"
        />
        <path
          d="M252.603 66.9007C252.603 66.9007 252.547 66.9007 252.49 66.9007C249.714 65.8803 248.298 65.7102 245.408 66.0504C245.238 66.0504 245.125 65.937 245.068 65.8236C245.068 65.6535 245.182 65.5402 245.295 65.4835C248.241 65.1433 249.771 65.3134 252.603 66.3905C252.773 66.3905 252.83 66.6173 252.773 66.7307C252.773 66.844 252.603 66.9007 252.49 66.9007H252.603Z"
          fill="#08080C"
        />
        <path
          d="M270.505 134.534C270.335 134.534 270.278 134.364 270.278 134.194C272.318 123.592 270.109 113.218 267.276 101.71C266.03 96.5508 264.67 91.0518 263.65 85.4961C262.744 80.6773 261.611 75.5752 258.495 71.6635C258.381 71.5501 258.438 71.38 258.495 71.2666C258.608 71.1533 258.778 71.2099 258.891 71.2666C262.12 75.3484 263.253 80.4506 264.16 85.3827C265.18 90.8817 266.483 96.3807 267.786 101.54C270.618 113.105 272.828 123.592 270.788 134.25C270.788 134.364 270.618 134.477 270.505 134.477V134.534Z"
          fill="#08080C"
        />
        <path
          d="M268.862 134.079C268.692 134.079 268.636 133.909 268.636 133.739C269.656 128.523 269.656 122.741 268.636 116.108C268.636 115.938 268.692 115.824 268.862 115.768C269.032 115.768 269.146 115.824 269.202 115.994C270.222 122.684 270.222 128.523 269.202 133.795C269.202 133.909 269.032 134.022 268.919 134.022L268.862 134.079Z"
          fill="#08080C"
        />
        <path
          d="M268.239 117.412C268.126 117.412 267.956 117.299 267.956 117.185C266.823 110.212 265.689 103.863 264.443 97.8537C263.197 91.9578 261.95 86.5155 260.534 81.1865C259.174 75.9143 256.908 72.0026 253.906 69.4515C251.13 67.1272 247.617 67.3539 244.161 67.524C243.991 67.524 243.878 67.4106 243.878 67.2405C243.878 67.0705 243.991 66.9571 244.161 66.9571C247.73 66.787 251.356 66.5602 254.302 68.998C257.418 71.6057 259.684 75.6308 261.157 81.0164C262.574 86.2887 263.82 91.7877 265.066 97.6836C266.313 103.693 267.446 110.042 268.579 117.072C268.579 117.242 268.522 117.355 268.352 117.412H268.239Z"
          fill="#08080C"
        />
        <path
          d="M268.35 129.6C268.237 129.6 268.067 129.486 268.067 129.373C267.274 123.534 266.424 118.318 265.631 113.443C264.838 108.794 264.101 104.599 263.308 100.63C263.308 100.46 263.365 100.347 263.535 100.29C263.705 100.29 263.818 100.347 263.875 100.517C264.668 104.485 265.404 108.681 266.197 113.329C266.991 118.205 267.84 123.42 268.633 129.259C268.633 129.429 268.52 129.543 268.407 129.6H268.35Z"
          fill="#08080C"
        />
        <path
          d="M263.593 100.916C263.48 100.916 263.366 100.802 263.31 100.689C263.027 99.2151 262.687 97.6845 262.403 96.2105C262.403 96.0404 262.46 95.9271 262.63 95.8704C262.8 95.8704 262.913 95.9271 262.97 96.0971C263.31 97.5711 263.593 99.1017 263.876 100.576C263.876 100.746 263.82 100.859 263.65 100.916H263.593Z"
          fill="#08080C"
        />
        <path
          d="M262.176 94.1129C262.063 94.1129 261.949 93.9996 261.893 93.8862C261.043 90.0879 260.136 86.3463 259.23 82.8881C259.23 82.718 259.23 82.6047 259.457 82.548C259.627 82.548 259.74 82.548 259.797 82.7747C260.703 86.2896 261.609 89.9745 262.459 93.8295C262.459 93.9996 262.403 94.1129 262.233 94.1696C262.233 94.1696 262.233 94.1696 262.176 94.1696V94.1129Z"
          fill="#08080C"
        />
        <path
          d="M259.514 83.0581C259.4 83.0581 259.287 83.0014 259.23 82.8313C259.06 82.2644 258.947 81.6975 258.777 81.1873C258.154 78.9196 257.361 76.9922 256.398 75.2914C254.981 72.8537 253.622 71.2097 252.149 70.3026C249.826 68.8853 246.993 69.0554 244.274 69.2255C243.594 69.2255 242.914 69.3389 242.234 69.3389C242.064 69.3389 241.951 69.2255 241.951 69.0554C241.951 68.8853 242.064 68.772 242.234 68.772C242.914 68.772 243.537 68.7153 244.274 68.6586C247.107 68.4885 249.996 68.2617 252.489 69.7924C254.075 70.7561 255.491 72.4569 256.964 74.9513C257.984 76.7087 258.777 78.6929 259.4 81.0172C259.57 81.5841 259.684 82.151 259.854 82.6613C259.854 82.8313 259.854 82.9447 259.627 83.0014C259.627 83.0014 259.627 83.0014 259.57 83.0014L259.514 83.0581Z"
          fill="#08080C"
        />
        <path
          d="M241.386 92.98C241.272 92.98 241.159 92.9233 241.103 92.81C240.253 90.9392 239.573 89.0117 238.95 86.8007C238.95 86.6306 238.95 86.5173 239.176 86.4606C239.346 86.4606 239.46 86.4606 239.516 86.6873C240.083 88.8416 240.763 90.8258 241.612 92.6399C241.669 92.81 241.612 92.9233 241.499 93.0367C241.499 93.0367 241.442 93.0367 241.386 93.0367V92.98Z"
          fill="#08080C"
        />
        <path
          d="M249.431 106.472C249.318 106.472 249.205 106.472 249.205 106.302C248.128 104.261 246.825 102.277 245.579 100.406C245.465 100.236 245.579 100.066 245.635 99.9529C245.749 99.8962 245.919 99.9529 246.032 100.01C247.278 101.994 248.581 103.978 249.658 106.019C249.714 106.132 249.658 106.302 249.544 106.416H249.431V106.472Z"
          fill="#08080C"
        />
        <path
          d="M252.434 135.555C252.32 135.555 252.207 135.441 252.15 135.328C251.64 133.06 251.131 130.339 251.017 127.618C250.904 125.18 251.244 122.799 251.584 120.475L251.754 119.454C252.15 116.847 252.094 114.636 251.64 112.595C251.187 110.611 250.394 108.513 249.204 106.245C249.148 106.132 249.204 105.962 249.318 105.849C249.431 105.792 249.601 105.849 249.714 105.962C250.961 108.286 251.754 110.384 252.207 112.425C252.66 114.522 252.717 116.79 252.32 119.454L252.15 120.475C251.81 122.856 251.47 125.124 251.584 127.505C251.697 130.169 252.207 132.834 252.717 135.101C252.717 135.271 252.66 135.385 252.49 135.441C252.49 135.441 252.49 135.441 252.434 135.441V135.555Z"
          fill="#08080C"
        />
        <path
          d="M250.394 104.771C250.28 104.771 250.224 104.771 250.167 104.601C249.204 102.844 248.071 101.143 246.994 99.4988C246.428 98.5917 245.805 97.6847 245.238 96.7776C244.162 95.0769 242.972 93.036 242.066 90.7684C241.272 88.8976 240.649 86.7433 240.083 84.1355C239.516 81.471 239.12 78.8066 238.836 76.1421V75.6319C238.61 73.6477 238.383 71.21 239.856 69.6226C239.969 69.5093 240.139 69.5093 240.253 69.6226C240.366 69.736 240.366 69.9061 240.253 70.0195C238.95 71.3801 239.176 73.7044 239.346 75.5752V76.0854C239.63 78.7499 240.026 81.4144 240.593 84.0221C241.159 86.6299 241.782 88.7275 242.519 90.5983C243.425 92.8092 244.615 94.8501 245.635 96.4942C246.201 97.4012 246.768 98.3083 247.391 99.2153C248.467 100.859 249.544 102.56 250.564 104.318C250.62 104.431 250.564 104.601 250.45 104.714H250.337L250.394 104.771Z"
          fill="#08080C"
        />
        <path
          d="M253.85 118.66C253.68 118.66 253.567 118.49 253.624 118.377C253.794 116.846 253.794 115.599 253.624 114.409C253.397 112.424 252.887 110.44 251.981 108.343C251.471 107.152 250.904 105.962 250.168 104.658C250.111 104.544 250.168 104.374 250.281 104.261C250.394 104.204 250.564 104.261 250.678 104.374C251.414 105.678 252.037 106.925 252.547 108.116C253.454 110.27 254.02 112.311 254.247 114.352C254.36 115.599 254.36 116.903 254.247 118.434C254.247 118.604 254.133 118.66 253.964 118.66H253.85Z"
          fill="#08080C"
        />
        <path
          d="M253.679 133.174C253.566 133.174 253.452 133.06 253.396 132.947C253.056 131.19 252.829 129.716 252.772 128.355C252.603 125.747 252.886 123.253 253.226 121.155C253.396 120.248 253.509 119.284 253.622 118.321C253.622 118.151 253.792 118.037 253.906 118.094C254.076 118.094 254.189 118.264 254.132 118.377C254.019 119.341 253.906 120.305 253.736 121.212C253.396 123.31 253.112 125.747 253.282 128.298C253.396 129.659 253.566 131.133 253.906 132.834C253.906 133.004 253.849 133.117 253.679 133.174Z"
          fill="#08080C"
        />
        <path
          d="M258.834 132.324C258.664 132.324 258.551 132.21 258.551 132.04C258.324 129.772 257.984 127.561 257.361 124.84C256.681 121.779 255.832 118.661 255.038 115.6C253.395 109.534 251.696 103.298 251.356 96.9484C251.356 96.7783 251.469 96.6649 251.639 96.6649C251.809 96.6649 251.922 96.7783 251.922 96.9484C252.262 103.241 253.962 109.477 255.605 115.486C256.398 118.491 257.248 121.609 257.928 124.727C258.494 127.448 258.891 129.659 259.117 131.983C259.117 132.153 259.004 132.267 258.891 132.267L258.834 132.324Z"
          fill="#08080C"
        />
        <path
          d="M251.582 96.0971C251.412 96.0971 251.299 95.9838 251.299 95.8137C251.299 95.4168 251.299 95.02 251.299 94.6232C251.299 94.4531 251.412 94.3397 251.582 94.3397C251.752 94.3397 251.865 94.4531 251.865 94.6232C251.865 95.02 251.865 95.4168 251.865 95.8137C251.865 95.9838 251.752 96.0971 251.582 96.0971Z"
          fill="#08080C"
        />
        <path
          d="M260.647 133.853C260.477 133.853 260.363 133.74 260.363 133.57C259.91 127.334 258.267 121.268 256.681 115.372C255.605 111.46 254.528 107.379 253.792 103.297C253.225 100.236 252.999 97.401 252.942 94.6232C252.942 94.4531 253.055 94.3397 253.225 94.3397C253.395 94.3397 253.508 94.4531 253.508 94.6232C253.508 97.3443 253.792 100.179 254.358 103.24C255.095 107.322 256.171 111.347 257.248 115.259C258.834 121.155 260.477 127.334 260.93 133.57C260.93 133.74 260.817 133.853 260.647 133.853Z"
          fill="#08080C"
        />
        <path
          d="M251.301 102.956C251.188 102.956 251.131 102.956 251.075 102.786C250.111 101.085 249.035 99.4408 248.015 97.8534C247.392 96.9464 246.826 96.0393 246.259 95.0756C246.202 94.9622 246.259 94.7921 246.372 94.6787C246.486 94.622 246.656 94.6787 246.769 94.7921C247.335 95.6992 247.959 96.6629 248.525 97.57C249.545 99.1573 250.678 100.858 251.641 102.559C251.698 102.672 251.641 102.842 251.528 102.956H251.415H251.301Z"
          fill="#08080C"
        />
        <path
          d="M238.722 69.1112C238.665 69.1112 238.609 69.1112 238.552 69.1112C238.439 68.9978 238.382 68.8277 238.495 68.7143C238.495 68.7143 239.175 67.8073 240.818 67.6372C240.988 67.6372 241.101 67.7506 241.101 67.9207C241.101 68.0907 240.988 68.2041 240.818 68.2041C239.458 68.3175 238.949 68.9978 238.949 69.0545C238.949 69.1112 238.835 69.1679 238.722 69.1679V69.1112Z"
          fill="#08080C"
        />
        <path
          d="M240.648 66.56C240.534 66.56 240.478 66.56 240.421 66.3899C240.364 66.2766 240.421 66.1065 240.534 65.9931C240.534 65.9931 241.498 65.4262 242.291 65.3695C242.461 65.3695 242.574 65.4829 242.574 65.653C242.574 65.823 242.461 65.9364 242.291 65.9364C241.611 65.9364 240.761 66.4466 240.761 66.4466H240.648V66.56Z"
          fill="#08080C"
        />
        <path
          d="M244.388 64.8582C244.388 64.8582 244.331 64.8582 244.275 64.8582C243.708 64.6314 243.085 64.5747 243.085 64.5747C242.915 64.5747 242.802 64.4046 242.802 64.2913C242.802 64.1779 242.972 64.0078 243.085 64.0078C243.085 64.0078 243.765 64.0645 244.445 64.348C244.615 64.4046 244.671 64.5747 244.615 64.6881C244.615 64.8015 244.445 64.8582 244.331 64.8582H244.388Z"
          fill="#08080C"
        />
        <path
          d="M273.281 118.432C273.054 118.205 271.015 118.262 270.845 118.545C270.618 118.772 270.675 119.622 270.675 119.906C270.675 120.189 270.731 120.983 270.958 121.21C271.185 121.436 273.224 121.38 273.394 121.096C273.621 120.869 273.564 120.019 273.564 119.736C273.564 119.452 273.507 118.658 273.281 118.432Z"
          fill="#B8D9EA"
        />
        <path
          d="M270.788 118.886C270.731 118.886 270.675 118.886 270.618 118.829C270.505 118.716 270.505 118.546 270.618 118.433C270.788 118.262 271.241 118.149 272.035 118.092C272.828 118.092 273.281 118.092 273.508 118.319C273.621 118.433 273.621 118.603 273.508 118.716C273.394 118.829 273.224 118.829 273.111 118.716C272.828 118.603 271.298 118.659 271.015 118.829C271.015 118.886 270.901 118.943 270.788 118.943V118.886Z"
          fill="#08080C"
        />
        <path
          d="M273.565 120.758C273.395 120.758 273.282 120.588 273.282 120.474C273.282 120.248 273.282 119.964 273.282 119.851C273.282 119.737 273.282 119.567 273.282 119.341C273.282 119.17 273.395 119.057 273.508 119C273.678 119 273.792 119.114 273.848 119.227C273.848 119.454 273.848 119.624 273.848 119.737C273.848 119.907 273.848 120.134 273.848 120.418C273.848 120.588 273.735 120.701 273.565 120.701V120.758Z"
          fill="#08080C"
        />
        <path
          d="M271.809 121.665C271.696 121.665 271.639 121.665 271.583 121.665C271.413 121.665 271.299 121.552 271.299 121.381C271.299 121.211 271.413 121.098 271.583 121.098C272.149 121.098 272.829 121.098 273.112 120.985C273.282 120.985 273.395 120.985 273.452 121.155C273.452 121.325 273.452 121.438 273.282 121.495C272.942 121.608 272.319 121.665 271.809 121.665Z"
          fill="#08080C"
        />
        <path
          d="M261.273 133.511C261.046 133.285 259.007 133.285 258.837 133.511C258.61 133.738 258.61 134.589 258.61 134.872C258.61 135.155 258.61 135.949 258.894 136.233C259.12 136.459 261.16 136.459 261.33 136.233C261.556 136.006 261.556 135.155 261.556 134.872C261.556 134.589 261.556 133.795 261.273 133.511Z"
          fill="white"
        />
        <path
          d="M260.425 136.629C260.255 136.629 260.142 136.516 260.142 136.346C260.142 136.175 260.255 136.062 260.425 136.062C260.878 136.062 261.105 135.949 261.161 135.949C261.161 135.949 261.275 135.609 261.275 134.815C261.275 134.021 261.161 133.738 261.105 133.681C260.991 133.568 260.991 133.398 261.105 133.284C261.218 133.171 261.388 133.171 261.501 133.284C261.558 133.341 261.841 133.624 261.841 134.815C261.841 136.005 261.615 136.289 261.558 136.346C261.501 136.402 261.331 136.572 260.482 136.629H260.425Z"
          fill="#08080C"
        />
        <path
          d="M259.404 136.631C258.951 136.631 258.781 136.517 258.724 136.404C258.668 136.347 258.384 136.064 258.384 134.873C258.384 133.683 258.611 133.399 258.668 133.343C258.781 133.229 258.951 133.229 259.064 133.343C259.177 133.456 259.177 133.626 259.064 133.74C259.064 133.796 258.894 134.136 258.894 134.873C258.894 135.61 259.007 135.951 259.064 136.007C259.064 136.007 259.121 136.007 259.404 136.007C259.574 136.007 259.687 136.177 259.631 136.291C259.631 136.461 259.517 136.517 259.347 136.517L259.404 136.631Z"
          fill="#08080C"
        />
        <path
          d="M271.016 133.682C270.846 133.399 268.863 133.115 268.58 133.342C268.297 133.569 268.184 134.362 268.127 134.646C268.07 134.929 267.957 135.723 268.127 136.006C268.297 136.29 270.28 136.573 270.563 136.347C270.846 136.12 270.96 135.326 271.016 135.043C271.073 134.759 271.186 133.966 271.016 133.682Z"
          fill="white"
        />
        <path
          d="M270.167 136.743C270.167 136.743 270.11 136.743 270.053 136.743C269.883 136.743 269.77 136.63 269.77 136.459C269.77 136.289 269.883 136.176 270.053 136.176C270.337 136.176 270.45 136.176 270.45 136.176C270.45 136.176 270.62 135.836 270.733 135.099C270.846 134.362 270.733 134.022 270.733 133.908C270.62 133.795 270.733 133.625 270.79 133.512C270.903 133.398 271.073 133.512 271.186 133.568C271.243 133.682 271.47 133.965 271.243 135.156C271.073 136.346 270.79 136.573 270.676 136.63C270.62 136.686 270.45 136.743 270.053 136.743H270.167Z"
          fill="#08080C"
        />
        <path
          d="M268.58 136.516C268.58 136.516 268.58 136.516 268.524 136.516C268.184 136.403 268.014 136.346 267.957 136.176C267.9 136.063 267.674 135.779 267.9 134.589C267.9 134.249 268.014 133.965 268.07 133.739C268.07 133.568 268.297 133.512 268.41 133.569C268.58 133.569 268.637 133.795 268.58 133.909C268.524 134.135 268.467 134.362 268.41 134.702C268.297 135.439 268.354 135.779 268.41 135.836C268.41 135.836 268.467 135.836 268.694 135.95C268.864 135.95 268.92 136.176 268.864 136.29C268.864 136.403 268.694 136.516 268.58 136.516Z"
          fill="#08080C"
        />
        <path
          d="M254.699 135.951C254.416 135.725 252.433 136.065 252.263 136.348C252.093 136.632 252.206 137.425 252.263 137.709C252.32 137.992 252.433 138.786 252.716 139.013C253 139.239 254.982 138.899 255.152 138.616C255.322 138.332 255.209 137.539 255.152 137.255C255.096 136.972 254.982 136.178 254.699 135.951Z"
          fill="white"
        />
        <path
          d="M253.114 139.409C252.831 139.409 252.661 139.409 252.548 139.296C252.435 139.239 252.208 139.013 251.981 137.822C251.811 136.632 251.981 136.348 252.038 136.235C252.151 136.121 252.321 136.065 252.435 136.178C252.548 136.291 252.604 136.461 252.491 136.575C252.491 136.632 252.378 136.972 252.491 137.765C252.604 138.502 252.774 138.842 252.831 138.899C253.058 139.013 254.644 138.729 254.871 138.559C254.984 138.446 255.097 138.389 255.267 138.502C255.381 138.616 255.437 138.786 255.324 138.899C255.211 139.126 254.701 139.296 253.964 139.409C253.624 139.466 253.341 139.523 253.058 139.523L253.114 139.409Z"
          fill="#08080C"
        />
        <path
          d="M255.155 137.538C255.041 137.538 254.872 137.424 254.872 137.311C254.758 136.574 254.588 136.234 254.532 136.177C254.532 136.177 254.192 136.177 253.625 136.234C253.455 136.234 253.342 136.177 253.285 136.007C253.229 135.837 253.342 135.724 253.512 135.667C254.588 135.497 254.815 135.667 254.872 135.667C254.985 135.724 255.211 135.95 255.438 137.141C255.438 137.311 255.381 137.424 255.211 137.481L255.155 137.538Z"
          fill="#08080C"
        />
        <path
          d="M250.562 129.6C250.449 129.6 250.336 129.486 250.279 129.373C249.996 127.842 249.882 126.142 249.939 124.214C249.996 122.513 250.166 120.813 250.336 119.169C250.506 117.468 250.675 115.711 250.732 113.953C250.732 113.783 250.902 113.67 251.015 113.67C251.185 113.67 251.299 113.783 251.299 113.953C251.299 115.711 251.072 117.468 250.902 119.169C250.732 120.813 250.562 122.513 250.505 124.158C250.449 125.972 250.506 127.672 250.845 129.146C250.845 129.316 250.789 129.43 250.619 129.486L250.562 129.6Z"
          fill="#08080C"
        />
        <path
          d="M296.565 140.144C296.225 142.412 294.186 143.716 294.186 143.716C294.186 143.716 294.299 157.548 294.186 158.739C294.073 159.929 292.486 161.177 292.486 161.177C292.486 161.177 292.373 165.599 292.373 166.336H280.476L279.853 162.14C277.87 161.177 277.416 158.512 277.416 158.512C277.416 158.512 277.53 157.378 279.343 156.471C279.343 156.471 278.663 148.648 278.946 145.927C278.946 145.927 274.527 142.639 274.527 141.902C274.527 141.165 276.51 137.537 276.51 137.537C276.51 137.537 274.244 124.157 274.527 121.38L273.281 119.452L273.507 111.459C273.507 111.459 278.153 103.805 282.005 101.198C284.328 104.089 288.464 111.799 291.013 121.096C293.903 131.471 294.922 131.301 294.752 133.001C294.582 134.645 292.939 136.006 292.939 136.006C292.939 136.006 296.905 137.933 296.565 140.201V140.144Z"
          fill="#4780E6"
        />
        <path
          d="M275.206 109.134C275.206 109.134 275.093 109.134 275.036 109.134C274.923 109.02 274.866 108.85 274.98 108.737C276.396 106.639 278.549 103.805 280.588 101.934C280.702 101.821 280.872 101.821 280.985 101.934C281.098 102.047 281.098 102.217 280.985 102.331C278.945 104.145 276.849 106.98 275.433 109.02C275.433 109.077 275.263 109.134 275.206 109.134Z"
          fill="#08080C"
        />
        <path
          d="M292.373 166.335C292.373 166.335 282.176 165.598 280.476 166.335C278.777 167.072 292.373 167.809 292.373 166.335Z"
          fill="#08080C"
        />
        <path
          d="M276.566 137.82C276.453 137.82 276.283 137.707 276.283 137.593C276.17 137.027 274.073 124.441 274.243 121.437L273.054 119.566C273.054 119.566 273.054 119.452 273.054 119.396L273.28 111.402C273.28 111.232 273.394 111.119 273.564 111.119C273.734 111.119 273.847 111.232 273.847 111.402L273.62 119.282L274.867 121.153C274.867 121.153 274.867 121.266 274.867 121.323C274.64 124.044 276.849 137.253 276.849 137.423C276.849 137.593 276.793 137.707 276.623 137.764L276.566 137.82Z"
          fill="#08080C"
        />
        <path
          d="M279.399 156.756C279.229 156.756 279.116 156.642 279.116 156.472C279.116 156.132 278.436 148.876 278.719 146.041C277.303 144.964 274.3 142.64 274.3 141.903C274.3 141.166 274.414 141.109 275.377 139.181C275.433 139.068 275.603 139.011 275.773 139.068C275.887 139.125 275.943 139.295 275.887 139.465C274.923 141.392 274.867 141.846 274.867 141.959C274.923 142.413 277.19 144.284 279.172 145.758C279.229 145.814 279.286 145.928 279.286 146.041C279.003 148.705 279.682 156.415 279.682 156.529C279.682 156.699 279.569 156.812 279.399 156.812V156.756Z"
          fill="#08080C"
        />
        <path
          d="M278.777 161.575C278.72 161.575 278.607 161.575 278.55 161.461C277.474 160.214 277.19 158.627 277.19 158.57C277.19 158.4 277.247 158.287 277.417 158.23C277.587 158.23 277.7 158.287 277.757 158.457C277.757 158.457 278.04 159.987 279.003 161.065C279.117 161.178 279.117 161.348 279.003 161.461C279.003 161.461 278.89 161.518 278.833 161.518L278.777 161.575Z"
          fill="#08080C"
        />
        <path
          d="M280.476 166.676C280.363 166.676 280.193 166.563 280.193 166.449L279.4 160.78C279.4 160.61 279.513 160.497 279.627 160.44C279.796 160.44 279.91 160.553 279.966 160.667L280.76 166.336C280.76 166.506 280.646 166.619 280.533 166.676H280.476Z"
          fill="#08080C"
        />
        <path
          d="M291.693 160.325C289.37 159.872 286.821 160.325 286.821 160.325C286.651 160.325 286.538 160.269 286.481 160.099C286.424 159.929 286.538 159.815 286.708 159.759C286.821 159.759 289.37 159.305 291.806 159.759C291.976 159.759 292.033 159.929 292.033 160.099C292.033 160.212 291.863 160.325 291.75 160.325H291.693Z"
          fill="#08080C"
        />
        <path
          d="M280.646 159.533C280.533 159.533 280.419 159.476 280.363 159.363C280.306 159.193 280.363 159.079 280.533 158.966C280.533 158.966 280.872 158.796 281.496 158.569C281.666 158.569 281.779 158.569 281.836 158.739C281.836 158.909 281.836 159.023 281.666 159.079C281.099 159.306 280.759 159.476 280.759 159.476C280.759 159.476 280.702 159.476 280.646 159.476V159.533Z"
          fill="#08080C"
        />
        <path
          d="M277.754 141.957C277.641 141.957 277.528 141.901 277.471 141.787C277.471 141.617 277.471 141.504 277.641 141.447C277.641 141.447 279.001 140.994 280.927 140.484C281.097 140.484 281.21 140.484 281.267 140.71C281.267 140.88 281.267 140.994 281.04 141.05C279.114 141.561 277.811 142.014 277.811 142.014C277.811 142.014 277.754 142.014 277.698 142.014L277.754 141.957Z"
          fill="#08080C"
        />
        <path
          d="M278.266 137.877C278.209 137.877 278.096 137.877 278.039 137.764C277.926 137.65 277.926 137.48 278.039 137.367C278.039 137.367 279.399 136.006 280.985 133.682C281.098 133.568 281.268 133.512 281.382 133.625C281.495 133.739 281.552 133.909 281.438 134.022C279.852 136.403 278.492 137.764 278.436 137.764C278.436 137.764 278.322 137.82 278.266 137.82V137.877Z"
          fill="#08080C"
        />
        <path
          d="M277.076 122.683C276.906 122.683 276.792 122.513 276.792 122.4C276.792 122.003 277.472 112.422 279.455 108.907C279.512 108.794 279.682 108.737 279.852 108.794C279.965 108.851 280.022 109.021 279.965 109.191C277.982 112.592 277.359 122.343 277.359 122.457C277.359 122.627 277.246 122.74 277.076 122.74V122.683Z"
          fill="#08080C"
        />
        <path
          d="M294.978 183.343C294.978 183.343 295.828 185.384 295.828 185.894C295.828 186.404 294.468 186.631 294.468 186.631C294.468 186.631 295.431 188.388 294.694 188.615C293.165 189.068 292.032 187.878 292.032 187.878C292.032 187.878 291.635 188.672 289.482 187.595C287.046 186.347 281.211 176.767 281.211 176.767C280.871 181.755 277.302 182.606 277.415 181.132C277.529 179.601 278.265 176.426 278.548 174.102C278.832 171.778 281.494 169.453 281.494 169.453L281.324 167.413V166.392C281.268 166.392 289.369 166.902 289.369 166.902L289.029 168.15L288.803 169.057C288.803 169.057 295.884 181.642 295.998 182.266C296.111 182.889 294.921 183.286 294.921 183.286L294.978 183.343Z"
          fill="#C48C73"
        />
        <path
          d="M291.351 186.688C291.295 186.688 291.181 186.688 291.125 186.575C289.595 184.931 285.742 178.014 285.573 177.731C285.516 177.618 285.573 177.448 285.686 177.334C285.799 177.277 285.969 177.334 286.082 177.448C286.082 177.504 290.048 184.647 291.521 186.178C291.634 186.291 291.634 186.461 291.521 186.575C291.521 186.575 291.408 186.631 291.351 186.631V186.688Z"
          fill="#08080C"
        />
        <path
          d="M293.732 185.951C293.675 185.951 293.562 185.951 293.505 185.838C291.976 184.194 288.18 176.541 288.01 176.201C287.953 176.087 288.01 175.917 288.123 175.804C288.237 175.747 288.407 175.804 288.52 175.917C288.52 175.974 292.486 183.854 293.959 185.441C294.072 185.555 294.072 185.725 293.959 185.838C293.959 185.838 293.845 185.895 293.789 185.895L293.732 185.951Z"
          fill="#08080C"
        />
        <path
          d="M294.128 182.605C294.071 182.605 293.958 182.605 293.902 182.492C292.825 181.188 290.162 175.235 290.049 175.008C289.992 174.838 290.049 174.725 290.219 174.611C290.389 174.555 290.502 174.611 290.616 174.782C290.616 174.838 293.335 180.904 294.411 182.151C294.525 182.265 294.468 182.435 294.411 182.548C294.411 182.548 294.298 182.605 294.241 182.605H294.128Z"
          fill="#08080C"
        />
        <path
          d="M289.425 166.902L289.085 168.15L281.324 167.413L281.267 166.392L289.425 166.902Z"
          fill="#08080C"
        />
        <path
          d="M277.416 181.415C277.246 181.415 277.132 181.245 277.132 181.131C277.132 180.281 277.416 178.921 277.699 177.503C277.925 176.313 278.152 175.122 278.265 174.102C278.549 171.721 281.155 169.396 281.268 169.283C281.381 169.17 281.551 169.17 281.665 169.283C281.778 169.396 281.778 169.566 281.665 169.68C281.665 169.68 279.115 172.004 278.832 174.158C278.719 175.179 278.492 176.426 278.265 177.617C277.982 179.034 277.756 180.394 277.699 181.188C277.699 181.358 277.586 181.472 277.416 181.472V181.415Z"
          fill="#08080C"
        />
        <path
          d="M279.852 181.075C279.795 181.075 279.738 181.075 279.682 181.019C279.568 180.905 279.568 180.735 279.682 180.622C280.192 179.998 280.871 178.808 281.041 176.767C281.041 176.597 281.211 176.484 281.325 176.484C281.495 176.484 281.608 176.654 281.608 176.767C281.438 178.978 280.701 180.225 280.135 180.962C280.135 181.019 280.022 181.075 279.908 181.075H279.852Z"
          fill="#08080C"
        />
        <path
          d="M281.778 177.843C281.664 177.843 281.608 177.843 281.551 177.73C281.211 177.219 281.041 176.936 281.041 176.936C280.985 176.823 281.041 176.652 281.155 176.539C281.268 176.482 281.438 176.539 281.551 176.652C281.551 176.652 281.721 176.936 282.061 177.446C282.118 177.56 282.061 177.73 281.948 177.843C281.948 177.843 281.834 177.843 281.778 177.843Z"
          fill="#08080C"
        />
        <path
          d="M291.353 188.444C290.899 188.444 290.276 188.274 289.426 187.877C287.387 186.8 283.251 180.451 281.552 177.786C281.495 177.673 281.552 177.503 281.665 177.389C281.778 177.332 281.948 177.389 282.061 177.503C283.364 179.543 287.727 186.403 289.71 187.423C291.183 188.16 291.749 187.934 291.862 187.82C291.919 187.707 292.089 187.65 292.259 187.707C292.372 187.764 292.429 187.934 292.372 188.104C292.372 188.16 292.089 188.557 291.409 188.557L291.353 188.444Z"
          fill="#08080C"
        />
        <path
          d="M241.951 132.038C241.951 132.038 240.591 125.576 239.402 124.952C238.212 124.328 234.586 124.555 234.586 124.555C234.586 124.555 231.583 121.721 230.11 121.494C228.637 121.267 227.051 121.891 228.637 126.199C228.637 126.199 228.184 128.75 228.298 129.091C228.411 129.431 229.544 131.415 229.544 131.415C229.544 131.415 229.317 134.079 229.374 134.363C229.431 134.646 229.997 135.27 229.997 135.27C229.997 135.27 230.224 137.141 230.394 137.367C230.564 137.594 236.682 139.692 238.042 139.465C239.402 139.238 243.367 137.991 242.008 132.038H241.951Z"
          fill="#C48C73"
        />
        <path
          d="M238.949 129.26C236.739 130.45 233.227 130.45 233.227 129.26C233.227 128.069 237.362 126.482 237.362 126.482C239.062 125.972 240.988 128.296 239.005 129.26H238.949Z"
          fill="#C48C73"
        />
        <path
          d="M235.21 130.394C234.473 130.394 233.85 130.281 233.454 130.054C233.057 129.828 232.944 129.487 232.944 129.261C232.944 127.9 236.513 126.483 237.25 126.199C237.419 126.199 237.533 126.199 237.589 126.369C237.646 126.539 237.589 126.653 237.419 126.71C235.777 127.333 233.51 128.524 233.51 129.204C233.51 129.884 233.51 129.431 233.737 129.544C234.587 130.054 237.08 129.884 238.836 128.977C238.949 128.921 239.119 128.977 239.232 129.091C239.289 129.204 239.232 129.374 239.119 129.487C237.986 130.111 236.456 130.394 235.267 130.394H235.21Z"
          fill="#08080C"
        />
        <path
          d="M231.356 128.921C230.45 128.921 229.147 127.673 228.41 126.37C228.354 126.256 228.41 126.086 228.524 125.973C228.637 125.916 228.807 125.973 228.92 126.086C229.77 127.503 231.016 128.467 231.47 128.354C231.64 128.354 231.696 127.957 231.696 127.787C231.696 125.519 230.45 123.478 230.45 123.478C230.393 123.365 230.45 123.195 230.563 123.081C230.677 123.025 230.846 123.081 230.96 123.195C230.96 123.308 232.319 125.406 232.319 127.843C232.319 128.637 231.98 128.864 231.696 128.977C231.64 128.977 231.526 128.977 231.47 128.977L231.356 128.921Z"
          fill="#08080C"
        />
        <path
          d="M243.481 130.338L242.857 129.714L232.49 129.318L232.773 130.054L243.481 130.338Z"
          fill="#08080C"
        />
        <path
          d="M232.546 129.147L242.517 129.601C242.914 129.601 243.14 130.054 242.97 130.395L234.246 145.418L226.257 146.155L225.748 145.588L231.526 129.828C231.696 129.374 232.093 129.091 232.603 129.147H232.546Z"
          fill="#08080C"
        />
        <path
          d="M233.056 129.657L243.027 130.11C243.423 130.11 243.65 130.564 243.48 130.904L234.755 145.927L226.257 146.097L232.036 130.337C232.206 129.884 232.602 129.6 233.112 129.657H233.056Z"
          fill="white"
        />
        <path
          d="M234.473 132.096C234.303 132.493 233.793 132.776 233.397 132.776C233 132.776 232.773 132.436 232.943 132.039C233.113 131.643 233.623 131.246 234.076 131.302C234.53 131.359 234.7 131.699 234.53 132.153L234.473 132.096Z"
          fill="#08080C"
        />
        <path
          d="M233.736 133.627C233.566 133.967 233.17 134.194 232.773 134.194C232.377 134.194 232.207 133.91 232.32 133.57C232.433 133.23 232.887 132.947 233.283 132.947C233.68 132.947 233.85 133.287 233.68 133.627H233.736Z"
          fill="#08080C"
        />
        <path
          d="M235.549 133.004C235.322 133.344 234.869 133.627 234.472 133.627C234.076 133.627 233.906 133.287 234.076 132.947C234.246 132.607 234.756 132.267 235.152 132.267C235.549 132.267 235.719 132.607 235.492 133.004H235.549Z"
          fill="#08080C"
        />
        <path
          d="M189.149 62.5911C188.469 62.3076 187.733 62.0808 186.996 61.9108C186.996 61.8541 186.996 61.7974 186.883 61.7407C184.957 59.303 181.274 58.4526 181.161 58.4526C180.878 58.3959 180.538 58.566 180.481 58.9062C180.424 59.1896 180.594 59.5298 180.934 59.5864C180.934 59.5864 183.314 60.1534 185.127 61.5706C177.875 60.2667 168.924 61.4006 167.054 61.684C170 58.3392 174.476 57.3188 178.498 57.4322C184.107 57.6023 187.223 60.4368 189.206 62.7044L189.149 62.5911Z"
          fill="#08080C"
        />
        <path
          d="M191.756 69.8476C191.756 69.8476 192.889 69.3941 192.889 68.5437C192.889 67.6933 192.323 66.9564 192.323 66.9564C192.323 66.9564 192.776 65.6525 192.323 64.9155C191.869 64.1785 185.128 62.0809 176.913 62.0809C168.698 62.0809 164.563 62.9313 164.336 63.6683C164.109 64.4053 163.883 65.7658 163.883 65.7658C163.883 65.7658 161.787 66.7296 161.787 67.5233C161.787 68.3169 162.693 69.1106 164.223 69.1106C165.752 69.1106 191.756 69.7909 191.756 69.7909V69.8476Z"
          fill="#FFDC69"
        />
        <path
          d="M190.85 66.5029C190.85 66.5029 190.793 66.5029 190.737 66.5029C187.451 65.1991 182.692 64.6888 182.635 64.6888C182.465 64.6888 182.352 64.5188 182.409 64.3487C182.409 64.1786 182.579 64.0652 182.749 64.1219C182.749 64.1219 187.678 64.6888 191.02 65.9927C191.19 66.0494 191.247 66.2195 191.19 66.3329C191.19 66.4463 191.02 66.5029 190.907 66.5029H190.85Z"
          fill="#08080C"
        />
        <path
          d="M191.868 74.9476C191.868 75.4011 189.828 79.4261 189.828 79.4261C189.828 79.4261 189.942 82.1473 189.262 83.6213C188.072 86.1157 182.633 93.3154 181.33 93.2587C181.104 93.2587 180.707 93.2021 180.311 93.0887C178.384 92.6918 175.099 91.6714 174.135 91.6714C173.172 91.6714 172.889 92.0115 172.209 92.3517C171.416 92.8052 170.736 93.2587 170.736 93.2587L158.159 84.4716C167.394 77.442 161.615 71.2626 163.994 67.7478C165.184 66.0471 170 65.5936 175.042 65.7069C180.254 65.8203 185.693 66.5573 187.789 67.0675C191.925 68.1446 191.755 68.8249 191.755 68.8249C191.755 68.8249 191.755 69.2218 191.755 69.7887C191.811 71.4327 191.925 74.5507 191.868 74.8909V74.9476Z"
          fill="#C48C73"
        />
        <path
          d="M184.389 74.4378C184.389 74.4378 184.389 72.2836 182.746 71.66C181.103 71.0364 178.101 72.2269 178.101 72.2269C178.101 72.2269 181.386 72.3969 182.236 72.7371C183.086 73.0772 184.389 74.4378 184.389 74.4378Z"
          fill="#08080C"
        />
        <path
          d="M187.676 75.5729C187.676 75.5729 190.169 74.8926 190.736 75.006C191.302 75.1193 191.925 75.6862 191.925 75.6862C191.925 75.6862 191.925 74.6091 190.679 74.0989C189.433 73.5887 187.676 75.5729 187.676 75.5729Z"
          fill="#08080C"
        />
        <path
          d="M184.389 83.6783C184.219 83.6783 184.106 83.5649 184.106 83.3949C184.106 83.2248 184.219 83.1114 184.389 83.1114C185.919 83.1114 186.882 82.6012 187.279 82.091C187.449 81.8642 187.449 81.7508 187.449 81.6941C187.222 80.9572 186.032 80.3336 185.636 80.1635C185.466 80.1068 185.409 79.9367 185.466 79.7666C185.522 79.5966 185.692 79.5399 185.862 79.5966C185.919 79.5966 187.675 80.3903 188.015 81.5241C188.072 81.8075 188.015 82.091 187.788 82.4311C187.562 82.7146 186.655 83.6783 184.446 83.6783H184.389Z"
          fill="#08080C"
        />
        <path
          d="M184.615 86.4576C184.275 86.9111 182.915 88.4985 181.782 88.1583C180.479 87.7615 179.516 84.3033 179.516 84.3033C179.856 84.5301 182.802 85.7206 184.558 86.4576H184.615Z"
          fill="white"
        />
        <path
          d="M182.179 88.4985C182.009 88.4985 181.896 88.4985 181.726 88.4419C180.31 87.9883 179.403 84.7569 179.29 84.4168C179.29 84.3034 179.29 84.19 179.403 84.1333C179.517 84.0766 179.63 84.0766 179.743 84.1333C179.913 84.2467 180.876 84.7002 184.729 86.2309C184.785 86.2309 184.899 86.3443 184.899 86.401C184.899 86.5144 184.899 86.5711 184.899 86.6277C184.559 87.0813 183.369 88.4985 182.179 88.4985ZM180.027 84.8703C180.423 85.9474 181.103 87.6482 181.896 87.8749C182.689 88.1584 183.709 87.0813 184.162 86.571C182.066 85.7207 180.763 85.1538 180.027 84.8703Z"
          fill="#08080C"
        />
        <path
          d="M186.032 87.307C186.032 87.307 185.975 87.307 185.918 87.307C185.918 87.307 185.352 87.0803 184.502 86.7401L179.403 84.5858C179.29 84.5858 179.233 84.4158 179.233 84.3024C179.233 84.189 179.233 84.2457 179.233 84.189C179.29 84.0189 179.46 83.9622 179.63 84.0189L184.729 86.1732C185.522 86.5133 186.145 86.7401 186.145 86.7401C186.315 86.7968 186.372 86.9669 186.315 87.0803C186.315 87.1936 186.145 87.2503 186.032 87.2503V87.307Z"
          fill="#08080C"
        />
        <path
          d="M182.235 90.3122C180.195 90.3122 179.459 89.1216 179.402 89.0649C179.345 88.9516 179.402 88.7815 179.515 88.6681C179.629 88.6114 179.799 88.6681 179.912 88.7815C179.912 88.7815 180.705 89.9153 182.801 89.7452C182.971 89.7452 183.085 89.8586 183.085 89.972C183.085 90.1421 182.971 90.2555 182.858 90.2555C182.631 90.2555 182.461 90.2555 182.291 90.2555L182.235 90.3122Z"
          fill="#08080C"
        />
        <path
          d="M188.922 79.9363C189.998 79.9363 189.998 78.2356 188.922 78.2356C187.846 78.2356 187.846 79.9363 188.922 79.9363Z"
          fill="#08080C"
        />
        <path
          d="M181.047 77.9521C182.123 77.9521 182.123 76.2513 181.047 76.2513C179.971 76.2513 179.971 77.9521 181.047 77.9521Z"
          fill="#08080C"
        />
        <path
          d="M176.289 69.3929C176.062 70.2432 173.003 71.0369 173.003 71.0369L172.833 77.0462H171.643C171.643 77.0462 171.643 72.5676 171.02 72.0007C170.454 71.4904 167.904 71.8306 167.904 73.8715C167.904 75.9123 168.244 85.0963 168.698 88.6678C167.564 87.3639 166.431 86.4002 165.355 85.7199C165.015 82.8286 164.279 77.8965 164.279 77.8965C164.279 77.8965 160.993 82.8286 159.633 83.1688C158.273 83.5656 154.478 83.9625 154.478 83.9625L157.763 81.5814C157.763 81.5814 156.744 80.8445 156.857 79.824C156.97 78.8036 163.372 68.7126 163.995 67.6922C164.618 66.6717 167.225 65.3678 176.289 65.708C176.289 65.708 176.516 68.4858 176.289 69.3362V69.3929Z"
          fill="#08080C"
        />
        <path
          d="M170.057 78.577C170.057 78.577 167.677 78.4636 166.998 75.4023C166.318 72.341 167.961 69.5064 170.963 72.0575C171.247 77.6133 170.057 78.577 170.057 78.577Z"
          fill="#C48C73"
        />
        <path
          d="M170.057 78.8606C170.057 78.8606 167.451 78.6905 166.714 75.4591C166.261 73.5316 166.714 71.7175 167.847 71.0939C168.357 70.7538 169.49 70.4703 171.19 71.8876C171.303 72.001 171.303 72.1711 171.19 72.2844C171.077 72.3978 170.907 72.3978 170.793 72.2844C169.717 71.3774 168.81 71.1506 168.131 71.5474C167.281 72.0577 166.884 73.645 167.281 75.3457C167.904 78.1803 170 78.2937 170.057 78.2937C170.227 78.2937 170.34 78.4071 170.34 78.5771C170.34 78.7472 170.227 78.8606 170.057 78.8606Z"
          fill="#08080C"
        />
        <path
          d="M170.001 76.309C169.887 76.309 169.774 76.1956 169.717 76.0822C169.434 74.7783 169.038 73.5311 168.811 73.3611H168.754C168.641 73.3611 168.471 73.7012 168.414 73.8713C168.414 74.0414 168.188 74.098 168.074 74.0414C167.905 74.0414 167.848 73.8146 167.905 73.7012C167.905 73.5878 168.131 73.0209 168.584 72.8508C168.698 72.8508 168.924 72.7942 169.151 72.9075C169.717 73.2477 170.171 75.3452 170.341 75.9688C170.341 76.1389 170.284 76.2523 170.114 76.309C170.114 76.309 170.114 76.309 170.057 76.309H170.001Z"
          fill="#08080C"
        />
        <path
          d="M180.254 93.1458C178.328 92.7489 175.042 91.7285 174.079 91.7285C173.116 91.7285 173.173 91.8985 172.72 92.1253C171.473 90.4246 169.774 87.9869 170.397 84.0752C170.397 84.0752 171.133 90.3112 180.198 93.1458H180.254Z"
          fill="#08080C"
        />
        <path
          d="M191.756 69.8464C187.677 66.7284 174.986 67.182 174.986 67.182V65.708C180.198 65.8214 185.637 66.5584 187.733 67.0686C191.869 68.1457 191.699 68.826 191.699 68.826C191.699 68.826 191.699 69.2228 191.699 69.7898L191.756 69.8464Z"
          fill="#08080C"
        />
        <path
          d="M163.655 137.988C161.899 142.864 160.765 153.181 160.765 153.181C160.765 153.181 162.748 156.469 162.975 157.206C163.202 157.943 160.879 158.68 158.556 159.191C155.667 159.814 125.414 156.583 124.281 156.356C123.148 156.129 119.975 153.748 119.975 152.274C119.975 150.8 126.32 145.585 126.32 145.585C126.32 145.585 129.04 114.291 129.719 110.663C130.399 107.035 147.565 89.2905 150.285 87.1363C153.004 84.982 161.219 84.075 161.219 84.075C161.219 84.075 163.032 84.3584 165.298 85.8324C166.374 86.5127 167.564 87.4764 168.64 88.7803C170 90.4243 171.303 92.5786 172.209 95.4698C176.458 109.189 165.354 133.169 163.598 138.045L163.655 137.988Z"
          fill="white"
        />
        <path
          d="M126.547 143.545C126.377 143.545 126.264 143.375 126.264 143.262C126.887 136.402 128.87 113.669 129.436 110.664C130.116 106.866 147.452 89.0651 150.115 86.9675C152.891 84.7565 160.822 83.9062 161.162 83.8495C161.332 83.8495 161.445 83.9629 161.445 84.0763C161.445 84.2463 161.332 84.3597 161.219 84.3597C161.162 84.3597 153.117 85.2101 150.455 87.3643C147.622 89.632 130.626 107.263 130.003 110.721C129.436 113.726 127.397 136.402 126.83 143.318C126.83 143.488 126.717 143.602 126.547 143.602V143.545Z"
          fill="#08080C"
        />
        <path
          d="M123.205 156.131C123.205 156.131 123.092 156.131 123.035 156.131C121.732 155.337 119.692 153.636 119.692 152.332C119.692 151.028 125.074 146.266 126.151 145.416C126.264 145.303 126.434 145.359 126.547 145.416C126.661 145.529 126.604 145.699 126.547 145.813C124.111 147.797 120.316 151.312 120.316 152.332C120.316 153.353 121.902 154.77 123.375 155.677C123.488 155.734 123.545 155.96 123.488 156.074C123.488 156.187 123.318 156.187 123.262 156.187L123.205 156.131Z"
          fill="#08080C"
        />
        <path
          d="M160.369 159.022C160.256 159.022 160.143 158.909 160.086 158.795C160.086 158.625 160.086 158.512 160.313 158.455C160.822 158.342 162.465 157.888 162.749 157.435C162.749 157.435 162.749 157.321 162.749 157.265C162.579 156.698 161.106 154.203 160.539 153.296C160.483 153.183 160.539 153.013 160.652 152.899C160.766 152.843 160.936 152.899 161.049 153.013C161.106 153.126 163.032 156.301 163.315 157.094C163.372 157.321 163.315 157.548 163.315 157.718C163.032 158.228 162.295 158.568 160.539 159.022C160.539 159.022 160.539 159.022 160.483 159.022H160.369Z"
          fill="#08080C"
        />
        <path
          d="M160.937 152.161C160.767 152.161 160.654 151.991 160.711 151.821C161.05 148.93 162.07 141.73 163.43 137.875C163.43 137.705 163.657 137.648 163.77 137.705C163.94 137.705 163.996 137.932 163.94 138.045C162.58 141.843 161.617 148.986 161.22 151.878C161.22 152.048 161.107 152.104 160.937 152.104V152.161Z"
          fill="#08080C"
        />
        <path
          d="M160.37 156.982C160.256 156.982 160.143 156.925 160.086 156.812C159.18 154.544 154.138 153.353 154.081 153.353C153.911 153.353 153.854 153.183 153.854 153.013C153.854 152.843 154.024 152.786 154.194 152.786C154.421 152.786 159.52 154.034 160.596 156.585C160.653 156.755 160.596 156.868 160.426 156.982C160.426 156.982 160.37 156.982 160.313 156.982H160.37Z"
          fill="#08080C"
        />
        <path
          d="M156.8 151.538C156.63 151.538 141.56 148.987 137.991 148.987C134.422 148.987 128.53 149.894 128.473 149.95C128.303 149.95 128.19 149.894 128.133 149.724C128.133 149.554 128.19 149.44 128.36 149.384C128.417 149.384 134.309 148.42 137.934 148.42C141.56 148.42 156.233 150.858 156.857 150.971C157.026 150.971 157.14 151.141 157.083 151.311C157.083 151.424 156.913 151.538 156.8 151.538Z"
          fill="#08080C"
        />
        <path
          d="M130.966 145.415C130.796 145.415 130.683 145.302 130.683 145.131C130.683 144.961 130.796 144.848 130.966 144.848C131.08 144.848 141.504 144.054 145.47 144.168C145.64 144.168 145.753 144.281 145.753 144.451C145.753 144.621 145.64 144.735 145.47 144.735C141.504 144.565 131.08 145.358 131.023 145.415H130.966Z"
          fill="#08080C"
        />
        <path
          d="M141.502 136.573C141.502 136.573 141.446 136.573 141.389 136.573C141.332 136.573 132.948 133.228 132.268 121.72C131.758 113.103 132.041 110.495 132.041 110.382C132.041 110.212 132.211 110.098 132.381 110.155C132.551 110.155 132.665 110.325 132.608 110.495C132.608 110.495 132.325 113.16 132.835 121.72C133.514 132.831 141.559 136.006 141.616 136.063C141.786 136.063 141.842 136.29 141.786 136.403C141.786 136.516 141.616 136.573 141.502 136.573Z"
          fill="#08080C"
        />
        <path
          d="M153.061 127.898C153.061 127.898 141.107 131.243 136.801 126.481C136.801 126.481 139.917 130.562 142.013 130.676C147.225 130.959 153.004 127.898 153.004 127.898H153.061Z"
          fill="#08080C"
        />
        <path
          d="M124.509 153.977C124.509 153.977 124.509 153.977 124.452 153.977C122.979 153.523 122.186 152.503 122.129 152.446C122.016 152.333 122.072 152.162 122.186 152.049C122.299 151.936 122.469 151.992 122.582 152.106C122.582 152.106 123.319 153.07 124.565 153.41C124.735 153.41 124.792 153.636 124.735 153.75C124.735 153.863 124.565 153.977 124.452 153.977H124.509Z"
          fill="#08080C"
        />
        <path
          d="M157.99 86.5712V86.3444L157.82 86.061L157.99 86.0043V86.2877L158.104 86.5145L157.99 86.3444V86.5712Z"
          fill="#08080C"
        />
        <path
          d="M170.68 96.7192C169.887 93.7145 165.468 84.8141 157.99 86.2313C150.512 87.7053 137.085 102.332 135.498 107.831C134.932 109.758 134.535 112.309 134.479 114.86C134.365 119.566 135.442 124.271 139.181 125.348C144.959 127.049 153.174 126.425 156.573 125.348C159.973 124.328 169.547 118.942 170.68 109.361C170.68 109.361 171.473 99.6671 170.68 96.6625V96.7192Z"
          fill="#C48C73"
        />
        <path
          d="M147.623 126.765C144.677 126.765 141.617 126.481 139.068 125.688C134.592 124.384 134.082 118.374 134.196 114.916C134.252 112.535 134.649 109.927 135.215 107.83C136.858 102.047 150.399 87.5345 157.877 86.0606C157.877 86.0606 157.933 86.0606 157.99 86.0606C161.219 85.437 164.392 86.6842 167.055 89.7455C169.377 92.3533 170.624 95.4713 170.964 96.7752C171.474 98.646 171.36 102.898 171.19 106.186C171.19 106.356 171.077 106.469 170.907 106.469C170.737 106.469 170.624 106.356 170.624 106.186C170.85 101.651 170.794 98.3625 170.397 96.9453C169.547 93.7139 165.072 85.3236 158.047 86.6842C150.908 88.1015 137.368 102.558 135.782 108.057C135.215 110.154 134.819 112.649 134.762 115.03C134.706 118.318 135.159 124.044 139.238 125.234C144.903 126.935 153.005 126.311 156.46 125.234C156.63 125.234 156.744 125.234 156.8 125.404C156.8 125.574 156.8 125.688 156.63 125.744C154.704 126.368 151.305 126.821 147.623 126.821V126.765Z"
          fill="#08080C"
        />
        <path
          d="M157.933 86.2875C157.536 86.4575 151.361 89.0653 144.053 97.2288C137.821 104.259 135.328 110.835 134.422 114.916C134.479 112.365 134.875 109.814 135.442 107.887C137.028 102.388 150.455 87.7614 157.933 86.2875Z"
          fill="#08080C"
        />
        <path
          d="M166.941 208.457L124.508 207.55L123.431 193.831C123.431 193.831 118.899 177.56 118.899 173.025C118.899 168.49 124.621 157.605 126.207 154.544C126.434 154.034 126.604 153.75 126.604 153.75C126.604 153.75 149.548 154.204 152.154 154.941C154.817 155.678 158.386 157.038 158.613 159.249C158.84 161.46 166.941 208.514 166.941 208.514V208.457Z"
          fill="#08080C"
        />
        <path
          d="M155.609 159.249H155.496C155.496 159.249 152.833 157.831 149.264 157.718C145.582 157.605 133.685 157.718 133.515 157.718C133.345 157.718 133.231 157.605 133.231 157.435C133.231 157.264 133.345 157.151 133.515 157.151C133.628 157.151 145.582 157.038 149.264 157.151C153.003 157.264 155.666 158.682 155.779 158.738C155.893 158.795 155.949 158.965 155.893 159.135C155.893 159.249 155.723 159.305 155.666 159.305L155.609 159.249Z"
          fill="white"
        />
        <path
          d="M139.124 176.428C139.01 176.428 138.897 176.371 138.84 176.258C138.84 176.088 138.84 175.974 139.01 175.918C144.506 174.103 150.341 171.836 150.568 171.099C150.738 169.965 150.568 164.523 150.568 164.466C150.568 164.296 150.681 164.182 150.851 164.182C151.021 164.182 151.134 164.296 151.134 164.466C151.134 164.693 151.304 170.022 151.134 171.212C150.907 172.403 142.693 175.294 139.18 176.428C139.18 176.428 139.124 176.428 139.067 176.428H139.124Z"
          fill="white"
        />
        <path
          d="M142.92 208.741C142.75 208.741 142.637 208.628 142.637 208.457C142.637 206.813 142.354 200.464 141.73 190.09C141.73 189.92 141.844 189.806 142.014 189.806C142.184 189.806 142.297 189.92 142.297 190.09C142.75 197.346 143.203 206.36 143.203 208.514C143.203 208.684 143.09 208.798 142.92 208.798V208.741Z"
          fill="white"
        />
        <path
          d="M149.209 190.316C149.209 190.316 149.152 190.316 149.095 190.316C142.694 187.538 137.935 188.559 137.878 188.559C137.708 188.559 137.595 188.502 137.538 188.332C137.538 188.162 137.595 188.049 137.765 187.992C137.991 187.992 142.75 186.915 149.322 189.749C149.492 189.806 149.549 189.976 149.492 190.146C149.492 190.26 149.322 190.316 149.209 190.316Z"
          fill="#08080C"
        />
        <path
          d="M158.612 159.193C158.612 159.193 156.629 158.002 153.683 156.698C150.794 155.451 126.66 154.487 126.207 154.431C126.433 153.921 126.603 153.637 126.603 153.637C126.603 153.637 149.548 154.091 152.154 154.828C154.816 155.565 158.386 156.925 158.612 159.136V159.193Z"
          fill="#08080C"
        />
        <path
          d="M155.044 110.666C155.044 110.666 161.502 134.362 170.397 147.855C170.397 147.855 192.662 154.204 214.133 151.653C235.605 149.102 232.772 149.726 233.339 148.989C233.905 148.252 233.395 148.138 234.925 147.061C236.455 145.984 236.171 143.943 236.171 143.943C236.171 143.943 240.194 139.748 240.42 139.408C240.647 139.068 240.081 138.047 240.081 138.047C240.081 138.047 241.837 136.914 241.893 135.95C241.95 134.986 240.76 135.326 238.098 135.95C235.435 136.573 228.75 139.748 228.75 139.748L227.674 138.728C227.674 138.728 230.223 135.383 230.393 134.022C230.563 132.662 229.6 131.358 228.807 131.471C228.013 131.585 227.843 133.115 226.994 134.249C226.144 135.383 222.575 137.821 222.405 138.274C222.235 138.728 220.082 143.773 220.082 143.773C220.082 143.773 212.434 144.34 205.012 142.016C197.591 139.691 182.238 135.496 180.538 135.326C180.538 135.326 171.303 108.285 169.321 105.053L154.931 110.722L155.044 110.666Z"
          fill="#C48C73"
        />
        <path
          d="M156.12 119.395C156.12 119.395 156.007 119.395 155.95 119.395C153.967 118.035 152.551 115.71 152.494 115.597C152.438 115.484 152.494 115.314 152.608 115.2C152.721 115.144 152.891 115.2 153.004 115.314C153.004 115.314 154.421 117.638 156.29 118.942C156.403 119.055 156.46 119.225 156.347 119.339C156.347 119.395 156.177 119.452 156.12 119.452V119.395Z"
          fill="#08080C"
        />
        <path
          d="M233.621 142.866C233.508 142.866 233.451 142.866 233.395 142.753C233.281 142.64 233.338 142.469 233.451 142.356C233.451 142.356 236.341 140.259 238.607 138.671C238.72 138.558 238.89 138.614 239.003 138.728C239.117 138.841 239.06 139.011 238.947 139.125C236.681 140.712 233.848 142.81 233.791 142.81C233.791 142.81 233.678 142.81 233.621 142.81V142.866Z"
          fill="#08080C"
        />
        <path
          d="M165.071 138.444C164.957 138.444 164.844 138.387 164.787 138.274C158.839 125.575 154.816 110.892 154.76 110.779C154.76 110.609 154.76 110.495 154.986 110.439C155.156 110.439 155.27 110.439 155.326 110.665C155.326 110.835 159.405 125.405 165.354 138.104C165.411 138.217 165.354 138.387 165.241 138.501C165.241 138.501 165.184 138.501 165.127 138.501L165.071 138.444Z"
          fill="#08080C"
        />
        <path
          d="M179.688 132.719C179.575 132.719 179.462 132.662 179.405 132.549C178.725 130.621 177.819 127.957 176.742 124.952C176.742 124.782 176.742 124.669 176.912 124.612C177.082 124.612 177.195 124.612 177.252 124.782C178.328 127.787 179.235 130.451 179.915 132.378C179.915 132.549 179.915 132.662 179.745 132.719C179.745 132.719 179.688 132.719 179.632 132.719H179.688Z"
          fill="#08080C"
        />
        <path
          d="M218.099 144.056C215.266 144.056 210.111 143.829 205.012 142.242C197.477 139.918 182.181 135.723 180.594 135.553C180.424 135.553 180.311 135.383 180.368 135.212C180.368 135.042 180.538 134.929 180.708 134.986C182.464 135.156 198.044 139.521 205.239 141.732C212.547 144 220.138 143.489 220.195 143.489C220.365 143.489 220.478 143.603 220.478 143.773C220.478 143.943 220.365 144.056 220.195 144.056C220.195 144.056 219.402 144.056 218.099 144.056Z"
          fill="#08080C"
        />
        <path
          d="M220.931 142.3C220.931 142.3 220.875 142.3 220.818 142.3C220.648 142.243 220.592 142.073 220.648 141.903C221.555 139.749 222.178 138.388 222.235 138.162C222.291 137.878 222.801 137.481 224.104 136.461C225.124 135.667 226.37 134.647 226.823 134.08C227.163 133.626 227.39 133.116 227.56 132.663C227.9 131.926 228.183 131.302 228.806 131.189C229.203 131.189 229.599 131.302 229.939 131.642C230.506 132.209 230.846 133.173 230.733 134.023C230.619 134.987 229.486 136.744 228.58 138.048C228.466 138.162 228.296 138.218 228.183 138.105C228.07 137.992 228.013 137.822 228.126 137.708C229.373 135.951 230.109 134.59 230.223 133.967C230.336 133.173 229.996 132.436 229.599 132.039C229.373 131.812 229.146 131.699 228.976 131.756C228.636 131.756 228.466 132.323 228.183 132.889C227.956 133.4 227.73 133.91 227.39 134.42C226.937 135.044 225.69 136.007 224.557 136.915C223.821 137.481 222.971 138.218 222.858 138.388C222.801 138.615 221.951 140.599 221.271 142.13C221.271 142.243 221.101 142.3 220.988 142.3H220.931Z"
          fill="#08080C"
        />
        <path
          d="M228.749 140.031C228.693 140.031 228.636 140.031 228.579 139.974L227.503 138.954C227.39 138.841 227.39 138.67 227.503 138.557C227.616 138.444 227.786 138.444 227.9 138.557L228.976 139.578C229.089 139.691 229.089 139.861 228.976 139.974C228.976 140.031 228.863 140.088 228.749 140.088V140.031Z"
          fill="#08080C"
        />
        <path
          d="M230.337 139.294C230.223 139.294 230.11 139.238 230.053 139.124C229.997 138.954 230.053 138.841 230.223 138.727C232.32 137.764 236.172 136.12 238.042 135.609C240.364 135.043 241.441 134.759 241.951 135.156C242.121 135.326 242.234 135.553 242.234 135.836C242.234 136.913 240.534 138.047 240.308 138.161C240.194 138.274 240.024 138.161 239.911 138.104C239.798 137.99 239.911 137.82 239.968 137.707C240.591 137.31 241.611 136.403 241.611 135.836C241.611 135.666 241.611 135.609 241.611 135.609C241.327 135.383 239.968 135.723 238.268 136.176C236.342 136.687 232.603 138.331 230.507 139.238C230.507 139.238 230.45 139.238 230.393 139.238L230.337 139.294Z"
          fill="#08080C"
        />
        <path
          d="M233.792 148.421H233.678C233.565 148.364 233.509 148.194 233.565 148.024C233.679 147.741 233.962 147.457 234.812 146.834C236.171 145.87 235.945 143.999 235.945 143.999C235.945 143.942 235.945 143.829 236.001 143.772C237.588 142.072 240.08 139.464 240.25 139.237C240.364 139.124 240.534 139.067 240.647 139.18C240.76 139.294 240.817 139.464 240.704 139.577C240.477 139.917 237.191 143.319 236.511 144.056C236.511 144.566 236.511 146.267 235.095 147.287C234.358 147.854 234.132 148.081 234.018 148.308C233.905 148.534 233.848 148.478 233.792 148.478V148.421Z"
          fill="#08080C"
        />
        <path
          d="M203.312 152.561C185.92 152.561 170.51 148.195 170.283 148.139C170.114 148.139 170.057 147.912 170.114 147.799C170.114 147.628 170.283 147.572 170.453 147.628C170.68 147.685 192.888 153.978 214.076 151.427C221.611 150.52 226.144 150.009 228.863 149.669C231.016 149.443 232.092 149.329 232.602 149.216C232.772 149.216 232.885 149.272 232.942 149.443C232.942 149.613 232.885 149.726 232.715 149.783C232.206 149.896 231.129 150.009 228.976 150.236C226.257 150.52 221.725 151.03 214.19 151.994C210.564 152.447 206.938 152.617 203.369 152.617L203.312 152.561Z"
          fill="#08080C"
        />
        <path
          d="M336.393 205.792C336.393 205.792 308.463 213.446 288.975 205.792C288.975 205.792 288.295 197.912 287.842 190.599C287.388 183.286 287.842 145.53 287.842 145.53L302.288 143.206L328.745 138.897C328.745 138.897 331.295 158.002 331.691 164.862C331.691 165.712 331.805 166.902 331.918 168.32C332.654 178.411 334.581 200.86 336.393 205.736V205.792Z"
          fill="#08080C"
        />
        <path
          d="M295.546 165.145C295.546 165.145 295.546 165.145 295.49 165.145C293.62 164.635 292.487 163.954 292.487 163.898C292.374 163.841 292.317 163.614 292.374 163.501C292.43 163.387 292.657 163.331 292.77 163.387C292.77 163.387 293.847 164.068 295.66 164.578C295.83 164.578 295.886 164.805 295.83 164.918C295.83 165.031 295.66 165.145 295.546 165.145Z"
          fill="white"
        />
        <path
          d="M307.047 209.477C306.934 209.477 306.82 209.42 306.764 209.307C296.283 176.653 296.169 162.083 296.169 161.97C296.169 161.856 296.283 161.686 296.453 161.686C296.623 161.686 296.736 161.8 296.736 161.97C296.736 162.14 296.849 176.596 307.33 209.137C307.33 209.307 307.33 209.42 307.16 209.477C307.16 209.477 307.103 209.477 307.047 209.477Z"
          fill="white"
        />
        <path
          d="M302.233 143.206L328.69 138.897C328.69 138.897 331.239 158.002 331.636 164.862C331.636 165.032 331.749 166.732 331.749 166.732C329.88 165.712 327.557 163.331 325.631 160.213C319.625 158.512 308.125 152.9 302.176 143.206H302.233Z"
          fill="#08080C"
        />
        <path
          d="M289.031 154.373C289.031 154.373 302.118 157.775 305.63 146.267C309.143 134.758 303.477 142.071 303.477 142.071L288.974 151.029V154.373H289.031Z"
          fill="#08080C"
        />
        <path
          d="M308.462 70.6408L301.493 66.4457L290.899 77.217C290.899 77.217 289.086 79.8814 288.52 83.8498C287.953 87.8182 289.086 89.5756 294.752 85.6639C300.417 81.7522 308.518 70.6975 308.518 70.6975L308.462 70.6408Z"
          fill="#9D5B3A"
        />
        <path
          d="M311.975 75.7421L306.366 138.896C306.366 138.896 302.854 148.59 297.529 151.425C294.696 152.955 292.09 153.239 290.277 153.239C288.691 153.239 287.671 152.955 287.671 152.955C287.671 152.955 287.558 152.898 287.501 152.842C284.782 150.461 284.782 120.471 284.782 120.244L281.326 87.7606L290.787 77.1594C290.673 77.4428 288.124 84.6993 291.07 85.0961C294.073 85.4362 308.349 70.6399 308.349 70.6399L311.862 75.6854L311.975 75.7421Z"
          fill="white"
        />
        <path
          d="M293.052 149.44C292.939 149.44 292.769 149.327 292.769 149.213C292.769 149.043 292.826 148.93 292.996 148.873C292.996 148.873 297.301 148.08 301.55 143.148C301.664 143.034 301.834 143.034 301.947 143.148C302.06 143.261 302.06 143.431 301.947 143.544C297.585 148.703 293.279 149.44 293.052 149.497V149.44Z"
          fill="#08080C"
        />
        <path
          d="M294.808 144.17C294.695 144.17 294.525 144.056 294.525 143.943C294.525 143.773 294.638 143.66 294.752 143.603C294.752 143.603 297.584 143.206 299.737 141.279C299.85 141.165 300.02 141.165 300.134 141.279C300.247 141.392 300.247 141.562 300.134 141.675C297.867 143.716 294.978 144.17 294.865 144.17H294.808Z"
          fill="#08080C"
        />
        <path
          d="M290.333 153.24C288.747 153.24 287.727 152.956 287.727 152.956C287.727 152.956 287.614 152.9 287.557 152.843C287.387 148.705 286.707 128.523 286.367 118.885C286.254 115.314 286.197 113.159 286.254 113.84C286.254 114.123 284.158 98.4197 284.554 91.9569C284.951 85.4941 289.993 78.2944 290.333 77.8408C290.673 77.3873 291.183 76.9338 291.126 77.3873C290.786 79.5983 289.087 85.9477 287.84 91.2766C285.574 100.914 286.707 117.411 286.764 117.581C287.84 125.688 290.39 143.376 290.39 145.133C290.333 147.287 290.39 153.013 290.39 153.24H290.333Z"
          fill="#08080C"
        />
        <path
          d="M302.401 146.721C302.401 146.721 302.628 145.247 302.401 144.85C302.175 144.453 301.098 144 301.098 144C301.098 144 300.985 142.922 300.815 142.639C300.645 142.356 299.229 142.015 299.229 142.015C299.229 142.015 298.719 142.412 298.209 142.639C297.699 142.866 298.719 140.655 298.492 139.748C298.265 138.841 290.617 117.468 291.637 104.543C292.543 93.2044 308.18 129.317 305.007 142.639C301.835 155.961 303.194 145.87 302.401 146.721Z"
          fill="#08080C"
        />
        <path
          d="M289.823 93.204C289.823 93.204 289.766 93.204 289.709 93.204C289.596 93.204 289.539 93.0906 289.539 92.9772C289.483 92.5237 287.613 81.5256 290.616 77.047C290.729 76.9337 290.899 76.877 291.012 76.9903C291.126 77.1037 291.182 77.2738 291.069 77.3872C288.576 81.1288 289.653 90.0293 289.993 92.3536C291.975 90.3127 300.757 80.7319 306.025 69.4504C306.082 69.2804 306.252 69.2237 306.422 69.3371C306.592 69.3938 306.649 69.5638 306.535 69.7339C300.587 82.5461 290.106 93.0906 289.993 93.204C289.993 93.204 289.879 93.2607 289.766 93.2607L289.823 93.204Z"
          fill="#08080C"
        />
        <path
          d="M289.993 95.925C289.2 95.925 288.747 95.3014 288.747 94.2809C288.747 93.6006 288.86 92.807 289.767 92.6369C290.163 92.5802 290.503 92.6369 290.787 92.8637C291.24 93.2038 291.41 93.8274 291.41 94.2809C291.41 95.3014 290.843 95.925 290.05 95.925H289.993ZM290.05 93.2038C290.05 93.2038 289.937 93.2038 289.88 93.2038C289.54 93.2605 289.314 93.4873 289.314 94.2809C289.314 94.6778 289.427 95.3581 289.993 95.3581C290.56 95.3581 290.787 94.9612 290.787 94.2809C290.787 93.6006 290.673 93.544 290.39 93.3172C290.277 93.2038 290.163 93.2038 290.05 93.2038Z"
          fill="#08080C"
        />
        <path
          d="M265.18 104.997C265.18 104.997 263.593 108.115 263.537 110.212C263.48 112.31 267.502 124.442 271.185 125.689C274.867 126.936 282.062 122.628 282.062 122.628C282.062 122.628 281.269 174.387 285.858 189.523L287.841 190.544C287.841 190.544 289.597 157.436 288.861 147.742C288.124 138.048 285.292 103.636 285.688 99.3845C286.085 95.1327 290.957 77.1616 290.957 77.1616C290.957 77.1616 278.323 84.5881 276 87.2526C273.678 89.9171 267.729 99.8947 267.729 99.8947C267.729 99.8947 264.67 100.745 264.556 102.106C264.443 103.466 265.236 104.94 265.236 104.94L265.18 104.997Z"
          fill="#AEDFEF"
        />
        <path
          d="M282.176 121.323C283.706 116.617 284.386 103.635 287.785 92.1836L276.794 90.4261L267.9 104.486C267.9 104.486 267.05 114.52 269.599 122.4C272.149 130.28 282.176 121.323 282.176 121.323Z"
          fill="#AEDFEF"
        />
        <path
          d="M282.063 96.2084C279.967 95.8116 275.491 91.4464 276.794 90.426C278.097 89.4055 287.615 91.7299 287.785 92.1834C287.955 92.6369 286.142 97.0021 282.063 96.2084Z"
          fill="#08080C"
        />
        <path
          d="M266.653 106.64C266.653 106.64 266.54 106.64 266.483 106.64C266.37 106.526 266.313 106.356 266.427 106.243C266.483 106.129 274.641 93.9409 275.548 92.0701C275.604 91.9567 275.774 91.9 275.944 91.9567C276.058 92.0134 276.114 92.1835 276.058 92.3535C275.151 94.2243 267.22 106.073 266.88 106.583C266.88 106.64 266.71 106.696 266.653 106.696V106.64Z"
          fill="#08080C"
        />
        <path
          d="M282.176 121.605C282.176 121.605 282.119 121.605 282.063 121.605C281.893 121.605 281.836 121.378 281.893 121.265C282.516 119.337 283.026 115.936 283.592 112.024C284.442 106.185 285.519 98.9287 287.501 92.1258C287.501 91.9557 287.728 91.899 287.841 91.9557C288.011 91.9557 288.068 92.1825 288.011 92.2959C286.029 99.0421 284.952 106.299 284.102 112.138C283.479 116.276 283.026 119.508 282.403 121.492C282.403 121.605 282.233 121.662 282.119 121.662L282.176 121.605Z"
          fill="#08080C"
        />
        <path
          d="M266.653 101.764C266.54 101.764 266.426 101.707 266.37 101.537C266.37 101.367 266.37 101.254 266.54 101.197C266.54 101.197 267.729 100.857 268.636 100.744C268.806 100.744 268.919 100.857 268.919 101.027C268.919 101.197 268.806 101.31 268.636 101.31C267.786 101.367 266.653 101.707 266.596 101.707C266.596 101.707 266.596 101.707 266.54 101.707L266.653 101.764Z"
          fill="#08080C"
        />
        <path
          d="M271.072 63.1019C271.355 63.8389 271.412 63.7255 273.791 66.6734C273.791 66.6734 274.698 69.2812 275.264 70.3017C275.831 71.3221 282.969 77.6148 284.046 77.3313C284.215 77.2746 284.499 77.1613 284.839 76.9912C286.312 76.1975 287.445 75.6306 288.238 75.4038C289.031 75.1771 291.41 78.6919 291.41 78.6919L301.438 66.4467C297.869 63.7822 295.263 61.3445 294.243 59.1902C292.487 55.3352 294.073 52.3873 292.26 50.7433C290.901 49.4394 286.822 50.063 282.743 51.2535C278.494 52.444 274.188 54.2581 272.602 55.2218C269.429 57.0926 269.769 57.6596 269.769 57.6596C269.769 57.6596 269.826 57.9997 269.939 58.5666C270.279 60.0406 270.902 62.8751 271.015 63.2153L271.072 63.1019Z"
          fill="#9D5B3A"
        />
        <path
          d="M276.849 61.6281C276.849 61.6281 276.056 60.0975 277.246 59.1337C278.436 58.17 281.778 58.8503 281.778 58.8503C281.778 58.8503 279.172 59.4172 278.549 59.9274C277.926 60.4376 276.849 61.6281 276.849 61.6281Z"
          fill="#08080C"
        />
        <path
          d="M274.698 62.8185C274.698 62.8185 272.885 62.9886 272.431 63.2153C271.978 63.4421 271.808 63.8389 271.808 63.8389C271.808 63.8389 270.958 62.8752 271.865 62.1382C272.771 61.4012 274.641 62.8185 274.641 62.8185H274.698Z"
          fill="#08080C"
        />
        <path
          d="M278.096 69.5653C277.36 69.5653 276.737 69.3952 276.34 69.0551C276.057 68.8283 275.83 68.5449 275.83 68.2614C275.83 67.1843 277.133 66.1071 277.19 66.0504C277.303 65.9371 277.473 65.9938 277.586 66.0504C277.7 66.1638 277.643 66.3339 277.586 66.4473C277.246 66.7307 276.453 67.5244 276.453 68.2047C276.453 68.2614 276.51 68.4315 276.737 68.5449C276.963 68.7149 277.756 69.2251 279.229 68.8283C279.399 68.8283 279.513 68.8283 279.569 69.0551C279.626 69.2818 279.569 69.3385 279.343 69.3952C278.946 69.5086 278.493 69.5653 278.153 69.5653H278.096Z"
          fill="#08080C"
        />
        <path
          d="M278.04 72.3982C278.04 72.3982 277.247 71.9447 277.303 71.6612C277.416 70.9809 278.266 69.5636 278.55 69.2802C278.833 68.9967 283.478 66.7858 284.102 66.6724C284.725 66.559 284.611 67.7495 284.611 67.7495C284.611 67.7495 279.966 70.3573 279.626 70.5841C279.286 70.8109 278.096 72.3982 278.096 72.3982H278.04Z"
          fill="#08080C"
        />
        <path
          d="M279.742 71.9445C280.138 72.2847 281.611 72.965 282.461 72.398C283.424 71.7178 283.821 68.7131 283.821 68.7131C283.594 68.9966 281.045 70.8674 279.742 71.9445Z"
          fill="white"
        />
        <path
          d="M281.611 72.9091C280.762 72.9091 279.855 72.4556 279.515 72.1721C279.459 72.1721 279.402 72.0587 279.402 71.9453C279.402 71.8886 279.402 71.7753 279.515 71.7186C279.968 71.3217 280.592 70.8682 281.215 70.358C282.178 69.621 283.424 68.6573 283.538 68.5439C283.594 68.4305 283.764 68.4305 283.877 68.4872C283.991 68.4872 284.047 68.6573 284.047 68.7706C284.047 69.1108 283.651 71.9453 282.574 72.6256C282.291 72.7957 281.951 72.9091 281.611 72.9091ZM280.195 71.8886C280.762 72.1721 281.725 72.5122 282.291 72.1154C282.858 71.7753 283.198 70.358 283.424 69.3942C283.028 69.7344 282.461 70.1879 281.611 70.8115C281.101 71.2084 280.648 71.5485 280.195 71.8886Z"
          fill="#08080C"
        />
        <path
          d="M279.005 72.6816C278.891 72.6816 278.835 72.6816 278.778 72.5682C278.721 72.4548 278.778 72.2848 278.891 72.1714C278.891 72.1714 279.231 72.0013 279.571 71.7179L283.65 68.4865C283.764 68.3731 283.934 68.4298 284.047 68.4865C284.047 68.4865 284.103 68.5999 284.103 68.6566C284.103 68.7132 284.103 68.8266 283.99 68.8833L279.911 72.1147C279.515 72.3982 279.231 72.5682 279.231 72.5682C279.231 72.5682 279.118 72.5682 279.061 72.5682L279.005 72.6816Z"
          fill="#08080C"
        />
        <path
          d="M282.064 75.0056C281.95 75.0056 281.78 74.8922 281.78 74.7788C281.78 74.6088 281.894 74.4954 282.007 74.4387C283.763 74.2119 284.103 73.0214 284.103 73.0214C284.103 72.8513 284.273 72.7946 284.443 72.8513C284.613 72.8513 284.67 73.0781 284.613 73.1915C284.613 73.2482 284.16 74.7221 282.064 75.0056Z"
          fill="#08080C"
        />
        <path
          d="M274.754 67.4112C275.83 67.4112 275.83 65.7104 274.754 65.7104C273.678 65.7104 273.678 67.4112 274.754 67.4112Z"
          fill="#08080C"
        />
        <path
          d="M280.306 64.2354C281.382 64.2354 281.382 62.5347 280.306 62.5347C279.229 62.5347 279.229 64.2354 280.306 64.2354Z"
          fill="#08080C"
        />
        <path
          d="M282.913 57.3191C283.309 58.0561 286.709 57.2057 286.709 57.2057L288.182 60.7206H289.995C289.995 60.7206 287.219 56.6388 287.558 56.0719C287.898 55.4483 290.108 55.2215 290.561 57.0923C291.014 58.9631 294.3 59.1899 294.3 59.1899C294.3 59.1899 293.96 51.9901 293.167 51.2532C291.977 50.006 289.145 49.3257 281.78 51.5366C281.78 51.5366 282.516 56.5821 282.913 57.3191Z"
          fill="#08080C"
        />
        <path
          d="M291.467 62.761C291.467 62.761 293.564 62.1374 293.394 58.906C293.224 55.6746 291.014 53.3503 288.975 56.5817C290.164 62.1374 291.467 62.761 291.467 62.761Z"
          fill="#9D5B3A"
        />
        <path
          d="M291.468 63.0445C291.354 63.0445 291.241 62.9879 291.184 62.8178C291.184 62.6477 291.184 62.5343 291.354 62.4776C291.411 62.4776 293.224 61.854 293.054 58.9061C292.941 57.092 292.204 55.618 291.241 55.3346C290.391 55.1078 289.655 55.9582 289.145 56.6952C289.088 56.8085 288.862 56.8652 288.748 56.8085C288.635 56.7518 288.578 56.5251 288.635 56.4117C289.711 54.7677 290.675 54.6543 291.354 54.8244C292.544 55.1645 293.451 56.8085 293.564 58.9061C293.734 62.2509 291.524 62.9879 291.468 63.0445C291.468 63.0445 291.468 63.0445 291.411 63.0445H291.468Z"
          fill="#08080C"
        />
        <path
          d="M290.902 60.5507C290.732 60.5507 290.618 60.4374 290.618 60.2673C290.562 59.4169 290.448 57.4327 290.902 56.9792C291.015 56.8091 291.242 56.7524 291.411 56.7524C291.865 56.7524 292.205 57.3194 292.261 57.4327C292.318 57.5461 292.261 57.7162 292.148 57.8296C292.035 57.8863 291.865 57.8296 291.751 57.7162C291.638 57.4894 291.411 57.2627 291.355 57.3194C291.128 57.5461 291.128 59.0201 291.185 60.2106C291.185 60.3807 291.072 60.494 290.902 60.494V60.5507Z"
          fill="#08080C"
        />
        <path
          d="M284.839 76.9906C286.312 76.1969 287.445 75.63 288.238 75.4032C289.031 75.1765 289.541 76.5938 289.994 76.9906C294.47 70.6412 291.864 67.6933 290.108 64.9154C290.108 64.9154 291.637 72.512 284.839 76.9906Z"
          fill="#08080C"
        />
        <path
          d="M270.223 59.4175C276.115 53.7484 290.221 54.5988 290.221 54.5988L282.8 51.254C278.551 52.4445 274.245 54.2587 272.659 55.2224C269.486 57.0932 269.826 57.6601 269.826 57.6601C269.826 57.6601 270.109 58.964 270.223 59.4742V59.4175Z"
          fill="#08080C"
        />
        <path
          d="M288.181 60.7206C288.181 60.7206 288.011 63.6685 286.482 65.9362L286.085 68.7707C286.085 68.7707 284.669 66.2196 284.045 66.6732C283.422 67.1267 284.045 67.8637 284.045 67.8637C284.045 67.8637 286.878 72.6257 286.822 73.4194C286.765 74.2131 285.745 75.2335 284.895 75.7437C284.045 76.2539 283.819 73.8729 283.762 73.8729C283.706 73.8729 283.082 74.3831 283.026 74.3831C282.969 74.3831 284.215 76.0839 284.159 76.0839C283.309 76.5374 282.629 76.4807 282.629 76.4807C282.629 76.4807 278.833 73.8729 277.927 72.2856L277.19 71.5486C277.19 71.5486 276.907 71.9454 277.36 72.569C277.814 73.1926 280.646 77.5011 282.346 79.0318C284.045 80.5625 293.563 74.9501 292.26 68.2038C291.297 63.1583 289.881 60.5505 289.881 60.5505H288.068L288.181 60.7206Z"
          fill="#08080C"
        />
        <path
          d="M294.129 70.8689C293.959 70.8689 293.846 70.7555 293.846 70.5855C293.789 67.9777 292.939 63.9526 292.939 63.8959C292.939 63.7259 292.996 63.6125 293.166 63.5558C293.336 63.5558 293.449 63.6125 293.506 63.7826C293.506 63.9526 294.356 67.921 294.469 70.5855C294.469 70.7555 294.356 70.8689 294.186 70.8689H294.129Z"
          fill="#08080C"
        />
        <path
          d="M293.167 46.2079C291.524 45.8677 288.578 45.6977 283.479 46.2079C277.134 46.7748 272.715 48.5322 270.732 49.496C271.752 47.3417 274.641 43.1466 281.949 42.5797C288.861 42.0127 291.977 44.6205 293.167 46.2646V46.2079Z"
          fill="#FA7353"
        />
        <path
          d="M274.472 47.116C274.472 47.116 274.358 47.116 274.302 47.116C274.188 47.0593 274.132 46.8892 274.188 46.7192C274.188 46.6625 275.208 45.0184 278.437 43.6579C278.607 43.6012 278.721 43.6579 278.834 43.8279C278.89 43.998 278.834 44.1114 278.664 44.2248C275.661 45.5287 274.698 47.0026 274.698 47.0026C274.698 47.116 274.528 47.116 274.472 47.116Z"
          fill="#08080C"
        />
        <path
          d="M278.494 46.0372C278.494 46.0372 278.381 46.0372 278.324 46.0372C278.211 45.9238 278.155 45.7537 278.268 45.6404C278.664 45.0735 279.344 44.2231 280.704 43.4861C280.817 43.4294 280.987 43.4861 281.1 43.5995C281.157 43.7129 281.1 43.8829 280.987 43.9963C279.741 44.6766 279.061 45.4703 278.721 45.9805C278.721 46.0372 278.551 46.0939 278.494 46.0939V46.0372Z"
          fill="#08080C"
        />
        <path
          d="M282.007 45.4706C281.837 45.4706 281.724 45.3006 281.78 45.1305C281.837 44.677 282.12 43.8833 282.517 43.3164C282.63 43.203 282.8 43.1463 282.913 43.2597C283.027 43.3731 283.083 43.5431 282.97 43.6565C282.573 44.2234 282.404 44.9037 282.347 45.2439C282.347 45.3572 282.234 45.4706 282.064 45.4706H282.007Z"
          fill="#08080C"
        />
        <path
          d="M285.292 45.1311C285.179 45.1311 285.066 45.0744 285.009 44.9611C284.839 44.4508 284.669 43.9406 284.499 43.6005C284.443 43.4304 284.499 43.317 284.669 43.2036C284.839 43.1469 284.952 43.2036 285.066 43.3737C285.236 43.7705 285.406 44.2241 285.576 44.791C285.576 44.9611 285.576 45.0744 285.406 45.1311C285.406 45.1311 285.349 45.1311 285.292 45.1311Z"
          fill="#08080C"
        />
        <path
          d="M287.729 45.0182C287.615 45.0182 287.559 45.0182 287.502 44.9048C287.105 44.3379 286.596 43.8843 286.029 43.4875C285.916 43.3741 285.859 43.204 285.972 43.0907C286.086 42.9773 286.256 42.9206 286.369 43.034C286.992 43.4308 287.502 43.941 287.955 44.5646C288.069 44.678 288.012 44.8481 287.899 44.9615C287.899 44.9615 287.785 44.9615 287.729 44.9615V45.0182Z"
          fill="#08080C"
        />
        <path
          d="M289.937 45.0184C289.881 45.0184 289.824 45.0184 289.767 44.9617C289.144 44.4514 288.464 44.0546 287.671 43.6578C287.501 43.6011 287.445 43.431 287.558 43.2609C287.615 43.1476 287.785 43.0342 287.955 43.1476C288.748 43.5444 289.484 43.9979 290.164 44.5081C290.277 44.6215 290.277 44.7916 290.164 44.905C290.164 44.9617 290.051 45.0184 289.937 45.0184Z"
          fill="#08080C"
        />
        <path
          d="M293.959 55.1074C293.959 55.1074 296.169 54.0303 296.112 52.8398C296.056 51.6493 295.262 50.5721 295.262 50.5721C295.262 50.5721 295.206 48.4746 295.036 48.1911C294.243 46.7172 287.048 46.2069 278.776 47.9077C270.505 49.6084 268.805 51.6493 268.522 52.1028C268.239 52.5563 268.862 54.4271 268.862 54.4271C268.862 54.4271 267.559 55.5043 267.616 56.4113C267.672 57.3184 269.938 58.5656 269.938 58.5656L269.768 57.6585C271.411 54.5405 290.503 50.3454 293.563 52.3296L293.959 55.1641V55.1074Z"
          fill="#FA7353"
        />
        <path
          d="M294.696 53.4629C294.583 53.4629 294.526 53.4629 294.469 53.3496C294.469 53.3496 294.073 52.726 293.449 52.5559C293.28 52.5559 293.223 52.3291 293.28 52.2157C293.28 52.0457 293.506 51.989 293.619 52.0457C294.469 52.3291 294.923 53.0661 294.923 53.1228C294.979 53.2362 294.922 53.4063 294.809 53.5196C294.809 53.5196 294.696 53.5196 294.639 53.5196L294.696 53.4629Z"
          fill="#08080C"
        />
        <path
          d="M271.186 55.618C271.016 55.618 270.676 55.448 270.506 55.1078C270.28 54.6543 270.45 54.0307 270.903 53.2937C270.846 53.0103 270.733 52.33 270.903 51.8198C270.903 51.6497 271.129 51.593 271.243 51.6497C271.413 51.6497 271.469 51.8764 271.413 51.9898C271.243 52.3867 271.413 53.067 271.413 53.237C271.413 53.2937 271.413 53.4071 271.413 53.4638C270.903 54.3142 270.959 54.711 271.016 54.8244C271.073 54.9944 271.243 54.9944 271.243 54.9944C271.413 54.9944 271.526 55.1645 271.469 55.3346C271.469 55.448 271.356 55.5614 271.186 55.5614V55.618Z"
          fill="#08080C"
        />
        <path
          d="M275.377 53.7467C275.32 53.7467 275.263 53.7467 275.207 53.69C275.207 53.69 274.697 53.1798 274.64 52.4995C274.64 52.1027 274.753 51.7058 275.093 51.3657C275.037 51.0822 274.923 50.5153 275.093 49.9484C275.093 49.7783 275.32 49.7216 275.433 49.7783C275.603 49.7783 275.66 50.0051 275.603 50.1185C275.433 50.6287 275.603 51.309 275.603 51.309C275.603 51.4224 275.603 51.5358 275.547 51.5925C275.263 51.8759 275.15 52.1594 275.15 52.3861C275.15 52.8397 275.49 53.1798 275.49 53.1798C275.603 53.2932 275.603 53.4633 275.49 53.5766C275.49 53.6333 275.377 53.69 275.263 53.69L275.377 53.7467Z"
          fill="#08080C"
        />
        <path
          d="M279.739 52.7835C279.626 52.7835 279.569 52.7835 279.513 52.6135C279.513 52.6135 278.946 51.5363 279.173 50.4025C279.173 50.1757 279.003 49.6655 279.059 49.0419C279.059 48.8719 279.229 48.7585 279.399 48.8152C279.569 48.8152 279.683 48.9852 279.626 49.1553C279.513 49.7789 279.739 50.3458 279.739 50.3458C279.739 50.3458 279.739 50.4592 279.739 50.5159C279.456 51.4796 279.966 52.3867 279.966 52.3867C280.023 52.5001 279.966 52.6701 279.853 52.7835H279.739Z"
          fill="#08080C"
        />
        <path
          d="M283.705 51.8754C283.591 51.8754 283.478 51.762 283.421 51.6486C283.421 51.6486 283.138 50.3447 283.308 49.5511C283.308 49.381 283.195 48.7574 283.138 48.4172C283.138 48.2472 283.251 48.1338 283.421 48.1338C283.591 48.1338 283.705 48.2472 283.705 48.4172C283.705 48.8141 283.875 49.5511 283.875 49.5511C283.875 49.5511 283.875 49.6078 283.875 49.6644C283.761 50.2314 283.875 51.1951 283.988 51.5352C283.988 51.7053 283.931 51.8187 283.761 51.8754H283.705Z"
          fill="#08080C"
        />
        <path
          d="M288.01 51.3098C287.84 51.3098 287.727 51.1964 287.727 51.0263C287.727 51.0263 287.727 50.0626 287.273 49.3823C287.273 49.3823 287.273 49.3256 287.273 49.2689C287.273 49.2689 287.104 48.4752 287.104 48.0784C287.104 47.9083 287.217 47.7949 287.387 47.7949C287.557 47.7949 287.67 47.9083 287.67 48.0784C287.67 48.4185 287.783 48.9854 287.84 49.1555C288.35 50.0059 288.35 51.0263 288.35 51.083C288.35 51.2531 288.237 51.3665 288.067 51.3665L288.01 51.3098Z"
          fill="#08080C"
        />
        <path
          d="M291.863 51.3099C291.807 51.3099 291.75 51.3099 291.693 51.2532C291.58 51.1398 291.58 50.9697 291.693 50.8563C291.693 50.8563 291.863 50.6863 291.807 50.4595C291.807 50.2894 291.693 50.1761 291.58 50.0627C291.523 50.0627 291.467 49.9493 291.467 49.8359C291.467 49.8359 291.467 48.7588 291.354 48.3619C291.354 48.1919 291.467 48.0785 291.637 48.0785C291.807 48.0785 291.92 48.1919 291.92 48.3619C291.92 48.7021 291.977 49.4391 291.977 49.7225C292.203 49.9493 292.317 50.1761 292.317 50.4595C292.317 50.913 292.09 51.2532 292.033 51.2532C292.033 51.3099 291.92 51.3666 291.807 51.3666L291.863 51.3099Z"
          fill="#08080C"
        />
        <path
          d="M277.645 91.2771C277.645 91.2771 277.532 86.0049 277.645 84.7577C277.758 83.5105 285.067 82.3199 285.067 82.3199C285.067 82.3199 284.67 79.7122 285.69 78.1248C286.71 76.5375 288.239 76.7075 288.636 76.9343C289.032 77.1611 288.126 80.0523 288.183 80.5058C288.239 80.9594 288.692 86.7418 288.636 87.7056C288.579 88.6693 285.86 92.5243 283.82 93.7715C281.781 95.0187 277.588 91.2204 277.588 91.2204L277.645 91.2771Z"
          fill="#9D5B3A"
        />
        <path
          d="M277.928 81.979C277.928 81.979 282.12 78.6909 284.047 77.387C285.973 76.0831 289.259 74.3257 289.825 74.8926C290.392 75.4595 290.278 76.4232 289.655 76.8768C289.032 77.3303 285.973 79.031 285.973 79.031C285.973 79.031 282.8 82.9427 282.007 83.4529C281.214 83.9631 277.758 82.6026 277.871 81.979H277.928Z"
          fill="#9D5B3A"
        />
        <path
          d="M285.122 80.3341C285.066 80.3341 285.009 80.3341 284.952 80.2775C284.839 80.1641 284.839 79.994 284.952 79.8806C285.462 79.257 285.802 78.8602 285.802 78.8602C285.802 78.8602 285.802 78.8602 285.859 78.8035C285.859 78.8035 287.615 77.8397 288.691 77.1594C288.805 77.1028 288.975 77.1594 289.088 77.2728C289.145 77.3862 289.088 77.5563 288.975 77.6697C287.955 78.2933 286.482 79.1436 286.142 79.257C286.029 79.3704 285.745 79.7672 285.292 80.2775C285.292 80.3341 285.179 80.3908 285.065 80.3908L285.122 80.3341Z"
          fill="#08080C"
        />
        <path
          d="M277.701 84.7578C277.701 84.7578 277.304 82.6036 277.928 81.98C278.551 81.3564 285.406 80.9595 286.029 81.5831C286.652 82.2067 286.369 83.6807 286.369 83.6807C286.369 83.6807 287.162 84.0208 287.105 86.0617C287.105 86.0617 287.899 87.6491 287.445 88.6128C286.992 89.5766 285.123 88.5561 285.123 88.5561C285.123 88.5561 284.273 90.3135 283.65 90.597C283.026 90.8805 278.268 89.123 277.701 84.7578Z"
          fill="#9D5B3A"
        />
        <path
          d="M283.65 90.8794C283.537 90.8794 283.423 90.8227 283.423 90.7093C283.367 90.596 283.423 90.4259 283.537 90.3125C283.876 90.1424 284.556 89.122 284.896 88.385C284.896 88.3283 285.01 88.2716 285.066 88.2149C285.123 88.2149 285.236 88.2149 285.293 88.2149C285.576 88.385 286.483 88.7818 286.992 88.6685C287.106 88.6685 287.162 88.5551 287.219 88.4417C287.559 87.7614 287.106 86.5142 286.879 86.1174V86.004C286.879 84.1899 286.313 83.8497 286.313 83.8497C286.199 83.793 286.143 83.6797 286.143 83.5096C286.199 83.1128 286.369 82.0923 285.916 81.6388C285.746 81.4687 284.216 81.2986 282.007 81.4687C281.837 81.4687 281.724 81.3553 281.724 81.1853C281.724 81.0152 281.837 80.9018 282.007 80.9018C282.403 80.9018 285.689 80.6183 286.313 81.2419C286.936 81.8089 286.822 82.9427 286.766 83.4529C287.049 83.6797 287.502 84.3599 287.502 85.9473C287.672 86.2874 288.239 87.7047 287.786 88.6685C287.672 88.9519 287.446 89.122 287.219 89.1787C286.596 89.3488 285.803 89.0653 285.349 88.8385C285.066 89.4054 284.443 90.4826 283.876 90.766C283.876 90.766 283.82 90.766 283.763 90.766L283.65 90.8794Z"
          fill="#08080C"
        />
        <path
          d="M281.781 84.3027C281.668 84.3027 281.555 84.246 281.498 84.0759C281.498 83.9058 281.498 83.7924 281.725 83.7358C281.781 83.7358 283.424 83.2822 285.124 83.2822C286.823 83.2822 285.407 83.3956 285.407 83.5657C285.407 83.7358 285.294 83.8491 285.124 83.8491C283.481 83.8491 281.895 84.246 281.838 84.246C281.838 84.246 281.838 84.246 281.781 84.246V84.3027Z"
          fill="#08080C"
        />
        <path
          d="M282.686 86.6283C282.572 86.6283 282.402 86.5149 282.402 86.4016C282.402 86.2315 282.516 86.1181 282.629 86.0614C282.629 86.0614 284.555 85.8346 286.198 85.778C286.368 85.778 286.481 85.8913 286.481 86.0614C286.481 86.2315 286.368 86.3449 286.198 86.3449C284.612 86.3449 282.742 86.6283 282.686 86.6283Z"
          fill="#08080C"
        />
        <path
          d="M283.253 88.8958C283.083 88.8958 282.97 88.7824 282.97 88.6123C282.97 88.4423 283.083 88.3289 283.253 88.3289C283.253 88.3289 283.706 88.3289 284.046 88.1588C284.216 88.1588 284.329 88.1588 284.386 88.3289C284.386 88.499 284.386 88.6123 284.216 88.669C283.763 88.8391 283.253 88.8958 283.253 88.8958Z"
          fill="#08080C"
        />
        <path
          d="M340.018 189.636C340.018 189.636 340.754 191.676 341.151 193.15C341.321 193.887 338.998 193.15 336.448 193.887C335.598 194.133 333.899 194.738 333.899 194.738C328.913 195.815 322.455 198.083 322.285 195.872C322.285 195.872 325.967 174.046 325.967 160.44C325.118 154.487 323.192 152.9 323.192 152.9C323.192 152.9 304.496 145.02 303.136 143.546C301.833 142.072 291.636 124.328 291.636 103.352C291.636 82.3762 302.06 64.9154 302.853 64.9154C303.59 64.9154 311.464 70.0176 311.464 70.0176C311.464 70.0176 311.068 73.986 309.312 76.7638C309.312 76.7638 327.837 118.262 329.367 130.11C330.896 142.015 343.247 182.946 343.757 185.157C344.267 187.311 340.131 189.636 340.131 189.636H340.018Z"
          fill="#AEDFEF"
        />
        <path
          d="M337.128 189.41C336.958 189.41 336.845 189.296 336.845 189.126C336.845 188.956 335.939 169.568 335.939 165.996C335.939 162.425 327.441 145.191 327.384 145.021C327.327 144.907 327.384 144.737 327.497 144.624C327.611 144.567 327.781 144.624 327.894 144.737C328.234 145.474 336.505 162.311 336.505 165.939C336.505 169.568 337.412 188.843 337.412 189.069C337.412 189.239 337.298 189.353 337.128 189.353V189.41Z"
          fill="#08080C"
        />
        <path
          d="M339.451 187.085C339.338 187.085 339.168 186.971 339.168 186.858C337.978 179.998 338.035 171.721 338.035 171.665C338.035 171.495 338.148 171.381 338.318 171.381C338.488 171.381 338.602 171.495 338.602 171.665C338.602 171.721 338.602 179.998 339.735 186.801C339.735 186.971 339.678 187.085 339.508 187.141L339.451 187.085Z"
          fill="#08080C"
        />
        <path
          d="M324.949 82.7173C323.249 78.0119 311.352 70.6421 308.463 70.6421C305.574 70.6421 293.79 86.1754 304.044 98.5341C304.044 98.5341 306.65 115.088 306.65 115.711C306.65 116.335 305.404 118.489 305.687 118.659C305.97 118.829 306.933 119.566 306.933 119.566C306.933 119.566 308.916 148.706 308.463 152.957L322.343 155.679C322.343 155.679 327.442 138.955 329.311 130.168C332.484 115.088 328.971 94.0555 324.949 82.7173Z"
          fill="#AEDFEF"
        />
        <path
          d="M306.31 85.5526C306.253 85.5526 306.196 85.5526 306.14 85.4959C306.026 85.3825 306.026 85.2125 306.14 85.0991C306.196 85.0424 312.485 78.4096 323.022 82.4346C323.192 82.4346 323.249 82.6614 323.192 82.7748C323.136 82.8881 322.966 83.0015 322.852 82.9448C312.655 79.0332 306.65 85.4392 306.593 85.4959C306.593 85.5526 306.48 85.6093 306.366 85.6093L306.31 85.5526Z"
          fill="#08080C"
        />
        <path
          d="M322.343 155.962L308.463 153.241C308.293 153.241 308.236 153.071 308.236 152.901C308.236 152.73 308.406 152.617 308.576 152.674L322.23 155.338C322.853 153.241 327.385 138.218 329.085 130.111C329.085 129.941 329.255 129.884 329.425 129.884C329.595 129.884 329.651 130.054 329.651 130.224C327.838 138.955 322.74 155.565 322.683 155.735C322.683 155.849 322.513 155.962 322.4 155.962H322.343Z"
          fill="#08080C"
        />
        <path
          d="M308.405 146.552C308.235 146.552 308.122 146.438 308.122 146.268C307.839 138.331 307.045 126.256 306.762 121.381C306.762 121.211 306.876 121.097 307.045 121.097C307.215 121.097 307.329 121.211 307.329 121.381C307.669 126.199 308.405 138.331 308.688 146.268C308.688 146.438 308.575 146.552 308.405 146.552Z"
          fill="#08080C"
        />
        <path
          d="M306.763 119.793C306.763 119.793 305.799 119.056 305.516 118.886C305.176 118.659 305.403 118.149 305.856 117.129C306.083 116.619 306.366 115.938 306.366 115.712C306.366 115.485 306.479 115.428 306.649 115.428C306.819 115.428 306.933 115.541 306.933 115.712C306.933 116.052 306.706 116.562 306.366 117.356C306.196 117.752 305.969 118.263 305.913 118.489C306.253 118.716 307.102 119.34 307.102 119.34L306.763 119.793Z"
          fill="#08080C"
        />
        <path
          d="M306.423 114.124C306.31 114.124 306.14 114.01 306.14 113.897C305.517 109.475 303.817 98.7038 303.817 98.5904C303.817 98.4203 303.874 98.3069 304.044 98.2502C304.214 98.2502 304.327 98.3069 304.384 98.477C304.384 98.5904 306.084 109.362 306.707 113.784C306.707 113.954 306.593 114.067 306.48 114.124H306.423Z"
          fill="#08080C"
        />
        <path
          d="M303.476 98.0816C303.363 98.0816 303.306 98.0816 303.25 97.9682C302.457 96.8911 301.777 95.8139 301.267 94.6234C301.21 94.4534 301.267 94.34 301.38 94.2266C301.55 94.1699 301.663 94.2266 301.777 94.34C302.287 95.4738 302.966 96.5509 303.703 97.5714C303.816 97.6847 303.76 97.8548 303.703 97.9682C303.703 97.9682 303.59 98.0249 303.533 98.0249L303.476 98.0816Z"
          fill="#08080C"
        />
        <path
          d="M308.463 152.902C308.01 151.428 321.946 153.525 322.343 155.623C322.683 157.267 309.879 155.623 308.463 152.902Z"
          fill="#08080C"
        />
        <path
          d="M306.026 147.573C306.026 147.573 306.026 169.002 310.332 171.043C314.637 173.084 324.891 172.97 324.891 172.97L325.685 160.215C325.685 160.215 313.957 157.947 306.026 147.516V147.573Z"
          fill="#08080C"
        />
        <path
          d="M320.133 158.458C320.133 158.458 319.849 155.737 319.679 155.34C319.509 154.943 313.618 153.299 311.295 153.016C308.972 152.732 316.563 157.438 320.133 158.515V158.458Z"
          fill="#9D5B3A"
        />
        <path
          d="M306.026 147.575C306.026 147.575 314.694 159.933 325.685 160.273C315.487 156.589 310.275 152.167 306.026 147.575Z"
          fill="#08080C"
        />
        <path
          d="M308.463 118.148C308.35 118.148 308.236 118.035 308.18 117.922C308.18 117.752 308.236 117.638 308.406 117.581C308.406 117.581 313.448 116.391 318.321 117.355C318.491 117.355 318.547 117.525 318.547 117.695C318.547 117.865 318.377 117.922 318.207 117.922C313.448 117.015 308.576 118.148 308.52 118.148C308.52 118.148 308.52 118.148 308.463 118.148Z"
          fill="#08080C"
        />
        <path
          d="M294.81 115.938C294.64 115.938 294.526 115.768 294.526 115.654C294.526 115.541 295.603 104.089 296.056 101.198C296.283 99.6106 299.399 94.1116 302.458 88.8393C304.667 84.9843 307.16 80.6758 307.103 80.0522C307.103 79.8821 307.047 79.8254 306.933 79.7121C306.367 79.2585 304.724 79.542 304.157 79.6554C303.987 79.6554 303.874 79.6554 303.818 79.4853C303.761 79.3152 303.818 79.2018 303.987 79.1451C303.987 79.1451 307.16 77.9546 309.143 76.5941C309.823 76.0838 310.673 72.8524 311.069 70.9816C311.069 70.8116 311.239 70.7549 311.409 70.7549C311.579 70.7549 311.692 70.925 311.636 71.095C311.522 71.6052 310.616 76.3106 309.483 77.1043C308.406 77.8413 307.047 78.5215 305.97 79.0318C306.48 79.0318 306.933 79.1451 307.273 79.3719C307.5 79.542 307.67 79.8254 307.67 80.1089C307.67 80.7892 306.254 83.397 302.911 89.1795C300.022 94.1683 296.792 99.8374 296.566 101.311C296.169 104.146 295.093 115.597 295.036 115.711C295.036 115.881 294.923 115.994 294.753 115.994L294.81 115.938Z"
          fill="#08080C"
        />
      </g>
      <defs>
        <clipPath id="clip0_76_10635">
          <path
            d="M44 20C44 8.95431 52.9543 0 64 0H335C346.046 0 355 8.95431 355 20V200H44V20Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default InviteIllustration;
