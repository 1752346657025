import { SVGProps } from 'react';

export function RemittanceSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.875 1.125C14.8665 1.125 13.0048 1.51954 11.5576 2.19546C10.3398 2.76427 8.70065 3.125 6.875 3.125C5.863 3.125 4.90489 3.01407 4.04245 2.81653C2.716 2.51272 1.125 3.42178 1.125 4.98897V15.761C1.125 16.5966 1.25742 17.3832 1.69144 18.0238C2.14024 18.6861 2.83189 19.0781 3.70755 19.2787C4.68449 19.5024 5.75516 19.625 6.875 19.625C8.34085 19.625 9.72849 19.4149 10.9339 19.0385C11.21 18.9523 11.3481 18.9092 11.407 18.8182C11.4659 18.7273 11.4392 18.5031 11.3856 18.0547C11.3786 17.9958 11.375 17.9358 11.375 17.875C11.375 14.8897 13.8256 12.5467 16.7527 12.5467H16.7583C16.9112 12.5467 16.9876 12.5467 17.0482 12.5183C17.1087 12.4899 17.1673 12.4194 17.2845 12.2784C17.3118 12.2456 17.34 12.2134 17.3691 12.1817C18.3043 11.1659 19.8859 11.1006 20.9016 12.0358C20.9095 12.0431 20.9173 12.0504 20.925 12.0578L22.0626 13.1511C22.1175 13.2038 22.1682 13.2606 22.2144 13.3209L22.2176 13.3251C22.3196 13.458 22.625 13.3856 22.625 13.2181V4.98897C22.625 4.19212 22.5178 3.40953 22.0968 2.76245C21.6575 2.08717 20.9595 1.68137 20.0424 1.47133C19.0655 1.24757 17.9948 1.125 16.875 1.125ZM14.875 10.375C14.875 12.0319 13.5319 13.375 11.875 13.375C10.2181 13.375 8.875 12.0319 8.875 10.375C8.875 8.71815 10.2181 7.375 11.875 7.375C13.5319 7.375 14.875 8.71815 14.875 10.375ZM6.375 11.375C6.375 10.8227 5.92728 10.375 5.375 10.375C4.82272 10.375 4.375 10.8227 4.375 11.375V11.384C4.375 11.9363 4.82272 12.384 5.375 12.384C5.92728 12.384 6.375 11.9363 6.375 11.384V11.375ZM18.375 8.36719C18.9273 8.36719 19.375 8.8149 19.375 9.36719V9.37617C19.375 9.92845 18.9273 10.3762 18.375 10.3762C17.8227 10.3762 17.375 9.92845 17.375 9.37617V9.36719C17.375 8.8149 17.8227 8.36719 18.375 8.36719Z"
        fill="currentColor"
      />
      <path
        d="M18.4727 13.1977C18.8467 12.7914 19.4794 12.7652 19.8857 13.1393L21.0233 14.2326C21.0918 14.3221 21.4349 14.7798 21.1903 15.3603C20.9494 15.9318 20.3924 16.0128 20.2838 16.0278C20.1439 16.0473 19.9815 16.047 19.883 16.0468L16.7527 16.0467C15.6993 16.0467 14.875 16.8815 14.875 17.875C14.875 18.4273 14.4273 18.875 13.875 18.875C13.3227 18.875 12.875 18.4273 12.875 17.875C12.875 15.7444 14.6275 14.0467 16.7527 14.0467H18.2231C18.1718 13.751 18.2536 13.4356 18.4727 13.1977Z"
        fill="currentColor"
      />
      <path
        d="M21.875 16.875C22.4273 16.875 22.875 17.3227 22.875 17.875C22.875 20.0056 21.1225 21.7033 18.9973 21.7033H17.5269C17.5782 21.999 17.4964 22.3145 17.2773 22.5523C16.9033 22.9586 16.2706 22.9848 15.8643 22.6107L14.7267 21.5174C14.6582 21.4279 14.3151 20.9702 14.5597 20.3897C14.8006 19.8182 15.3576 19.7373 15.4662 19.7222C15.606 19.7027 15.7685 19.7031 15.867 19.7033H18.9973C20.0507 19.7033 20.875 18.8685 20.875 17.875C20.875 17.3227 21.3227 16.875 21.875 16.875Z"
        fill="currentColor"
      />
    </svg>
  );
}
