export * from './clock-svg';
export * from './google-logo';
export * from './moniepoint-logo';
export * from './remittance-svg';
export * from './driving-license-icon';
export * from './resident-permit-icon';
export * from './skip-icon';
export * from './passport-icon';
export * from './statement-icon';
export * from './invite-illustration';
export * from './green-tick-icon';
export * from './add-recipient-success-illustration';
export * from './magnifier-illustration';
export * from './empty-recipient-illustration';
export * from './support-page-illustration';
export * from './danger-round';
export * from './trash-icon';
export * from './safety-shield-icon';
export * from './flash-icon';
