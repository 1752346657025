import { SVGProps } from 'react';

export const PassportIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2527 4.7417C18.0862 4.74691 17.908 4.75249 17.7092 4.75249L11.7002 4.75249C11.1479 4.75249 10.7002 4.30477 10.7002 3.75249C10.7002 3.2002 11.1479 2.75249 11.7002 2.75249L17.7092 2.75249C17.7898 2.75249 17.8959 2.7493 18.019 2.74561C18.4003 2.73417 18.9457 2.7178 19.4107 2.77559C20.0686 2.85736 20.9437 3.11601 21.453 3.98451C21.7624 4.51208 21.781 5.07425 21.7252 5.58959C21.6728 6.07432 21.5352 6.66246 21.3789 7.33011C21.3745 7.34908 21.37 7.36812 21.3656 7.38722L19.4342 15.6436C19.1433 16.8872 18.9047 17.9071 18.6141 18.7055C18.3096 19.5418 17.9121 20.2426 17.2396 20.7783C16.5666 21.3145 15.7957 21.5439 14.9145 21.6507C14.0743 21.7525 13.0318 21.7525 11.7624 21.7525H11.7002C11.1479 21.7525 10.7002 21.3048 10.7002 20.7525C10.7002 20.2002 11.1479 19.7525 11.7002 19.7525C13.0473 19.7525 13.968 19.7508 14.6738 19.6652C15.3522 19.583 15.7172 19.4341 15.9935 19.214C16.2703 18.9935 16.499 18.6688 16.7347 18.0213C16.9796 17.3487 17.1924 16.4465 17.5008 15.128L19.4181 6.93167C19.5917 6.18958 19.6992 5.72246 19.7368 5.37453C19.7545 5.21071 19.7514 5.11399 19.7439 5.05804C19.7377 5.01251 19.7296 4.99915 19.728 4.99654C19.7279 4.99643 19.728 4.99662 19.728 4.99654C19.6845 4.92243 19.5786 4.81184 19.164 4.76032C18.861 4.72266 18.5795 4.73147 18.2527 4.7417Z"
        fill="#08080C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 7.775C0.25 4.22768 0.25 2.45402 1.27513 1.35201C2.30025 0.25 3.95017 0.25 7.25 0.25L10.25 0.25C13.5498 0.25 15.1997 0.25 16.2249 1.35201C17.25 2.45402 17.25 4.22768 17.25 7.775V14.225C17.25 17.7723 17.25 19.546 16.2249 20.648C15.1997 21.75 13.5498 21.75 10.25 21.75H10.25L7.25 21.75H7.24998C3.95016 21.75 2.30025 21.75 1.27513 20.648C0.25 19.546 0.25 17.7723 0.25 14.225L0.25 7.775ZM6.83431 6.37434C6.02544 6.9655 5.5 7.92139 5.5 9C5.5 10.0786 6.02544 11.0345 6.83431 11.6257C6.61938 10.8724 6.5 9.96236 6.5 9C6.5 8.03764 6.61938 7.1276 6.83431 6.37434ZM8.75 4.25C6.12665 4.25 4 6.37665 4 9C4 11.6234 6.12665 13.75 8.75 13.75C11.3734 13.75 13.5 11.6234 13.5 9C13.5 6.37665 11.3734 4.25 8.75 4.25ZM8.75 5.78744C8.74306 5.79456 8.7355 5.80267 8.72733 5.81189C8.6304 5.92121 8.50886 6.12219 8.39159 6.43492C8.15912 7.05483 8 7.96156 8 9C8 10.0384 8.15912 10.9452 8.39159 11.5651C8.50886 11.8778 8.6304 12.0788 8.72733 12.1881C8.7355 12.1973 8.74306 12.2054 8.75 12.2126C8.75694 12.2054 8.7645 12.1973 8.77267 12.1881C8.8696 12.0788 8.99114 11.8778 9.10841 11.5651C9.34088 10.9452 9.5 10.0384 9.5 9C9.5 7.96156 9.34088 7.05483 9.10841 6.43492C8.99114 6.12219 8.8696 5.92121 8.77267 5.81189C8.7645 5.80267 8.75694 5.79456 8.75 5.78744ZM10.6657 11.6257C10.8806 10.8724 11 9.96236 11 9C11 8.03764 10.8806 7.1276 10.6657 6.37434C11.4746 6.9655 12 7.92139 12 9C12 10.0786 11.4746 11.0345 10.6657 11.6257ZM5.25 16.25C4.83579 16.25 4.5 16.5858 4.5 17C4.5 17.4142 4.83579 17.75 5.25 17.75L12.25 17.75C12.6642 17.75 13 17.4142 13 17C13 16.5858 12.6642 16.25 12.25 16.25L5.25 16.25Z"
        fill="#08080C"
      />
    </svg>
  );
};
