import { SVGProps } from 'react';

function MagnifierIllustration(props: Readonly<SVGProps<SVGSVGElement>>) {
  return (
    <svg
      width="164"
      height="104"
      viewBox="0 0 164 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_472_12168)">
        <path
          d="M153.62 77.0985C161.247 79.2479 157.433 90.4799 150.015 87.5679L101.412 69.5412L104.116 61.7065L153.62 77.0985Z"
          fill="#AEDFEF"
        />
        <path
          d="M107.653 62.8159C107.653 62.8159 109.04 65.9359 108.624 72.2452L101.344 69.5412L104.048 61.7065L107.653 62.8159Z"
          fill="#AEDFEF"
        />
        <path
          d="M153.62 77.0985C161.247 79.2479 157.433 90.4799 150.015 87.5679L101.412 69.5412L104.116 61.7065L153.62 77.0985Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M107.653 62.8159C107.653 62.8159 109.04 65.9359 108.624 72.2452L101.344 69.5412L104.048 61.7065L107.653 62.8159Z"
          fill="#010205"
        />
        <path
          d="M141.418 81.5359L126.234 75.9199"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M106.874 56.5629C111.948 35.0984 98.6609 13.5847 77.1964 8.51074C55.732 3.43675 34.2183 16.7239 29.1443 38.1883C24.0703 59.6528 37.3574 81.1665 58.8219 86.2405C80.2864 91.3145 101.8 78.0274 106.874 56.5629Z"
          fill="#AEDFEF"
        />
        <path
          d="M104.187 47.3546C104.187 67.3226 87.9627 83.5466 67.9947 83.5466C48.0267 83.5466 31.8027 67.3226 31.8027 47.3546C31.8027 27.3866 47.9574 11.1626 67.9947 11.1626C88.0321 11.1626 104.187 27.3866 104.187 47.3546Z"
          fill="white"
        />
        <path
          d="M106.874 56.5629C111.948 35.0984 98.6609 13.5847 77.1964 8.51074C55.732 3.43675 34.2183 16.7239 29.1443 38.1883C24.0703 59.6528 37.3574 81.1665 58.8219 86.2405C80.2864 91.3145 101.8 78.0274 106.874 56.5629Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.2394 49.504C35.1994 30.9226 51.2154 14.4906 70.1434 15.7386"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M104.187 47.3546C104.187 67.3226 87.9627 83.5466 67.9947 83.5466C48.0267 83.5466 31.8027 67.3226 31.8027 47.3546C31.8027 27.3866 47.9574 11.1626 67.9947 11.1626C88.0321 11.1626 104.187 27.3866 104.187 47.3546Z"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.7207 43.5414L88.726 39.52"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.5996 51.9305L94.2023 47.0771"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.5312 59.9041L85.3286 55.8828"
          stroke="#010205"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_472_12168">
          <rect
            width="163.211"
            height="104"
            fill="white"
            transform="translate(0.394531)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MagnifierIllustration;
