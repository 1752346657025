import { SVGProps } from 'react';

export function FlashIcon(props: Readonly<SVGProps<SVGSVGElement>>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.3464 1.93762C13.7838 2.0029 14.2599 2.16083 14.5329 2.63365C14.8052 3.10531 14.7057 3.59681 14.5456 4.00947C14.3916 4.40659 14.1185 4.88694 13.8004 5.44638L12.8829 7.06044C12.7094 7.36567 12.5977 7.56314 12.5265 7.71448C12.4731 7.82805 12.4634 7.87459 12.4618 7.88229C12.4644 7.97484 12.5134 8.05839 12.5894 8.1055C12.5977 8.10815 12.6428 8.12208 12.7637 8.13152C12.9293 8.14447 13.1805 8.14492 13.5304 8.14492C13.9377 8.14491 14.2794 8.1449 14.5459 8.16532C14.8037 8.18505 15.1072 8.22886 15.3616 8.398C15.8557 8.72633 16.1126 9.31242 16.0224 9.89775C15.9761 10.1988 15.8052 10.4528 15.6464 10.6578C15.4823 10.8696 15.2525 11.1237 14.9784 11.4268L10.3187 16.5793C9.89128 17.0521 9.53145 17.45 9.2387 17.7053C9.08978 17.835 8.91395 17.9684 8.7177 18.048C8.50162 18.1358 8.21642 18.173 7.93317 18.0254C7.65056 17.8782 7.51776 17.6238 7.46493 17.3978C7.41682 17.192 7.4238 16.9716 7.44349 16.7747C7.48222 16.3873 7.59825 15.8625 7.73619 15.2387L8.31807 12.6064C8.43353 12.0843 8.50395 11.7586 8.52195 11.5202C8.5467 11.3237 8.37528 11.2393 8.28644 11.2218C8.05185 11.1878 7.7211 11.1863 7.18873 11.1863H6.75868C6.18127 11.1863 5.67864 11.1864 5.29024 11.1286C4.87934 11.0673 4.43946 10.9203 4.16321 10.493C3.88761 10.0668 3.93263 9.60517 4.04342 9.2045C4.14839 8.82492 4.35234 8.36367 4.58707 7.83287L6.12896 4.3454C6.3461 3.85424 6.52868 3.44122 6.71491 3.11785C6.91227 2.77514 7.14029 2.48372 7.47342 2.26609C7.80678 2.04832 8.16496 1.95696 8.55753 1.91475C8.92753 1.87498 9.37753 1.87499 9.91187 1.875H11.735C12.3755 1.87496 12.9264 1.87493 13.3464 1.93762Z"
        fill="#246DF0"
      />
    </svg>
  );
}
