import { SVGProps } from 'react';

export const DrivingLicenseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM12 9.25C11.6295 9.25 10.5641 9.26325 8.57523 10.0153C7.55851 10.3997 6.36632 10.1406 5.46315 9.76002C5.25859 9.67383 5.02637 9.68268 4.82897 9.78419C4.63157 9.88569 4.48927 10.0694 4.44036 10.2859C4.31567 10.838 4.25 11.4118 4.25 12C4.25 12.4821 4.29412 12.9546 4.37873 13.4135C4.43436 13.7151 4.66807 13.9525 4.96882 14.0128C5.97308 14.2142 7.13598 14.5922 8.0553 15.3306C8.94576 16.0458 9.6488 17.1293 9.72164 18.864C9.73589 19.2033 9.97638 19.4907 10.3079 19.5646C10.8532 19.6861 11.4196 19.75 12 19.75C12.5804 19.75 13.1468 19.6861 13.6921 19.5646C14.0236 19.4907 14.2641 19.2033 14.2784 18.864C14.3512 17.1293 15.0542 16.0458 15.9447 15.3306C16.864 14.5922 18.0269 14.2142 19.0312 14.0128C19.3319 13.9525 19.5656 13.7151 19.6213 13.4135C19.7059 12.9546 19.75 12.4821 19.75 12C19.75 11.4118 19.6843 10.838 19.5596 10.2859C19.5107 10.0694 19.3684 9.88569 19.171 9.78419C18.9736 9.68268 18.7414 9.67383 18.5369 9.76002C17.6337 10.1406 16.4415 10.3997 15.4248 10.0153C13.4359 9.26325 12.3705 9.25 12 9.25Z"
        fill="#08080C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5078 13C13.5078 13.8284 12.8382 14.5 12.0123 14.5L12.0033 14.5C11.1774 14.5 10.5078 13.8284 10.5078 13C10.5078 12.1716 11.1774 11.5 12.0033 11.5L12.0123 11.5C12.8382 11.5 13.5078 12.1716 13.5078 13Z"
        fill="#08080C"
      />
    </svg>
  );
};
