export function MoniepointLogo(props: Readonly<React.SVGProps<SVGSVGElement>>) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        d="M15.9464 32C12.6964 31.9916 9.74609 31.6598 7.43464 31.1715C6.27917 30.9273 5.27969 30.6433 4.4806 30.3392C3.68841 30.0378 3.07214 29.7091 2.70026 29.3651C2.23021 28.9442 1.83802 28.2124 1.50964 27.3074C1.17786 26.3935 0.900391 25.2712 0.677474 24.0387C0.23151 21.573 0 18.6418 0 16.0017C0 12.8965 0.283984 9.91633 0.757943 7.53953C0.994792 6.35139 1.28021 5.30881 1.60378 4.4746C1.92435 3.64854 2.29258 2.99922 2.71016 2.6249C3.07982 2.28329 3.6944 1.95638 4.4849 1.65643C5.28255 1.35382 6.28099 1.07075 7.43555 0.827247C9.74518 0.340172 12.6947 0.00837295 15.9436 0H16.0536C19.3036 0.00837295 22.254 0.340172 24.5654 0.828528C25.7208 1.07266 26.7203 1.35668 27.5194 1.66077C28.3116 1.96216 28.9279 2.29094 29.2999 2.63492C29.7151 3.00694 30.0815 3.65461 30.4004 4.479C30.7227 5.3118 31.007 6.35343 31.2434 7.54087C31.716 9.91636 32 12.8965 32 16.0017C32 19.1067 31.7168 22.0811 31.2447 24.4537C31.0086 25.6397 30.7242 26.6806 30.4018 27.5144C30.0826 28.3405 29.7159 28.9903 29.3007 29.3676C28.928 29.7152 28.3113 30.0467 27.5189 30.3499C26.7198 30.6557 25.7207 30.9404 24.5658 31.1844C22.2553 31.6724 19.3065 32 16.0564 32H15.9464Z"
        fill="currentColor"
      />
      <path
        d="M22.8133 16.5829L22.2499 9.8667H18.8464C18.8464 9.8667 16.2553 15.038 16.026 15.7414H15.99L13.258 9.8667H9.8382L9.27478 15.69L8.7998 21.6H10.8799C11.7774 21.6 12.1312 21.221 12.2033 20.296C12.2033 20.296 12.557 14.8838 12.5014 14.0937H12.5374L12.6422 14.4534C12.7831 14.9352 14.7223 19.5572 14.7223 19.5572H17.2773C17.2773 19.5572 19.2165 15.0059 19.4622 14.0937H19.4983L19.7963 20.296C19.8684 21.2242 20.2386 21.6 21.1197 21.6H23.1998L22.8133 16.5829Z"
        fill="white"
      />
    </svg>
  );
}
