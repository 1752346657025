import { SVGProps } from 'react';

export const TrashIcon = (props: Readonly<SVGProps<SVGSVGElement>>) => {
  return (
    <svg
      width="88"
      height="115"
      viewBox="0 0 88 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.62109 106.793C1.62109 106.793 21.65 112.182 75.2698 113.349C76.168 107.691 40.5112 97.1826 40.5112 97.1826L1.62109 106.703V106.793Z"
        fill="#010205"
      />
      <path
        d="M1.62109 106.793C1.62109 106.793 21.65 112.182 75.2698 113.349C76.168 107.691 40.5112 97.1826 40.5112 97.1826L1.62109 106.703V106.793Z"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.53125 25.2408L28.7454 34.402L86.3171 33.5936L58.9234 23.8936L1.53125 25.2408Z"
        fill="#010205"
      />
      <path
        d="M56.5879 1.34961L77.6047 11.0497C79.7603 14.2831 80.6585 18.5942 80.8381 27.0369L57.3962 19.4924L56.5879 1.34961Z"
        fill="#D9DCE3"
      />
      <path
        d="M6.65036 20.3905C7.99759 12.846 9.88372 6.55894 12.9374 2.24779L56.5878 1.43945C58.2045 4.76263 58.0249 11.7682 57.486 19.4924L6.56055 20.3905H6.65036Z"
        fill="#D9DCE3"
      />
      <path
        d="M57.5762 5.57129C57.9354 8.35557 57.9354 11.7686 57.666 15.5408"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.672 15.0016L16.1708 15.5405C13.9254 15.5405 12.3087 13.834 12.5782 11.6784H12.9374C12.8476 13.5645 14.2847 15.1812 16.1708 15.1812L49.672 14.6423C51.6479 14.6423 53.4442 13.0256 53.5341 11.0497L53.8035 7.54688H54.1628L53.8933 11.0497C53.8035 13.2053 51.7378 14.9118 49.5822 15.0914L49.672 15.0016Z"
        fill="#D9DCE3"
      />
      <path
        d="M12.9374 11.5883H12.5781L12.8476 8.08547C12.9374 5.9299 15.0031 4.22341 17.2485 4.04378L50.7497 3.50488C52.9951 3.50488 54.6118 5.21138 54.3424 7.36695H53.9831C54.0729 5.48082 52.6359 3.86414 50.7497 3.86414L17.2485 4.40304C15.2726 4.40304 13.4763 6.01972 13.3865 7.99566L13.0272 11.4985L12.9374 11.5883Z"
        fill="#D9DCE3"
      />
      <path
        d="M16.9795 6.11018C15.9017 6.11018 15.0035 7.00834 14.8239 7.99631L14.5544 11.5889C14.4646 12.6667 15.1831 13.4751 16.2609 13.4751L49.7621 12.8464C50.8399 12.8464 51.7381 11.9482 51.9177 10.9602L52.1871 7.45742C52.277 6.37963 51.5584 5.57129 50.4807 5.57129L16.9795 6.11018Z"
        fill="#010205"
      />
      <path
        d="M86.2266 33.593L84.9692 27.0364L59.0125 18.5938L2.33888 19.6715C1.17128 19.6715 0.542578 25.3299 1.53055 25.3299L58.3838 24.2521L86.3164 33.6828L86.2266 33.593Z"
        fill="#D9DCE3"
      />
      <path
        d="M84.6092 33.1439L82.9925 46.8856L75.1786 113.349L49.8506 108.769L1.52982 106.793C1.44 79.5785 2.06871 53.1727 3.77521 27.9346C3.77521 27.1262 3.86502 26.4975 3.86502 25.6892L58.2932 24.791L84.5194 33.2337L84.6092 33.1439Z"
        fill="white"
      />
      <path
        d="M84.6092 33.1439L82.9925 46.8856L75.1786 113.349L49.8506 108.769L1.52982 106.793C1.44 79.5785 2.06871 53.1727 3.77521 27.9346C3.77521 27.1262 3.86502 26.4975 3.86502 25.6892L58.2932 24.791L84.5194 33.2337L84.6092 33.1439Z"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.6222 47.1551C81.7361 69.8784 79.0416 91.973 75.2694 113.349L49.9414 108.769C52.9053 83.71 55.4202 58.8311 57.7554 30.9883C57.8452 28.8327 58.1146 26.8567 58.2943 24.7012L84.5204 33.1438C84.2509 37.8142 83.9815 42.5745 83.6222 47.1551Z"
        fill="#D9DCE3"
      />
      <path
        d="M58.2943 24.7012L84.5204 33.1438C84.2509 37.8142 83.9815 42.5745 83.6222 47.1551C81.7361 69.8784 79.0416 91.973 75.2694 113.349L49.9414 108.769"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.75 101.943C53.2648 80.2972 55.4204 58.472 57.4862 34.5811"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.6214 47.1551L83.0825 46.7959L57.7545 31.0781L57.4851 30.8087L3.77539 28.0244C3.77539 27.2161 3.8652 26.5874 3.8652 25.779L58.2934 24.8809L84.5195 33.3235C84.2501 37.9939 83.9807 42.7542 83.6214 47.3348V47.1551Z"
        fill="#010205"
      />
      <path
        d="M59.0115 18.5039L58.3828 24.1623L86.3154 33.5929C87.483 33.5929 86.8543 27.5753 86.046 27.3058L59.1013 18.5039H59.0115Z"
        fill="#D9DCE3"
      />
      <path
        d="M37.1866 46.7967L33.6838 43.0244L26.3189 50.3893L20.3013 43.0244L16.4392 46.5272L22.6365 54.1615L15.6309 61.257L18.8642 64.5801L25.6902 57.844L31.4384 65.119L35.4801 61.8857L29.4625 54.2514L37.2764 46.7069L37.1866 46.7967Z"
        fill="#D9DCE3"
      />
      <path
        d="M6.74023 19.4919L59.0129 18.5039L81.6464 25.8688"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.5689 22.1868C80.2096 16.7979 79.2216 13.6543 77.5151 11.0497L56.4983 1.34961L12.8479 2.15795C10.5127 5.48112 8.80625 9.88209 7.54883 15.1812"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.33888 19.582C1.17128 19.582 0.542578 25.2404 1.53055 25.2404L58.3838 24.1626L86.3164 33.5933C87.484 33.5933 86.8553 27.5756 86.047 27.3062"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
