import React, { SVGProps } from 'react';

function AddRecipientSuccessIllustration(
  props: Readonly<SVGProps<SVGSVGElement>>,
) {
  return (
    <svg
      width="300"
      height="150"
      viewBox="0 0 300 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M227.242 26.6664C227.242 26.6664 202.99 32.1664 165.698 19.9164C124.004 6.24975 89.9512 20.0831 89.9512 20.0831L100.499 94.0831C100.499 94.0831 131.313 81.2498 172.01 96.0831C218.604 113 238.039 102.833 238.039 102.833L227.159 26.6664H227.242Z"
        fill="white"
      />
      <path
        d="M216.03 105.75C231.395 106.417 238.289 102.833 238.289 102.833L227.408 26.6666C227.408 26.6666 203.156 32.1666 165.864 19.9166C139.785 11.3333 116.695 13.5833 103.24 16.4166"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.656 39.2501C104.072 41.9167 107.477 65.2501 107.726 67.2501C113.955 66.0001 120.433 71.1667 120.849 77.8334C143.274 71.3334 194.768 93.6667 213.622 94.0834C212.376 87.3334 217.525 80.8334 224.336 80.9167L220.266 48.1667C214.037 49.0001 208.555 44.3334 208.057 36.7501C166.363 34.4167 151.662 19.6667 112.709 25.3334C113.955 32.0834 110.716 37.9167 103.739 39.0001V39.2501H103.656Z"
        fill="#72D399"
      />
      <path
        d="M213.621 94.0834C212.375 87.3334 217.524 80.8334 224.335 80.9167L220.265 48.1667C214.036 49.0001 208.554 44.3334 208.056 36.7501C166.362 34.4167 151.661 19.6667 112.708 25.3334C113.954 32.0834 110.715 37.9167 103.738 39.0001"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.0352 20L92.5268 37.75"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M199.834 40.5833C118.605 29.0833 61.2969 56 61.2969 56L73.6722 134.917C73.6722 134.917 133.057 118.083 212.707 122.333L200 40.4166H199.917L199.834 40.5833Z"
        fill="white"
      />
      <path
        d="M211.212 113.75L212.541 122.5C132.973 118.083 73.5887 135 73.5887 135L63.7051 71.5"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M191.446 54.8333L198.09 103C194.602 105.083 192.609 108.667 192.276 112.583C147.925 111.75 112.045 117.417 94.2708 120.75C94.1047 119.833 93.8556 118.75 93.3572 117.75C91.1978 112.583 85.6331 109.917 80.3175 111.167L75.002 73.9999C76.7461 73.9999 78.6564 73.6666 80.4836 72.9999C86.4636 70.3333 89.1214 63.4166 86.4636 57.4166C86.3806 57.3333 86.3806 57.0833 86.2145 56.8333C97.6761 53.4166 145.516 42.3333 180.566 46.3333C181.728 50.9999 185.3 55.2499 191.446 54.9166V54.8333Z"
        fill="#72D399"
      />
      <path
        d="M209.469 102.583L199.918 40.75C118.689 29.25 61.3809 56.1667 61.3809 56.1667"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M191.446 54.8333L198.09 103C194.602 105.083 192.609 108.667 192.276 112.583C147.925 111.75 112.045 117.417 94.2708 120.75C94.1047 119.833 93.8556 118.75 93.3572 117.75C91.1978 112.583 85.6331 109.917 80.3175 111.167L75.002 73.9999C76.7461 73.9999 78.6564 73.6666 80.4836 72.9999C86.4636 70.3333 89.1214 63.4166 86.4636 57.4166C86.3806 57.3333 86.3806 57.0833 86.2145 56.8333C97.6761 53.4166 145.516 42.3333 180.566 46.3333C181.728 50.9999 185.3 55.2499 191.446 54.9166V54.8333Z"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.632 65.7499C123.755 67.5832 113.705 78.7499 120.848 95.3332C127.991 111.917 158.306 108.25 159.884 88.5832C161.213 72.9165 152.492 63.1665 135.549 65.6665L135.632 65.7499Z"
        fill="white"
      />
      <path
        d="M133.555 104.833C133.555 104 133.555 103 133.389 101.916C133.14 99.9998 132.393 97.7498 132.393 97.7498L145.848 95.4998C145.848 95.4998 145.765 98.9998 145.848 100.416C145.931 101.833 146.097 103.083 146.263 104.166C141.861 105.833 137.625 106.333 133.472 104.916L133.555 104.833Z"
        fill="#9D5B3A"
      />
      <path
        d="M145.848 102.333C145.848 103 146.014 103.666 146.014 104.333"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.632 65.7499C123.755 67.5832 113.705 78.7499 120.848 95.3332C127.991 111.917 158.306 108.25 159.884 88.5832C161.213 72.9165 152.492 63.1665 135.549 65.6665L135.632 65.7499Z"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.923 89.25C148.757 93.9167 146.763 102.167 138.956 102.75C131.564 103.25 128.076 96.4167 126.913 91.6667C126.913 91.6667 123.757 93.9167 123.674 90.3333C123.674 89.5833 123.674 88.8333 123.674 88.0833C123.923 85.4167 124.754 83 125.75 80.75C126.83 78.3333 132.062 73.6667 138.125 71.25C147.012 74.4167 149.753 80.9167 150.999 85C151.165 85.5 151.248 86 151.414 86.4167C152.411 90.1667 148.923 89.25 148.923 89.25Z"
        fill="#9D5B3A"
      />
      <path
        d="M156.563 72.0832C156.563 75.4166 153.656 74.3332 153.656 74.3332C153.656 76.5832 151.081 76.9166 151.081 76.9166C152.493 80.7499 151.081 84.9166 151.081 85.0832C149.835 80.9999 147.095 74.4999 138.208 71.3332C132.145 73.7499 126.912 78.4166 125.832 80.8332C124.836 82.9999 124.005 85.4999 123.756 88.1666C123.756 88.1666 120.932 85.6666 122.178 80.5832C122.178 80.5832 119.022 79.4166 119.52 75.4999C119.603 74.8332 119.935 73.9166 120.434 72.9166C120.434 72.9166 117.942 64.0832 122.51 58.3332C124.919 55.2499 129.321 51.5832 134.885 52.4166C134.885 52.4166 135.799 49.9166 137.792 50.3332C137.792 50.3332 147.593 52.3332 147.427 60.0832C152.659 61.6666 156.563 68.7499 156.48 72.1666L156.563 72.0832Z"
        fill="#F28D8D"
      />
      <path
        d="M126.912 91.6667C126.912 91.6667 123.756 93.9167 123.673 90.3333C123.59 86.75 124.586 83.5833 125.832 80.8333C127.078 78.0833 133.001 72 141.501 70"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149 89.5C149 89.5 152.492 90.1667 151.412 86.4167C150.332 82.6667 148.09 74.8333 138.123 71.25"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M126.246 87.5833C126.246 87.5833 126.412 89.3333 126.994 91.6667C128.156 96.4167 131.645 103.25 139.037 102.75C146.844 102.25 148.754 93.9167 149.003 89.25C149 86.5 149.003 86 149.003 86"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.732 72.0833C130.732 72.0833 126.663 77.7499 125.832 80.8333"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M127.576 81.4998C127.576 81.4998 129.237 78.2498 133.058 78.1665"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.453 77.3333C139.453 77.3333 144.187 77.4166 145.931 79.3332"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M128.074 84.1666C128.074 84.1666 129.735 82.3332 133.556 82.8332"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.014 82.2498C146.014 82.2498 144.021 80.7498 140.449 81.9998"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.208 85.3332C138.208 85.3332 134.221 85.0832 134.304 87.4166C134.387 89.7499 138.125 89.0832 138.125 89.0832"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.608 90.3333C142.608 90.3333 142.11 94.4999 138.622 95.2499C135.133 95.9999 133.971 91.4999 133.971 91.4999C135.881 91.4999 139.951 91.3333 142.608 90.3333Z"
        fill="white"
      />
      <path
        d="M132.975 91.4999C132.975 91.4999 133.39 91.4999 133.971 91.4999M133.971 91.4999C135.882 91.4999 139.951 91.3333 142.609 90.3333C142.609 90.3333 142.111 94.4999 138.622 95.2499C135.134 95.9999 133.971 91.4999 133.971 91.4999Z"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.795 97.9166C136.795 97.9166 139.287 98.4166 141.695 96.8333"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.662 81.25C151.662 81.25 151.496 78 150.998 76.8333C150.998 76.8333 153.573 76.5 153.573 74.25C153.573 74.25 156.48 75.4167 156.48 72C156.48 68.6667 152.576 61.5 147.344 59.9167C142.111 58.3333 132.311 59.1667 132.311 59.1667"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.24 72.1665C153.24 72.1665 153.24 69.5832 152.16 67.9165"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150.499 74.8332C150.499 74.8332 150.499 69.3332 147.26 66.4165"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.708 65C137.708 65 126.33 66 123.672 77.1667"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.094 84.3333C122.094 84.3333 121.762 82.5 122.094 80.5C122.094 80.5 118.938 79.3333 119.436 75.4167C119.935 71.5 126.828 61.8333 140.865 61.5"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.43 67.9165C121.43 67.9165 122.177 64.9998 125.001 62.9165"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.436 70.6667C119.436 70.6667 117.941 64 122.426 58.25C126.911 52.5 132.144 49.1667 140.283 54.5"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.758 61.4165C122.758 61.4165 128.987 54.2498 137.708 56.3332"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.791 50.3333C137.791 50.3333 147.666 51.8332 147.5 59.4999"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M252.905 107.917C252.822 109.083 251.66 116.833 261.377 116.917C261.377 116.917 253.321 117.083 253.321 127C253.321 127 253.321 117.917 244.102 117.917C249.666 117.5 252.324 113.5 252.905 108V107.917Z"
        fill="white"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.3463 119.167C46.3463 119.167 44.8513 126.5 53.0738 126.75C53.0738 126.75 46.3463 126.833 46.3463 135.333C46.3463 135.333 46.3463 127.5 38.5391 127.5C38.5391 127.5 45.6818 127.333 46.3463 119V119.167Z"
        fill="white"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.1598 10.25C52.1598 10.25 49.419 24.3333 65.1165 24.8333C65.1165 24.8333 52.1598 25 52.1598 41.25C52.1598 41.25 52.1598 26.5 37.459 26.5C37.459 26.5 51.1632 26.1667 52.1598 10.3333V10.25Z"
        fill="white"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AddRecipientSuccessIllustration;
