import { SVGProps } from 'react';

export const ResidentPermitIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.75 12.0537V12.0537V12.0538C22.75 14.0117 22.75 15.5532 22.5976 16.7576C22.4417 17.9901 22.1147 18.9829 21.3746 19.7692C20.6284 20.562 19.6762 20.9182 18.4951 21.0869C17.3529 21.25 15.8946 21.25 14.0595 21.25H9.94047C8.10537 21.25 6.6471 21.25 5.50485 21.0869C4.32381 20.9182 3.37155 20.562 2.62542 19.7692C1.88534 18.9829 1.55834 17.9901 1.40238 16.7576C1.24998 15.5532 1.24999 14.0116 1.25 12.0537V11.9463C1.24999 9.98836 1.24998 8.44685 1.40238 7.24244C1.55834 6.0099 1.88535 5.01711 2.62542 4.23077C3.37155 3.43801 4.32381 3.08185 5.50485 2.91314C6.64711 2.74997 8.10538 2.74998 9.94049 2.75H9.9405H14.0595H14.0595C15.8946 2.74998 17.3529 2.74997 18.4951 2.91314C19.6762 3.08185 20.6284 3.43801 21.3746 4.23077C22.1147 5.01711 22.4417 6.0099 22.5976 7.24244C22.75 8.44682 22.75 9.98829 22.75 11.9462V11.9462V11.9463V12.0537ZM10.75 9.22222C10.75 10.4495 9.74264 11.4444 8.5 11.4444C7.25736 11.4444 6.25 10.4495 6.25 9.22222C6.25 7.99492 7.25736 7 8.5 7C9.74264 7 10.75 7.99492 10.75 9.22222ZM5.78879 13.3787C5.71843 13.4252 5.63673 13.4766 5.54774 13.5325C4.74199 14.0391 3.33903 14.9212 4.35633 15.9981C4.90815 16.5822 5.52275 17 6.29543 17H10.7046C11.4773 17 12.0918 16.5822 12.6437 15.9981C13.661 14.9212 12.258 14.0391 11.4523 13.5325L11.4522 13.5325L11.452 13.5324L11.4519 13.5323C11.3631 13.4764 11.2815 13.4251 11.2112 13.3787C9.55241 12.2812 7.44759 12.2812 5.78879 13.3787ZM15 8.75002C14.5857 8.75002 14.25 9.0858 14.25 9.50002C14.25 9.91423 14.5857 10.25 15 10.25H19C19.4142 10.25 19.75 9.91423 19.75 9.50002C19.75 9.0858 19.4142 8.75002 19 8.75002H15ZM15 12.75C14.5857 12.75 14.25 13.0858 14.25 13.5C14.25 13.9142 14.5857 14.25 15 14.25H17C17.4142 14.25 17.75 13.9142 17.75 13.5C17.75 13.0858 17.4142 12.75 17 12.75H15Z"
        fill="#08080C"
      />
    </svg>
  );
};
