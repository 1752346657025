import { SVGProps } from 'react';

export const SkipIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM5.04816 6.60379C3.88979 8.09394 3.2 9.96642 3.2 12C3.2 16.8601 7.13989 20.8 12 20.8C14.0336 20.8 15.9061 20.1102 17.3962 18.9518L5.04816 6.60379ZM6.60379 5.04816L18.9518 17.3962C20.1102 15.9061 20.8 14.0336 20.8 12C20.8 7.13989 16.8601 3.2 12 3.2C9.96642 3.2 8.09394 3.88979 6.60379 5.04816Z"
        fill="#08080C"
      />
    </svg>
  );
};
