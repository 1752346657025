import { SVGProps, forwardRef } from 'react';

export const DangerRoundIcon = forwardRef(
  (props: SVGProps<SVGSVGElement>, ref: any) => {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          d="M9.00033 17.3334C4.39795 17.3334 0.666992 13.6024 0.666992 9.00008C0.666992 4.39771 4.39795 0.666748 9.00033 0.666748C13.6027 0.666748 17.3337 4.39771 17.3337 9.00008C17.3337 13.6024 13.6027 17.3334 9.00033 17.3334ZM9.00033 15.6667C12.6822 15.6667 15.667 12.682 15.667 9.00008C15.667 5.31818 12.6822 2.33341 9.00033 2.33341C5.31843 2.33341 2.33366 5.31818 2.33366 9.00008C2.33366 12.682 5.31843 15.6667 9.00033 15.6667ZM8.16699 11.5001H9.83366V13.1667H8.16699V11.5001ZM8.16699 4.83341H9.83366V9.83341H8.16699V4.83341Z"
          fill="currentColor"
        />
      </svg>
    );
  },
);

DangerRoundIcon.displayName = 'DangerRoundIcon';
