import React, { SVGProps } from 'react';

function EmptyRecipientIllustration(props: Readonly<SVGProps<SVGSVGElement>>) {
  return (
    <svg
      width="128"
      height="82"
      viewBox="0 0 128 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M115.846 3.35062C48.8199 -6.10712 1.53125 16.0295 1.53125 16.0295L11.7429 80.9315C11.7429 80.9315 60.7449 67.0875 126.469 70.5828L115.984 3.21355H115.915L115.846 3.35062Z"
        fill="white"
      />
      <path
        d="M125.236 63.5233L126.333 70.7194C60.6771 67.087 11.6751 80.9995 11.6751 80.9995L3.51953 28.7764"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108.925 15.0697L114.408 54.6826C111.529 56.3959 109.884 59.3429 109.61 62.564C73.013 61.8787 43.4062 66.539 28.7398 69.2804C28.6027 68.5265 28.3971 67.6355 27.9859 66.8131C26.204 62.564 21.6122 60.3709 17.226 61.3989L12.8398 30.8326C14.2791 30.8326 15.8554 30.5585 17.3631 30.0102C22.2976 27.8171 24.4907 22.1288 22.2976 17.1943C22.229 17.1258 22.229 16.9202 22.092 16.7145C31.5497 13.9046 71.0255 4.78958 99.947 8.07923C100.906 11.9171 103.853 15.4124 108.925 15.1383V15.0697Z"
        fill="#D9DCE3"
      />
      <path
        d="M123.796 54.3408L115.915 3.48831C48.8883 -5.96942 1.59961 16.1672 1.59961 16.1672"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108.925 15.0697L114.408 54.6826C111.529 56.3959 109.884 59.3429 109.61 62.564C73.013 61.8787 43.4062 66.539 28.7398 69.2804C28.6027 68.5265 28.3971 67.6355 27.9859 66.8131C26.204 62.564 21.6122 60.3709 17.226 61.3989L12.8398 30.8326C14.2791 30.8326 15.8554 30.5585 17.3631 30.0102C22.2976 27.8171 24.4907 22.1288 22.2976 17.1943C22.229 17.1258 22.229 16.9202 22.092 16.7145C31.5497 13.9046 71.0255 4.78958 99.947 8.07923C100.906 11.9171 103.853 15.4124 108.925 15.1383V15.0697Z"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.0055 24.0478C53.1366 25.5556 44.9125 34.7392 50.8064 48.5146C58.0025 65.2369 81.7839 59.2059 83.1546 42.9633C84.2512 30.0103 77.0551 21.9918 63.0055 24.1164V24.0478Z"
        fill="white"
      />
      <path
        d="M61.2915 57.9034C61.2915 57.218 61.2915 56.3956 61.1544 55.5047C60.9488 53.9284 60.332 52.078 60.332 52.078L71.5031 50.2275C71.5031 50.2275 71.4346 53.106 71.5031 54.2711C71.5717 55.4361 71.7087 56.4642 71.8458 57.3551C68.2135 58.7943 64.6497 59.137 61.2915 57.9719V57.9034Z"
        fill="white"
      />
      <path
        d="M71.5039 53.2432C71.5039 53.7914 71.641 55.3677 71.7095 55.8475"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.2917 56.5324C61.2917 55.9156 61.2232 55.2303 61.0176 54.5449"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.0055 24.0478C53.1366 25.5556 44.9125 34.7392 50.8064 48.5146C58.0025 65.237 81.7839 59.2059 83.1546 42.9633C84.2512 30.0103 77.0551 21.9918 63.0055 24.1164V24.0478Z"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.0397 43.4442C73.9026 47.2821 72.2578 54.1356 65.8156 54.5468C59.716 54.958 56.8376 49.3382 55.8781 45.4317C55.8781 45.4317 53.2738 47.2821 53.1367 44.3352C53.1367 43.7183 53.1367 43.1015 53.1367 42.4847C53.3423 40.2916 54.0277 38.2356 54.8501 36.4537C55.741 34.4662 60.1272 30.6283 65.1302 28.5723C72.4634 31.1766 74.725 36.5908 75.753 39.949C75.8901 40.3602 75.9586 40.7714 76.0957 41.114C76.9181 44.1981 74.0397 43.4442 74.0397 43.4442Z"
        fill="white"
      />
      <path
        d="M80.3448 29.2574C80.3448 31.9988 77.9461 31.1078 77.9461 31.1078C77.9461 32.9582 75.8216 33.2324 75.8216 33.2324C76.9866 36.385 75.8216 39.8117 75.8216 39.9487C74.7935 36.5906 72.5319 31.1763 65.1987 28.572C60.1957 30.6281 55.8095 34.466 54.9186 36.4535C54.0962 38.2354 53.4108 40.2914 53.2052 42.4845C53.2052 42.4845 50.8751 40.4285 51.9031 36.2479C51.9031 36.2479 49.2302 35.2884 49.71 32.0673C49.7785 31.519 50.0527 30.7651 50.4639 29.9427C50.4639 29.9427 48.4078 22.6781 52.1772 17.8807C54.1647 15.3449 57.8656 12.3294 62.4574 12.9462C62.4574 12.9462 63.2112 10.8902 64.8561 11.2329C64.8561 11.2329 72.9431 12.9462 72.806 19.2514C77.1237 20.5535 80.3448 26.4475 80.3448 29.1889V29.2574Z"
        fill="#D9DCE3"
      />
      <path
        d="M55.81 45.4307C55.81 45.4307 53.2057 47.2811 53.0687 44.3342C52.9316 41.3872 53.8225 38.7829 54.8506 36.4527C55.8786 34.1911 61.4298 29.4622 67.3238 27.8174"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.0403 43.4432C74.0403 43.4432 76.9873 44.2656 76.0964 41.1131C75.2739 38.029 73.355 31.5183 65.1309 28.5713"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.2637 42.0043C55.2637 42.0043 55.4007 43.4435 55.8805 45.3625C56.84 49.2689 59.7184 54.8888 65.818 54.4775C72.2602 54.0663 73.905 47.2129 74.0421 43.375C74.0421 41.7987 74.0421 40.7021 74.0421 40.7021"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.9634 29.2568C58.9634 29.2568 55.6053 33.9172 54.9199 36.4529"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.7715 39.0578C56.7715 39.0578 59.4443 38.0983 61.2948 36.3164"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.5703 35.5615C66.5703 35.5615 69.1746 36.8637 71.9845 37.2063"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.1816 41.2509C57.1816 41.2509 59.6489 41.4564 61.7049 40.1543"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.0529 39.6744C72.0529 39.6744 70.4081 40.7024 67.3926 39.4688"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.5425 42.2095C65.5425 42.2095 62.2528 42.0039 62.3214 43.9229C62.3899 45.8418 65.5425 45.2936 65.5425 45.2936"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.2402 49.4742C64.2402 49.4742 65.8165 49.6112 67.3928 49.2686"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.3025 36.8643C76.3025 36.8643 76.1654 34.1229 75.7542 33.232C75.7542 33.232 77.8788 32.9578 77.9473 31.1074C77.9473 31.1074 80.346 32.0669 80.346 29.257C80.346 26.5156 77.1249 20.6217 72.8072 19.3195C68.4896 18.0174 60.334 18.7027 60.334 18.7027"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.6038 29.3253C77.6038 29.3253 77.6038 27.1322 76.7129 25.8301"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.3428 31.5186C75.3428 31.5186 75.3428 26.9268 72.6699 24.5967"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.7893 23.4316C64.7893 23.4316 55.4001 24.2541 53.207 33.5062"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.8367 39.4005C51.8367 39.4005 51.4941 37.8927 51.8367 36.2479C51.8367 36.2479 49.2324 35.2884 49.6436 32.0673C50.0548 28.8462 55.8117 20.8962 67.394 20.6221"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.2871 25.8298C51.2871 25.8298 51.9039 23.3626 54.2341 21.7178"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.6427 28.0913C49.6427 28.0913 48.4091 22.6086 52.1099 17.8797C55.8108 13.1508 60.197 10.4094 66.9133 14.7271"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.3848 20.4851C52.3848 20.4851 57.5248 14.5912 64.7895 16.3045"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.8574 11.3008C64.8574 11.3008 72.9445 13.0141 72.8074 19.3193"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.2539 48.6519C62.2539 48.6519 63.6246 47.2127 65.4065 47.1441C67.1884 47.0756 68.4905 48.0351 68.4905 48.0351"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EmptyRecipientIllustration;
