import { SVGProps } from 'react';

export const SafetyShieldIcon = (props: Readonly<SVGProps<SVGSVGElement>>) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.4674 6.78398C31.4674 6.78398 23.5758 6.98398 18.2091 3.33398C12.0924 6.28398 6.2341 5.34232 6.2341 5.34232C0.500762 25.834 12.3341 35.0173 12.3341 35.0173C13.8341 35.784 15.3091 36.4257 16.7174 36.6673C16.7174 36.6673 33.9591 35.1257 35.8508 9.45065C35.2758 8.65898 32.9174 7.15065 31.4674 6.78398Z"
        fill="#AEDFEF"
      />
      <path
        d="M18.2091 3.33398C12.0924 6.28398 6.2341 5.34232 6.2341 5.34232C0.500762 25.834 12.3341 35.0173 12.3341 35.0173C12.3341 35.0173 29.5758 32.459 31.4674 6.78398C31.4674 6.78398 23.5758 6.98398 18.2091 3.33398Z"
        fill="white"
      />
      <path
        d="M15.018 29.5423C14.0096 29.9173 13.3763 30.009 13.3763 30.009C13.3763 30.009 5.28463 23.7257 9.2013 9.71732C9.2013 9.71732 13.2096 10.359 17.393 8.33398C17.8763 8.66732 18.4013 8.95065 18.9346 9.20065C14.9846 11.759 10.3846 11.3673 10.3846 11.3673C7.96796 22.509 13.093 27.9507 15.018 29.5423Z"
        fill="#AEDFEF"
      />
      <path
        d="M26.4674 10.6923C25.4758 24.159 18.3091 28.334 15.0258 29.5423C14.0174 29.9173 13.3841 30.009 13.3841 30.009C13.3841 30.009 5.29244 23.7257 9.20911 9.71732C9.20911 9.71732 13.2174 10.359 17.4008 8.33398C17.8841 8.66732 18.4091 8.95065 18.9424 9.20065C20.6758 10.0007 22.5591 10.3757 24.0091 10.5507"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6758 32.9759C22.3591 30.3509 28.7341 24.4842 30.8924 11.6426"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.4674 6.78398C31.4674 6.78398 23.5758 6.98398 18.2091 3.33398C12.0924 6.28398 6.2341 5.34232 6.2341 5.34232C0.500762 25.834 12.3341 35.0173 12.3341 35.0173C13.8341 35.784 15.3091 36.4257 16.7174 36.6673C16.7174 36.6673 33.9591 35.1257 35.8508 9.45065C35.2758 8.65898 32.9174 7.15065 31.4674 6.78398Z"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3919 17.0679C17.9253 16.9679 17.4919 16.8929 17.0836 16.8262C17.2003 16.4429 17.2919 16.2095 17.2919 16.2012C17.6086 15.4262 17.2336 14.5345 16.4503 14.2179C15.6669 13.9012 14.7836 14.2762 14.4669 15.0595C14.4503 15.1095 14.2169 15.6762 13.9586 16.6429C13.3419 16.6762 12.9753 16.7429 12.8836 16.7595C12.0669 16.9262 11.5419 17.7262 11.7086 18.5429C11.8586 19.3179 12.5919 19.8345 13.3586 19.7595C13.2753 20.4012 13.2169 21.1012 13.1919 21.8512C13.1586 22.6929 13.8169 23.4012 14.6586 23.4345C14.6753 23.4345 14.7003 23.4345 14.7169 23.4345C15.5336 23.4345 16.2086 22.7929 16.2419 21.9679C16.2753 21.1929 16.3419 20.4762 16.4336 19.8262C16.8336 19.8845 17.2669 19.9512 17.7419 20.0595C17.8503 20.0845 17.9586 20.0929 18.0669 20.0929C18.7669 20.0929 19.4003 19.6095 19.5503 18.8929C19.7336 18.0679 19.2086 17.2595 18.3836 17.0762L18.3919 17.0679Z"
        fill="#AEDFEF"
        stroke="#010205"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
